import React, {
  useContext,
  createContext,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { getPolicyContainerHelpers } from 'sure-platform-sdk';

const StagedPolicyContext = createContext();

export function useStagedPolicy(policy) {
  const [stagedPolicy, setStagedPolicy] = useContext(StagedPolicyContext);
  const [hasInitializedStagedPolicy, setHasInitializedStagedPolicy] = useState(
    false
  );

  const { isStagedPolicyDirty } = getPolicyContainerHelpers();

  if (typeof setStagedPolicy === undefined) {
    throw new Error(
      'useStagedPolicy must be used within an StagedPolicyContextProvider'
    );
  }

  useEffect(() => {
    if (!hasInitializedStagedPolicy) {
      setStagedPolicy(policy);
      setHasInitializedStagedPolicy(true);
    }
  }, [hasInitializedStagedPolicy, policy, setStagedPolicy]);

  const updateStagedPolicy = useCallback(
    (updatedPolicy) => {
      setStagedPolicy(updatedPolicy);
    },
    [setStagedPolicy]
  );

  const unstagePolicyChanges = useCallback(() => {
    setStagedPolicy(policy);
  }, [policy, setStagedPolicy]);

  const isPolicyDirty = useMemo(() => {
    function checkIsPolicyDirty() {
      if (!!policy && !!stagedPolicy) {
        return isStagedPolicyDirty(policy, stagedPolicy);
      } else {
        return false;
      }
    }

    return checkIsPolicyDirty();
  }, [isStagedPolicyDirty, policy, stagedPolicy]);

  return {
    stagedPolicy,
    updateStagedPolicy,
    unstagePolicyChanges,
    isPolicyDirty,
  };
}

export function StagedPolicyContextProvider({ children }) {
  const [stagedPolicy, setStagedPolicy] = useState(null);

  return (
    <StagedPolicyContext.Provider value={[stagedPolicy, setStagedPolicy]}>
      {children}
    </StagedPolicyContext.Provider>
  );
}
