import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useGlobalState } from '../../store';
import ClaimsView from '../../views/ClaimsView';
import NewClaimView from '../../views/NewClaimView';

function ClaimsContainer({ history, match }) {
  const { claims, isLoggedIn } = useGlobalState();

  function handleFileNewClaim() {
    history.push(`${match.url}/new-claim`);
  }

  return (
    <Switch>
      <Route
        exact
        path={match.path}
        render={() => (
          <ClaimsView
            claims={claims}
            isLoggedIn={isLoggedIn}
            handleFileNewClaim={handleFileNewClaim}
          />
        )}
      />
      <Route exact path={`${match.path}/new-claim`} component={NewClaimView} />
      <Redirect to={match.path} />
    </Switch>
  );
}

export default ClaimsContainer;
