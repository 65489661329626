import React from 'react';
import qs from 'qs';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
import LayoutRoot from '../../components/LayoutRoot/LayoutRoot';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Avatar from '@material-ui/core/Avatar';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import wlConfig from '../../constants/wlConfig';

const {
  errorConfig: { bristolWest: bristolWestErrorConfig },
  icons: { bristolWestIcon, errorIcon },
  theme: {
    styles: {
      errorViewStyles: {
        avatar: avatarStyles,
        errorMessage: errorMessageStyles,
      },
    },
  },
} = wlConfig;

const useStyles = makeStyles((theme) => ({
  body: {
    flex: '1 0 auto',
    width: '100%',
    paddingTop: 150,
    paddingBottom: 16,
    color: theme.palette.tenant.primary,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatar: {
    backgroundColor: theme.palette.tenant.primary,
    width: 48,
    height: 48,
    marginBottom: 24,
    ...avatarStyles,
  },
  icon: {
    color: theme.palette.tenant.white,
  },
  iconImg: {
    width: 48,
    height: 48,
    marginBottom: 16,
  },
  errorMessage: {
    maxWidth: 576,
    ...errorMessageStyles,
  },
  errorId: {
    backgroundColor: theme.palette.tenant.offWhite,
    color: theme.palette.tenant.accent,
    padding: '2px 4px',
  },
}));

function getErrorParams(code) {
  switch (code) {
    case 'unauthorized_request':
    case 'invalid_login_creds':
      window.location.href = '/logout?redirectUrl=/login?forceLogout=true';
      return {};
    case 'bristol_west':
    case 'no_prior_coverage':
    case 'application_ineligible':
    case 'household_knockout':
      return (
        bristolWestErrorConfig || {
          dataTestId: 'bristolWestError',
          iconSrc: bristolWestIcon,
          body: (
            <>
              It appears that you qualify for our Bristol West product. Please
              call{' '}
              <a style={{ color: 'inherit' }} href="tel:1-800-206-9469">
                1-800-206-9469
              </a>{' '}
              to complete this quote.
            </>
          ),
        }
      );
    case 'business_reject':
      return {
        dataTestId: 'businessRejectError',
        IconComponent: AssignmentIcon,
        body:
          'Due to eligibility restrictions on the number of vehicles used for business purposes, we are unable to provide you with a quote.',
      };
    case 'equifax_unavailable':
      return {
        dataTestId: 'equifaxUnavailableError',
        iconSrc: errorIcon,
        body: 'Oops, something went wrong. Please try again later.',
      };
    case 'quote_rejection':
    case 'frozen_credit':
    case 'high_risk':
    case 'ineligible_drivers':
    case 'ineligible_vehicles':
    case 'unverifiable_address':
    case 'invalid_address':
    case 'invalid_dob':
    case 'invalid_name':
      return {
        dataTestId: 'quoteRejectedError',
        IconComponent: AssignmentIcon,
        body: 'We are unable to provide you with a quote at this time.',
      };
    default:
      return {
        dataTestId: 'defaultError',
        iconSrc: errorIcon,
        body: 'Oops, something went wrong. Please try again later.',
      };
  }
}

function ErrorView({ location }) {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const queryParams = qs.parse(get(location, 'search', {}), {
    ignoreQueryPrefix: true,
  });

  const { IconComponent, body, dataTestId, iconSrc } = getErrorParams(
    queryParams.code
  );

  return (
    <LayoutRoot>
      <Container className={classes.body} data-testid={dataTestId}>
        {IconComponent && (
          <Avatar className={classes.avatar}>
            <IconComponent className={classes.icon} />
          </Avatar>
        )}
        {!!iconSrc && (
          <img className={classes.iconImg} src={iconSrc} alt="error-icon" />
        )}
        <Typography
          variant={isMobile ? 'h3' : 'h4'}
          align="center"
          className={classes.errorMessage}
        >
          {body}
        </Typography>
        {queryParams.id && (
          <Typography
            variant="body1"
            align="center"
            className={classes.errorMessage}
            data-testid="errorEventID"
          >
            Event ID: <code className={classes.errorId}>{queryParams.id}</code>
          </Typography>
        )}
      </Container>
    </LayoutRoot>
  );
}

export default ErrorView;
