import React from 'react';
import LayoutRoot from '../../components/LayoutRoot';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../../components/Button';
import sortBy from 'lodash/sortBy';
import moment from 'moment-timezone';
import filter from 'lodash/filter';
import { useGlobalState } from '../../store';
import ClaimCard from '../../components/ClaimCard';

const useStyles = makeStyles((theme) => {
  const {
    palette: { tenant },
    breakpoints,
  } = theme;
  return {
    '@global': {
      body: {
        background: tenant.whiteDark,
      },
    },
    claimsContainer: {
      padding: '32px 16px',
      [breakpoints.up('sm')]: {
        padding: '56px 64px',
      },
      [breakpoints.up('md')]: {
        padding: '58px 148px',
      },
    },
    header: {
      color: tenant.primary,
      marginTop: 32,
      fontSize: 32,
      [breakpoints.up('sm')]: {
        marginTop: 32,
      },
      [breakpoints.up('md')]: {
        marginTop: 48,
      },
      '&:first-of-type': {
        marginTop: 0,
      },
    },
    claimSectionHeader: {
      marginTop: 18,
      color: tenant.primary,
    },
    bodyText: {
      fontSize: 14,
      [breakpoints.up('sm')]: {
        fontSize: 22,
      },
    },
    claimCardSubtitle: {
      fontWeight: 'bold',
      marginTop: 15,
    },
    newClaimBtn: {
      marginBottom: 10,
    },
  };
});

const defaultProps = {
  claims: [],
};

function ClaimsView({ claims, handleFileNewClaim }) {
  const classes = useStyles();
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const viewableClaims = filter(
    claims,
    (claim) => moment().diff(claim.loss_date, 'days') <= 180
  );

  const showableClaims = sortBy(
    viewableClaims,
    (claim) => -moment(claim.loss_date).valueOf()
  );

  const openClaims = showableClaims.filter(({ status }) => status === 'Open');
  const closedClaims = showableClaims.filter(
    ({ status }) => status === 'Closed'
  );
  const { isLoggedIn } = useGlobalState();

  return (
    <LayoutRoot
      withTopNavigation
      withFooter
      withRightsReservedFooter={!isLoggedIn}
      withAlternateBackground={isDesktop}
    >
      <Container
        className={classes.claimsContainer}
        data-testid="claims_container"
      >
        <Typography
          className={classes.header}
          data-testid="claimsPage_header"
          component="h2"
          variant="h3"
          align="left"
        >
          View all claims
        </Typography>
        <Typography
          className={classes.bodyText}
          variant="body2"
          align="left"
          paragraph
          gutterBottom
        >
          You can review an auto claim and check it’s status for up to 180 days;
          details about a property claim are available for up to 455 days. If
          you need help with an existing claim, please contact your claim
          representative or call us at{' '}
          <a href="tel:1-800-435-7764" style={{ color: 'inherit' }}>
            1-800-435-7764
          </a>
          .
        </Typography>
        <Button
          variant="tenant"
          fullWidth={true}
          className={classes.newClaimBtn}
          onClick={handleFileNewClaim}
          data-testid="claimsView_newClaimBtn"
        >
          File a new claim
        </Button>
        {!!openClaims.length && (
          <>
            <Typography
              component="h4"
              variant="h3"
              align="left"
              className={classes.claimSectionHeader}
              data-testid="claimsView_openClaimsHeader"
              gutterBottom
            >
              Open Claims
            </Typography>
            <div data-testid="claimsPage_openClaims">
              {openClaims.map((openClaim) => (
                <ClaimCard
                  key={openClaim.claim_number}
                  claim={openClaim}
                  classes={classes}
                />
              ))}
            </div>
          </>
        )}
        {!!closedClaims.length && (
          <>
            <Typography
              component="h4"
              variant="h3"
              align="left"
              className={classes.claimSectionHeader}
              data-testid="claimsView_closedClaimsHeader"
              gutterBottom
            >
              Closed Claims
            </Typography>
            <div data-testid="claimsPage_closedClaims">
              {closedClaims.map((closedClaim) => (
                <ClaimCard
                  key={closedClaim.claim_number}
                  claim={closedClaim}
                  classes={classes}
                />
              ))}
            </div>
          </>
        )}
      </Container>
    </LayoutRoot>
  );
}

ClaimsView.defaultProps = defaultProps;

export default ClaimsView;
