import React from 'react';
import IconCard from '../../components/IconCard';
import Typography from '@material-ui/core/Typography';
import CarIcon from '@material-ui/icons/DirectionsCar';
import moment from 'moment-timezone';

function ClaimCard({ claim, classes }) {
  return (
    <IconCard
      header="Auto insurance policy"
      body={`Policy #${claim.policyNumber}`}
      IconComponent={CarIcon}
      dataTestId={`claimDetails_card_${claim.claim_number}`}
      buttonText="View Details"
      buttonProps={{
        'data-testid': `button_claimDetails_${claim.claim_number}`,
      }}
    >
      <Typography variant="subtitle1" className={classes.claimCardSubtitle}>
        Vehicle(s)
      </Typography>
      {claim.vehicles.map((vehicle) => (
        <Typography variant="body1">
          {vehicle.year} {vehicle.make} {vehicle.model}
          <br />
          {vehicle.vin}
        </Typography>
      ))}
      <Typography variant="subtitle1" className={classes.claimCardSubtitle}>
        Loss date
      </Typography>
      <Typography
        variant="body1"
        data-testid={`claimDetails_loss_date_${claim.claim_number}`}
      >
        {moment(claim.loss_date).format('MM/DD/YYYY')}
      </Typography>
      <Typography variant="subtitle1" className={classes.claimCardSubtitle}>
        Type
      </Typography>
      <Typography variant="body1">{claim.type}</Typography>
      <Typography variant="subtitle1" className={classes.claimCardSubtitle}>
        Driver involved
      </Typography>
      <Typography variant="body1">
        {claim.driver_first_name} {claim.driver_last_name}
      </Typography>
    </IconCard>
  );
}

export default ClaimCard;
