import React from 'react';
import { Link } from 'react-router-dom';
import LayoutRoot from '../../components/LayoutRoot';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useGlobalState } from '../../store';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
  const {
    palette: { tenant },
    breakpoints,
  } = theme;

  return {
    '@global': {
      body: {
        background: tenant.white,
      },
    },
    touContainer: {
      padding: '32px 24px',
      [breakpoints.up('sm')]: {
        padding: '56px 64px',
      },
      [breakpoints.up('md')]: {
        padding: '58px 148px',
      },
    },
    headerText: {
      color: tenant.primary,
      marginTop: 24,
      [breakpoints.up('sm')]: {
        fontSize: 32,
        marginTop: 32,
      },
      [breakpoints.up('md')]: {
        marginTop: 48,
      },
      '&:first-of-type': {
        marginTop: 0,
      },
    },
    bodyText: {
      fontSize: 14,
      [breakpoints.up('sm')]: {
        fontSize: 16,
      },
    },
    link: {
      textDecoration: 'none',
      '&:hover': {
        color: tenant.primaryHighlight,
      },
    },
  };
});

function HeaderText({ className, children }) {
  return (
    <Typography
      className={className}
      component="h2"
      variant="h3"
      align="left"
      gutterBottom
    >
      {children}
    </Typography>
  );
}

function BodyText({ className, children }) {
  return (
    <Typography
      className={className}
      variant="body2"
      align="left"
      paragraph
      gutterBottom
    >
      {children}
    </Typography>
  );
}

function TermsOfUseView() {
  const classes = useStyles();
  const { isLoggedIn } = useGlobalState();

  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  return (
    <LayoutRoot
      withFooter
      withRightsReservedFooter={!isLoggedIn}
      withTopNavigation
      withAlternateBackground={isDesktop}
    >
      <Container className={classes.touContainer}>
        <HeaderText className={classes.headerText}>Terms of Use</HeaderText>
        <BodyText className={classes.bodyText}>
          These terms were last updated on November 3, 2023.
        </BodyText>
        <HeaderText className={classes.headerText}>
          Please read these terms of use carefully before using this Web Site.
        </HeaderText>
        <BodyText className={classes.bodyText}>
          Throughout these terms of service, when we say “we”, “us” or “our”, we
          mean Mid-Century Insurance Company (depending upon your state), and/or
          its subsidiaries, affiliates, and parent companies and their
          respective successors.
        </BodyText>
        <BodyText className={classes.bodyText}>
          These Terms of Service (“Terms”) govern your access to and use of{' '}
          <Link to="/">www.farmersdirect.com</Link> (“Site”), and all products,
          services, and materials made available through the Site (“Services”).
          By accessing this Site or using our Services, you confirm that you are
          at least 18 years of age and can form a binding contract with us. Our
          Services are not intended for children.
        </BodyText>
        <BodyText className={classes.bodyText}>
          By accessing this Site or using our Services, you agree to be bound by
          these Terms and by our Privacy Policy. If you do not agree to these
          Terms or the Privacy Policy, please do not access or otherwise use our
          Site or Services.
        </BodyText>
        <BodyText className={classes.bodyText}>
          We reserve the right to modify these Terms from time to time. Any
          modifications are effective when posted to the Site and will apply to
          your subsequent use of the Site. You should continue to check the Site
          for changes. Your continued use of our Site or Services following the
          posting of changes to these Terms will mean that you accept those
          changes.
        </BodyText>
        <BodyText className={classes.bodyText}>
          The headings and captions used in these Terms are inserted for
          convenience only and will not affect the meaning or interpretation of
          these Terms.
        </BodyText>

        <HeaderText className={classes.headerText}>Scope of Use</HeaderText>
        <BodyText className={classes.bodyText}>
          You may view, use and download materials at this Site for
          informational, non-commercial purposes in connection with seeking
          insurance. You may not store, modify, reproduce, transmit, distribute,
          repost, publicly display or otherwise use any content on this Site, or
          the design or layout of this Site or individual sections of it, in any
          form or media except with our express prior written permission. The
          commercial use, reproduction, reposting, public display, transmission
          or distribution of any information, software, logo or other material
          available through this Site without our prior written consent is
          strictly prohibited.
        </BodyText>
        <BodyText className={classes.bodyText}>
          You understand that we may, at any time, for any reason and without
          notice, discontinue, change or restrict your use of this Site and make
          changes in the Services made available through this Site.
        </BodyText>
        <BodyText className={classes.bodyText}>
          In connection with your use of the Site or Services, you agree that
          you will (a) review and comply with these Terms and the Privacy
          Policy; (b) comply with all federal, state, local, foreign or other
          applicable law, or regulatory requirement; and (c) provide accurate
          information to us and update it as necessary.
        </BodyText>

        <HeaderText className={classes.headerText}>UNAUTHORIZED USE</HeaderText>
        <BodyText className={classes.bodyText}>
          You may not use any portion of the Services or this Site for any
          unlawful purpose or in any way that might harm, damage, or disparage
          any other party.
        </BodyText>
        <BodyText className={classes.bodyText}>
          Without limiting the preceding sentence, under no circumstances are
          you allowed to:
          <ol type="a">
            <li>
              engage in any conduct in connection with the Site or Services that
              could be considered defamatory, bullying, threatening, harassing,
              predatory, intimidating, obscene, violent, inflammatory, or
              otherwise objectionable;
            </li>
            <li>
              infringe upon any third party’s copyrights, trademarks, service
              marks, trade secrets, or rights of publicity or privacy;
            </li>
            <li>
              use the Site or Services for spamming or to send unsolicited mass
              mailings;
            </li>
            <li>
              create an account for anyone other than a natural person or use or
              attempt to use another’s account or create a false identity;
            </li>
            <li>
              decompile, reverse engineer, disassemble, or modify any portion of
              the Services or Site;
            </li>
            <li>
              introduce into the Services or Site any virus or other code or
              programming intended to disrupt or damage the Services or Site, or
              alter, damage or delete any portion of the Services or Site, or
              retrieve or record information about the Services or Site or their
              users;
            </li>
            <li>
              utilize or copy information, content or any data you view on or
              obtain from the Services or Site to provide any service that is
              competitive, in our sole discretion, with the Services or Site;
            </li>
            <li>
              merge any portion of the Services or Site with any other content
              or data or otherwise create derivative works based on any portion
              of the Services or Site;
            </li>
            <li>
              sublicense, assign, translate, rent, lease, lend, resell for
              profit, distribute or transfer any portion of the Services or Site
              or access to the Services or Site to others;
            </li>
            <li>
              remove, obscure, or alter any copyright, trademark or other
              proprietary rights notices contained in or on the Services or
              Site;
            </li>
            <li>
              engage in “framing,” “mirroring,” or otherwise simulating the
              appearance or function of the Site.
            </li>
          </ol>
        </BodyText>

        <HeaderText className={classes.headerText}>
          ELIGIBILITY FOR INSURANCE PRODUCTS AND SERVICES
        </HeaderText>
        <BodyText className={classes.bodyText}>
          Not all of the Services described on this Site are available in all
          areas of the United States and you may not be eligible for them.
          Policy features may vary by state; see your policy contract for
          detailed information. We reserve the right to determine eligibility.
        </BodyText>

        <HeaderText className={classes.headerText}>
          CREDIT AND CREDIT-BASED INFORMATION
        </HeaderText>
        <HeaderText className={classes.headerText}>
          Disclosure in accordance with Insurance Law
        </HeaderText>
        <BodyText className={classes.bodyText}>
          <strong>If you are a first time user:</strong> In connection with this
          insurance, we may review your credit report or obtain or use a
          credit-based insurance score, based on information contained in that
          report. We will not review your credit report or obtain or use a
          credit-based insurance score in states where this is prohibited. An
          insurance score uses information from your credit report to help
          predict how often you are likely to file claims and how expensive
          those claims will be. Typical items from a credit report that could
          affect a score include, but are not limited to, the following: payment
          history, number of revolving accounts, number of new accounts, the
          presence of collection accounts, bankruptcies and foreclosures. The
          information used to develop the insurance score comes from Equifax.
          You may request a written statement from us describing our use of
          credit histories or insurance scores.
        </BodyText>
        <BodyText className={classes.bodyText}>
          <strong>
            If you have used our Services before and you wish to renew your
            policy:
          </strong>{' '}
          In connection with this insurance, we may have previously used a
          credit report or obtained or used a credit-based insurance score based
          on information contained in that report. We may obtain or use credit
          information provided again, upon renewal of policy. We will not review
          your credit report or obtain or use a credit-based insurance score in
          states where this is prohibited. An insurance score uses information
          from your credit report to help predict how often you are likely to
          file claims and how expensive those claims will be. Typical items from
          a credit report that could affect a score include, but are not limited
          to, the following: payment history, number of revolving accounts,
          number of new accounts, the presence of collection accounts,
          bankruptcies and foreclosures. The information used to develop the
          insurance score comes from Equifax.
        </BodyText>
        <BodyText className={classes.bodyText}>
          Where applicable, if you believe an extraordinary life circumstance
          has negatively impacted your credit information, you may request, in
          writing, that we provide reasonable exceptions to our underwriting
          and/or rating practices for this quote or any policy issued. Please
          contact us as <Link to="/">www.farmersdirect.com</Link> for details on
          qualifying circumstances and how to request an exception.
        </BodyText>

        <HeaderText className={classes.headerText}>INSURANCE QUOTES</HeaderText>
        <BodyText className={classes.bodyText}>
          All quotes we generate are based on the information you provided and
          do not constitute a contract or binding agreement to extend insurance
          coverage. To obtain coverage you must submit an application to us. All
          applications are subject to underwriting approval.
        </BodyText>
        <BodyText className={classes.bodyText}>
          We may also receive information provided by third parties, as a
          licensee or otherwise, relating to your property that is public and/or
          proprietary information. That information may be used by us and/or
          presented to you in preparation of your insurance quote.
        </BodyText>
        <BodyText className={classes.bodyText}>
          Any insurance purchasing decisions, such as coverage amounts, limits
          and deductibles, are completely and solely your responsibility.
          Insurance coverage and our performance under your insurance policy are
          determined solely by the terms, conditions, exclusions and limitations
          of your insurance policy and applicable law. Policy features may vary
          by state;{' '}
          <strong>
            we encourage you to view/download a sample of your actual policy
            documentation prior to making any purchase decision:
          </strong>
          <ul>
            <li>
              <a
                href="https://sure-host.s3.amazonaws.com/docs/farmers/auto/sample_docs/FULL-SAMPLE-POLICY-SC.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                South Carolina
              </a>
            </li>
          </ul>
        </BodyText>
        <BodyText className={classes.bodyText}>
          The above policy sample are provided for general information purposes
          only and are not intended to describe all of the terms, conditions,
          and exclusions applicable. The information is provided by Farmers® and
          while we endeavor to keep the information up to date and correct, we
          make no representations or warranties of any kind, express or implied,
          about the completeness, accuracy, reliability, suitability or
          availability with respect to the information, products or services
          contained therein for any purpose. Please refer to your actual policy
          or the relevant product or services agreement for the specific
          governing terms. The information should be construed as rendering
          legal, accounting or other professional advice, and any reliance you
          place on such information is therefore strictly at your own risk.
        </BodyText>

        <HeaderText className={classes.headerText}>Claims Process</HeaderText>
        <BodyText className={classes.bodyText}>
          If you report an insurance claim, the information you submit regarding
          your insurance policy and the loss is subject to review and
          verification. We reserve the right to request additional information
          prior to reaching a decision on the claim. A claim representative may
          be communicating with you regarding your claim. All policy provisions
          contained in your policy remain in effect. If you have any questions
          concerning the coverage afforded by your policy, please visit the
          Support page.
        </BodyText>

        <HeaderText className={classes.headerText}>
          ACCOUNT INFORMATION
        </HeaderText>
        <BodyText className={classes.bodyText}>
          In order to access the Services, you will have to create an account.
          By doing so, you represent and warrant, unless prohibited by state
          law, that the information you provide to us at registration (including
          information provided through any Linked Accounts, as defined below),
          and at all other times, will be true, accurate, current, and complete.
          Your “Account Information” also includes any information you provide
          to us during the insurance application, in connection with a support
          request, or during the claims process.
        </BodyText>
        <BodyText className={classes.bodyText}>
          We reserve the right to access, read, preserve, and disclose Account
          Information as we reasonably believe is necessary to: (a) satisfy any
          law, regulation, legal process, subpoena or governmental request; (b)
          enforce these Terms, including investigation of potential violations
          thereof; (c) detect, prevent, or otherwise address fraud, security or
          technical issues; (d) cooperate with law enforcement authorities; (e)
          respond to user support requests; or (f) protect our, our users' or
          the public's rights, property or safety.
        </BodyText>

        <HeaderText className={classes.headerText}>
          INFORMATION STORAGE AUTHORIZATION
        </HeaderText>
        <BodyText className={classes.bodyText}>
          You authorize us or one of our vendors to store any information you
          provide, including, but not limited to, names, addresses, birth dates,
          driver’s license numbers, social security numbers, vehicle
          identification numbers (VINs), Bank Account or Payment Card
          information, and User Content (as defined below).
        </BodyText>
        <BodyText className={classes.bodyText}>
          You certify that you are the owner and or authorized signer for any
          Bank Account or Payment Card you provide, and you authorize the
          financial institution where any such Bank Account or Payment Card is
          held to honor any withdrawals or charges you authorized.
        </BodyText>
        <BodyText className={classes.bodyText}>
          Additional{' '}
          <Link to="/payment-terms-of-use">
            Payment Authorization Terms and Conditions
          </Link>{' '}
          may be applicable.
        </BodyText>

        <HeaderText className={classes.headerText}>
          PRIVACY AND PASSWORDS
        </HeaderText>
        <BodyText className={classes.bodyText}>
          Some portions of the Site and Services are protected and require a
          User ID and password (together, “Log-in Credentials”) for access.
          Unauthorized use or access is strictly prohibited. You agree that you
          are responsible for maintaining the confidentiality of your Log-in
          Credentials. You may not share your Log-in Credentials with any third
          party. You are responsible for all uses of your Log-in Credentials,
          and any and all related charges, whether or not authorized by you. You
          must immediately notify us of any unauthorized use of your Log-in
          Credentials or any other breach of security. We will not be liable for
          any loss that you may incur as a result of someone else using your
          Log-in Credentials, with or without your knowledge. However, you could
          be held liable for losses incurred by us or another party due to
          someone else using your Log-in Credentials.
        </BodyText>

        <HeaderText className={classes.headerText}>
          LINKED ACCOUNTS AND SOCIAL NETWORKING SITES
        </HeaderText>
        <BodyText className={classes.bodyText}>
          We may, now or in the future, allow you to link your Farmers® account
          to your account(s) on third party services, such as social networking
          sites (“Linked Accounts”). If you choose to add any Linked Accounts,
          you are authorizing us to store and use your log-in credentials to
          access your Linked Account on your behalf as your agent for the
          purpose of integrating your Farmers® account with content and features
          available through your Linked Account. This may include importing the
          contacts, preferences, interests or “likes” of the Linked Account,
          and/or pushing updates regarding your use of the Services to your
          Linked Accounts. Adding Linked Accounts or accessing or using a
          third-party service via Linked Accounts may be subject to additional
          terms established by the applicable third party, and it is your sole
          responsibility to comply with those terms.
        </BodyText>

        <HeaderText className={classes.headerText}>USER CONTENT</HeaderText>
        <BodyText className={classes.bodyText}>
          We may request that you submit certain content such as text, photos,
          audiovisual content, and other media content (“User Content”) through
          the Site or Services. By providing User Content in this manner (other
          than personal information subject to our Privacy Policy), you grant to
          us an unrestricted, irrevocable, worldwide, royalty-free license to
          use, reproduce, display, publicly perform, and transmit such User
          Content for any purpose, including without limitation, disclosing your
          User Content as necessary to satisfy any law, regulation, or
          governmental request.
        </BodyText>
        <BodyText className={classes.bodyText}>
          We shall not be subject to any obligations of confidentiality
          regarding any information or materials that you submit except as
          specified in the Privacy Policy, as set forth in any additional terms
          and conditions relating to specific products or services, or as
          otherwise specifically agreed or required by law.
        </BodyText>

        <HeaderText className={classes.headerText}>SUBMISSIONS </HeaderText>
        <BodyText className={classes.bodyText}>
          If you submit any ideas, suggestions, or proposals (collectively,
          “Submissions”) relating to the Site or Services (or other products or
          services) to us through any means, you acknowledge and agree that: (a)
          your Submissions do not contain confidential or proprietary
          information; (b) we can share your Submissions with third parties; (c)
          we are entitled to use (or choose not to use) any Submissions for any
          purpose, in any way; (d) your Submissions automatically become our
          property; and (e) you are not entitled to any compensation or
          reimbursement of any kind from us for your Submissions.
        </BodyText>

        <HeaderText className={classes.headerText}>COMMUNICATIONS </HeaderText>
        <BodyText className={classes.bodyText}>
          In connection with your use of the Site or Services, we may send you
          announcements, administrative messages, and other information. You may
          opt out of some of those communications.
        </BodyText>

        <HeaderText className={classes.headerText}>
          INTELLECTUAL PROPERTY
        </HeaderText>
        <BodyText className={classes.bodyText}>
          All right, title, and interest in and to the Site and Services,
          including the Farmers® name and logo, the Farmers Insurance® name and
          logo, and other trademarks, service marks, and copyright are the
          property of Farmers Group, Inc. and/or its affiliates or licensors.
          Except as expressly provided in “Permitted Use”, you may not use,
          reproduce, modify, transmit, distribute, publicly display or perform
          any portion of the Site or Services without our prior written
          permission. We reserve all rights not expressly granted in and to the
          Site and Services.
        </BodyText>

        <HeaderText className={classes.headerText}>
          THIRD PARTY LINKS AND SERVICES
        </HeaderText>

        <BodyText className={classes.bodyText}>
          This Site may contain links to third-party websites that are not owned
          or controlled by us. We make no representation regarding the content,
          or accuracy thereof, of any third-party website. Links to third-party
          websites are offered for convenience and for informational purposes
          only and are not intended as a referral or endorsement by us of the
          third-party website. We assume no responsibility and/or liability for
          the content, privacy policies, or practices of any third-party
          websites.
        </BodyText>
        <BodyText className={classes.bodyText}>
          If you access any third party's website, service, or content from this
          Site, you do so at your own risk. By using this Site or the Services,
          you expressly release us from any liability arising from your use of
          any third-party website, information, materials, products, or
          services. Accordingly, we encourage you to be aware when you have left
          this Site and to read the terms and conditions and privacy policy of
          each other website that you visit.
        </BodyText>

        <HeaderText className={classes.headerText}>
          DISCLAIMER OF WARRANTIES
        </HeaderText>
        <BodyText className={classes.bodyText}>
          This Site, and any Services available through this Site (collectively,
          "Materials"), are provided to you on an "as is," "as available" basis
          without warranty of any kind, either express or implied, including
          without limitation to any implied warranties of merchantability,
          fitness for a particular purpose, quiet enjoyment, systems
          integration, accuracy, and non-infringement.
        </BodyText>
        <BodyText className={classes.bodyText}>
          We do not endorse nor make any warranty as to the accuracy,
          completeness, currency, or reliability of the Materials. We make no
          representations or warranties that (a) use of the Materials will be
          uninterrupted or error-free; (b) defects will be corrected; or (c) the
          Site, any communications we send, including via e-mail, or the server
          that makes the Site available are free of viruses or any other harmful
          components. This warranty disclaimer may be different in connection
          with specific products and services offered by us. The information and
          descriptions contained herein are not necessarily intended to be
          complete descriptions of all terms, exclusions and conditions
          applicable to the products and services, but are provided solely for
          general informational purposes; please refer to the actual policy or
          the relevant product or services agreement for the governing terms.
        </BodyText>
        <BodyText className={classes.bodyText}>
          Some states do not allow the disclaimer of implied warranties, so the
          foregoing disclaimer may not apply to you if you are a resident of one
          of those states. You may also have other legal rights that vary from
          state to state.
        </BodyText>

        <HeaderText className={classes.headerText}>
          LIMITATION OF LIABILITY
        </HeaderText>
        <BodyText className={classes.bodyText}>
          Your use of the Materials is at your own risk. We shall not be liable,
          whether based in contract, tort, negligence, strict liability or
          otherwise, for any direct, indirect, incidental, consequential, or
          special damages (even if we or our representative have been advised of
          the possibility of such damages) arising out of or in any way
          connected with (a) access to, use of or inability to use the Site or
          Services; (b) your reliance upon the Materials; (c) unauthorized
          access to or alteration of your transmissions or personal data; (d)
          our actions or omissions taken in reliance on your account
          information, changes to your account information, and/or any notices
          or requests received from your account; (e) your failure to protect
          the confidentiality of any passwords or access rights to your account
          information; and (e) mistakes, omissions, errors, interruptions,
          delays or computer viruses, whether caused in whole or in part by
          negligence, acts of god, theft or destruction of, or unauthorized
          access to this Site or the Materials.
        </BodyText>
        <BodyText className={classes.bodyText}>
          This limitation of liability may be different in connection with
          specific products and services we offer. Some states do not allow the
          limitation of liability, so the foregoing limitation may not apply to
          you.
        </BodyText>
        <BodyText className={classes.bodyText}>
          You understand and agree that we, and/or our subsidiaries, affiliates,
          and parent companies and their respective successors, are separate
          parties and nothing in these Terms permits any claim that you may have
          against one party in connection with the Services to extend under any
          legal theory to the other party.
        </BodyText>

        <HeaderText className={classes.headerText}>INDEMNITY </HeaderText>
        <BodyText className={classes.bodyText}>
          You agree to defend and indemnify us from any and all third party
          claims, liability, damages, expenses and costs (including reasonable
          attorneys’ fees) caused by or arising from your use of this Site or
          the Materials in a manner not expressly authorized by these Terms or
          that violates these Terms, any law, or the rights of a third party.
        </BodyText>

        <HeaderText className={classes.headerText}>
          GOVERNING LAW AND JURISDICTION
        </HeaderText>
        <BodyText className={classes.bodyText}>
          These Terms and your use of the Site and Services are governed in all
          respects by the laws of the State of California, without giving effect
          to any principles of conflicts of laws. Any dispute concerning the
          Site or Services or these Terms shall be subject to the exclusive
          venue of a court of competent jurisdiction in Los Angeles County in
          the State of California.
        </BodyText>

        <HeaderText className={classes.headerText}>
          PAPERLESS COMMUNICATION
        </HeaderText>
        <BodyText className={classes.bodyText}>
          By using the Site, or any Services made available through the Site,
          you agree to have all documents, notices, and communications
          (collectively, “Documents”), including, but not limited to, the
          insurance policy, policy notices, privacy statements, billing-related
          communications, and other policy-related communications, provided to
          you in electronic form. This consent, unless withdrawn, applies to all
          transactions between you and us. You have the right to receive
          Documents from us in paper form, if you wish, by withdrawing this
          consent.{' '}
          <strong>
            Before you withdraw your consent to electronic communication,
            including receiving the insurance policy electronically, please know
            that you can easily download, print, or email your insurance
            documents from your account.
          </strong>
        </BodyText>
        <BodyText className={classes.bodyText}>
          There may be some Documents that we cannot or do not deliver
          electronically due to legal, technological, and/or other constraints.
          These documents will be delivered to you via the United States Postal
          Service, but most will also be available electronically on the Site.
        </BodyText>
        <BodyText className={classes.bodyText}>
          If you withdraw your consent for us to provide you with records in
          electronic form, we will, from that point forward, provide you with
          records in paper form. To withdraw your consent, you must go to{' '}
          <Link to="/">www.farmersdirect.com</Link> and click on Contact. You
          must include “WITHDRAW ELECTRONIC CONSENT” within the body of the
          message, along with your name, policy number, effective and expiration
          dates of the policy, the effective date of your withdrawal and whether
          you want (a) all communications to be in paper form and (b) your
          insurance policy to be sent to you in paper form.
        </BodyText>
        <BodyText className={classes.bodyText}>
          You must maintain a valid, current email address in order for us to
          electronically provide Documents. Ensure that your email is active and
          capable of receiving new emails. If an email is returned to us as
          undeliverable, we may notify you to update your e-mail address. We may
          also resend the e-mail. If one and/or more emails are returned as
          undeliverable, and there is no other active e-mail address of a named
          insured associated with the enrolled policy(ies), we will presume that
          you have withdrawn your consent, you will be disenrolled from
          electronic communications, and all available Documents will be sent to
          you via the USPS. Note that you will have the ability to access the
          Site and view your Documents on demand.
        </BodyText>
        <BodyText className={classes.bodyText}>
          If you need to update or modify your email address associated with
          your Farmers policy, or, if after electing to withdraw your consent to
          receiving Documents electronically you decide you want to once again
          enroll in electronic delivery you may log in to your Farmers account
          and update your paperless enrollment status.
        </BodyText>
        <BodyText className={classes.bodyText}>
          As Documents become available, we will send you a notification by
          e-mail which will contain the security-enabled Internet address (URL)
          to your Farmers® account, where the Documents can be viewed,
          downloaded and printed. It is your responsibility to log in to view
          your Documents.
        </BodyText>
        <BodyText className={classes.bodyText}>
          Hardware and Software Requirements:
        </BodyText>
        <BodyText className={classes.bodyText}>
          In order to access, view and retain Documents that we provide to you
          electronically, including your insurance policy, you need:
          <ul>
            <li>a valid email account and an active Farmers® account; and</li>
            <li>
              a desktop or laptop computer, tablet or mobile device equipped
              with -
              <ul>
                <li>
                  a modern and supported internet browser (like Chrome, Firefox,
                  Safari or Edge); and
                </li>
                <li>
                  software capable of opening a .pdf document, such as
                  Adobe® Reader® software (this software is available for
                  download free of charge at http://get.adobe.com/reader/), or
                  similar software, to view and print the documents.
                </li>
              </ul>
            </li>
          </ul>
        </BodyText>
        <BodyText className={classes.bodyText}>
          If you want to download and save Documents, you must have sufficient
          electronic memory on your hard drive or other media storage device. If
          you wish to print Documents, you must also have access to a printer.
        </BodyText>
        <BodyText className={classes.bodyText}>
          These minimum requirements are subject to change. By using the
          Services, you acknowledge that you have the minimum hardware and
          software requirements listed in this section.
        </BodyText>
        <BodyText className={classes.bodyText}>
          You may incur costs associated with electronic access to Documents,
          such as usage charges from your mobile device or internet service
          provider. We are not responsible for any damages to your computer,
          tablet, or phone hardware or software, injury to you as a result of
          power failures or power spikes, or telephone or internet interruptions
          or other expenses in relation to your use of electronic delivery.
        </BodyText>

        <HeaderText className={classes.headerText}>FRAUD WARNING</HeaderText>
        <BodyText className={classes.bodyText}>
          Any person who knowingly and with intent to defraud any insurance
          company or other person files an application for insurance or
          statement of claim containing any materially false information or
          conceals for the purpose of misleading, information concerning any
          material fact thereto, commits a fraudulent insurance act, and may be
          subject to fines, restitution, or confinement in prison, or any
          combination thereof.
        </BodyText>

        <HeaderText className={classes.headerText}>ACCESSIBILITY</HeaderText>
        <BodyText className={classes.bodyText}>
          If our website not fully accessible to you, please go to{' '}
          <Link to="/">www.farmersdirect.com</Link> and click on Contact Please
          include any details that will help us resolve the issue, such as a
          description of the problem, and your preferred contact information.
          Farmers is committed to making our website accessible to everyone.
        </BodyText>

        <HeaderText className={classes.headerText}>MISCELLANEOUS </HeaderText>
        <BodyText className={classes.bodyText}>
          We reserve the right to discontinue or modify any aspect of the Site
          or Services at any time. These Terms, together with the Privacy
          Policy, and any other legal notices published by us on the Site,
          constitute the entire agreement between us concerning the Services. If
          any provision of these Terms is deemed invalid by a court of competent
          jurisdiction, the invalidity of that provision will not affect the
          validity of the remaining provisions of these Terms, which will remain
          in full force and effect.
        </BodyText>
      </Container>
    </LayoutRoot>
  );
}

export default TermsOfUseView;
