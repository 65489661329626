import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import localforage from 'localforage';
import { makeStyles } from '@material-ui/core/styles';
import { getFromSessionStorage } from '../../services/sessionStorageUtils';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

const propTypes = {
  children: PropTypes.func.isRequired,
  persistedStoreKey: PropTypes.string,
  persistWhitelist: PropTypes.arrayOf(PropTypes.string),
  sessionStorageWhitelist: PropTypes.arrayOf(PropTypes.string),
};

const defaultProps = {
  persistedStoreKey: 'persistedStore',
  persistWhitelist: [],
  sessionStorageWhitelist: [],
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
});

const isNotProdOrTest =
  process.env.REACT_APP_ENV !== 'production' && process.env.NODE_ENV !== 'test';

function PersistGate({
  children,
  persistWhitelist,
  persistedStoreKey,
  sessionStorageWhitelist,
  dbName,
}) {
  const classes = useStyles();

  const [persistedStore, setPersistedStore] = useState(null);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(
    function fetchPersistedState() {
      localforage.config({
        name: dbName,
        storeName: dbName,
      });

      setIsFetching(true);
      localforage
        .getItem(persistedStoreKey)
        .then((val) => {
          const localforageStore = JSON.parse(val) || {};
          const sessionStorageStore = JSON.parse(
            getFromSessionStorage(persistedStoreKey)
          );
          const store = Object.assign(
            {},
            localforageStore,
            sessionStorageStore,
            window.__INITIAL_STATE__ || {}
          );
          if (isNotProdOrTest) {
            console.group('%cpersisted store:', 'font-weight: bold;');
            console.log(store);
            console.groupEnd();
          }
          setPersistedStore(store);
          setIsFetching(false);
        })
        .catch((err) => {
          console.error(err);
          setIsFetching(false);
        });
    },
    [dbName, persistWhitelist, persistedStoreKey, sessionStorageWhitelist]
  );

  if (isFetching) {
    return (
      <Box className={classes.root} data-testid="PersistGate_spinner">
        <CircularProgress />
      </Box>
    );
  } else if (persistedStore) {
    return children(persistedStore);
  } else {
    return null;
  }
}

PersistGate.propTypes = propTypes;
PersistGate.defaultProps = defaultProps;

export default PersistGate;
