/**
 * Check that sessionStorage is supported before calling sessionStorage.getItem
 *
 * @param {String} key The key of the sessionStorage item
 */
export function getFromSessionStorage(key) {
  try {
    return window.sessionStorage.getItem(key);
  } catch (e) {
    if (process.env.NODE_ENV !== 'test') {
      window.console.error('sessionStorage is unsupported', e);
    }
  }
}

/**
 * Check that sessionStorage is supported before calling sessionStorage.setItem
 *
 * @param {String} key The key of the sessionStorage item
 * @param {String} value The value of the sessionStorage item
 */
export function setSessionStorage(key, value) {
  try {
    window.sessionStorage.setItem(key, value);
  } catch (e) {
    if (process.env.NODE_ENV !== 'test') {
      window.console.error('sessionStorage is unsupported', e);
    }
  }
}
