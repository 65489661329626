export const PATHNAMES = {
  // QUOTE PATHS
  START: '/start',
  ADDRESS: '/address',
  VIOLATIONS: '/violations',
  NUMBER_OF_VIOLATIONS: '/number-of-violations',
  START_DATE: '/start-date',
  GET_QUOTE: '/get-quote',
  CONFIRM_DETAILS: '/confirm-details',
  MOVE_DATE: '/move-date',
  CONFIRM_ADDRESS: '/confirm-address',
  VEHICLES_QUOTE: '/vehicles',
  RIDESHARE_VEHICLES: '/rideshare-vehicles',
  BUSINESS_VEHICLES: '/business-vehicles',
  DRIVERS_QUOTE: '/drivers',
  SR22: '/sr22',
  REVOKED_SUSPENDED: '/revoked-suspended',
  COVERAGE: '/coverage',
  HEALTH_CHECK: '/check',
  MISSING_VINS: '/missing-vins',
  MISSING_LIENHOLDER: '/missing-lienholder',
  MISSING_LICENSE: '/missing-license',
  EXCLUDE_VEHICLES: '/exclude-vehicles',
  EXCLUDE_DRIVERS: '/exclude-drivers',
  EXCLUDED_VEHICLES_NOTICE: '/excluded-vehicles',
  EXCLUDED_DRIVERS_NOTICE: '/excluded-drivers',
  BUSINESS_VEHICLE_NOTICE: '/business-vehicles-notice',
  VERIFY_INFORMATION: '/verify-information',
  REVIEW_QUOTE: '/review-quote',
  DISCLOSURES: '/disclosures',
  CHECKOUT: '/checkout',

  // POLICY PATHS
  POLICY_OVERVIEW: '/overview',
  POLICY_DRAFT: '/draft',
  POLICY_REVIEW: '/review',
  POLICY_DISCLOSURES: '/disclosures',
  POLICY_CHECKOUT: '/checkout',
  DOCUMENT_CENTER: '/document-center',

  // LANDING PATHS
  INDEX: '/',
  MY_ACCOUNT: '/my-account',
  FAQ: '/faq',
  ABOUT_US: '/about',
  TERMS_OF_USE: '/terms-of-use',
  PAYMENTS_TERMS_OF_USE: '/payment-terms-of-use',
  CLAIMS: '/claims',
  PRIVACY_POLICY: '/privacy',
  LOG_IN: '/login',
  LOG_OUT: '/logout',

  CONTACT_US: process.env.REACT_APP_ZENDESK_CONTACT_URL,
};

export const pathSequence = [
  PATHNAMES.START,
  PATHNAMES.ADDRESS,
  PATHNAMES.VIOLATIONS,
  PATHNAMES.NUMBER_OF_VIOLATIONS,
  PATHNAMES.START_DATE,
  PATHNAMES.CONFIRM_DETAILS,
  PATHNAMES.MOVE_DATE,
  PATHNAMES.CONFIRM_ADDRESS,
  PATHNAMES.GET_QUOTE,
  PATHNAMES.VEHICLES_QUOTE,
  PATHNAMES.RIDESHARE_VEHICLES,
  PATHNAMES.BUSINESS_VEHICLES,
  PATHNAMES.DRIVERS_QUOTE,
  PATHNAMES.SR22,
  PATHNAMES.REVOKED_SUSPENDED,
  PATHNAMES.COVERAGE,
  PATHNAMES.HEALTH_CHECK,
  PATHNAMES.MISSING_VINS,
  PATHNAMES.MISSING_LIENHOLDER,
  PATHNAMES.MISSING_LICENSE,
  PATHNAMES.EXCLUDE_VEHICLES,
  PATHNAMES.EXCLUDE_DRIVERS,
  PATHNAMES.VERIFY_INFORMATION,
  PATHNAMES.REVIEW_QUOTE,
  PATHNAMES.DISCLOSURES,
  PATHNAMES.CHECKOUT,
];
