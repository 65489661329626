import { createContext, useContext, useCallback } from 'react';
import ACTION_TYPES from '../constants/actionTypes';
import api from '../services/api';
import getHasOpenOrLostBillingDispute from '../services/getHasOpenOrLostBillingDispute';
import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import omit from 'lodash/omit';

const OKTA_ID_TOKEN_KEY = 'idToken';
const OKTA_ACCESS_TOKEN_KEY = 'accessToken';
const POLICY_UNDER_DISPUTE_ERROR_CODE = 'policy_under_dispute';

export const ActionsContext = createContext();

/**
 * Create quote
 * =========================
 */

function createQuoteRequest(enrollmentApplication) {
  return {
    type: ACTION_TYPES.CREATE_QUOTE,
    payload: {
      enrollmentApplication,
    },
  };
}

function createQuoteSuccess(quote) {
  return {
    type: ACTION_TYPES.CREATE_QUOTE_SUCCESS,
    payload: quote,
  };
}

function createQuoteFailure(error) {
  return {
    type: ACTION_TYPES.CREATE_QUOTE_FAILURE,
    error,
  };
}

/**
 * Get quote
 * =========================
 */

function getQuoteRequest(quoteNumber) {
  return {
    type: ACTION_TYPES.GET_QUOTE,
    payload: {
      quoteNumber,
    },
  };
}

function getQuoteSuccess(quote) {
  return {
    type: ACTION_TYPES.GET_QUOTE_SUCCESS,
    payload: quote,
  };
}

function getQuoteFailure(error) {
  return {
    type: ACTION_TYPES.GET_QUOTE_FAILURE,
    error,
  };
}

/**
 * Update quote
 * =========================
 */

function updateQuoteRequest(quoteNumber, quote) {
  return {
    type: ACTION_TYPES.UPDATE_QUOTE,
    payload: {
      quoteNumber,
      quote,
    },
  };
}

function updateQuoteSuccess(quote) {
  return {
    type: ACTION_TYPES.UPDATE_QUOTE_SUCCESS,
    payload: quote,
  };
}

function updateQuoteFailure(error) {
  return {
    type: ACTION_TYPES.UPDATE_QUOTE_FAILURE,
    error,
  };
}

/**
 * Get quote variables
 * =========================
 */

function getQuoteVarsRequest(quoteNumber) {
  return {
    type: ACTION_TYPES.GET_QUOTE_VARS,
    payload: {
      quoteNumber,
    },
  };
}

function getQuoteVarsSuccess(variables) {
  return {
    type: ACTION_TYPES.GET_QUOTE_VARS_SUCCESS,
    payload: variables,
  };
}

function getQuoteVarsFailure(error) {
  return {
    type: ACTION_TYPES.GET_QUOTE_VARS_FAILURE,
    error,
  };
}

/**
 * Get checkout data
 * =========================
 */

function getQuoteCheckoutRequest(quoteNumber, application) {
  return {
    type: ACTION_TYPES.GET_QUOTE_CHECKOUT,
    payload: {
      quoteNumber,
      application,
    },
  };
}

function getQuoteCheckoutSuccess(quoteCheckout) {
  return {
    type: ACTION_TYPES.GET_QUOTE_CHECKOUT_SUCCESS,
    payload: quoteCheckout,
  };
}

function getQuoteCheckoutFailure(error) {
  return {
    type: ACTION_TYPES.GET_QUOTE_CHECKOUT_FAILURE,
    error,
  };
}

/**
 * Finalize quote
 * =========================
 */

function finalizeQuoteRequest() {
  return {
    type: ACTION_TYPES.FINALIZE_QUOTE,
  };
}

function finalizeQuoteSuccess(quote) {
  return {
    type: ACTION_TYPES.FINALIZE_QUOTE_SUCCESS,
    payload: {
      quote,
    },
  };
}

function finalizeQuoteFailure(error) {
  return {
    type: ACTION_TYPES.FINALIZE_QUOTE_FAILURE,
    error,
  };
}

/**
 * Get policies
 * =========================
 */

function getPoliciesRequest() {
  return {
    type: ACTION_TYPES.GET_POLICIES,
  };
}

function getPoliciesSuccess(policies) {
  return {
    type: ACTION_TYPES.GET_POLICIES_SUCCESS,
    payload: {
      policies,
    },
  };
}

function getPoliciesFailure(error) {
  return {
    type: ACTION_TYPES.GET_POLICIES_FAILURE,
    error,
  };
}

/**
 * Get policy
 * =========================
 */

function getPolicyRequest() {
  return {
    type: ACTION_TYPES.GET_POLICY,
  };
}

function getPolicySuccess(policy) {
  return {
    type: ACTION_TYPES.GET_POLICY_SUCCESS,
    payload: {
      policy,
    },
  };
}

function getPolicyFailure(error) {
  return {
    type: ACTION_TYPES.GET_POLICY_FAILURE,
    error,
  };
}

/**
 * Update policy
 * =========================
 */

function updatePolicyRequest(policy) {
  return {
    type: ACTION_TYPES.UPDATE_POLICY,
    payload: {
      policy,
    },
  };
}

function updatePolicySuccess(policy) {
  return {
    type: ACTION_TYPES.UPDATE_POLICY_SUCCESS,
    payload: {
      policy,
    },
  };
}

function updatePolicyFailure(error) {
  return {
    type: ACTION_TYPES.UPDATE_POLICY_FAILURE,
    error,
  };
}

/**
 * Get policy vars
 * =========================
 */

function getPolicyVarsRequest(policyId) {
  return {
    type: ACTION_TYPES.GET_POLICY_VARS,
    payload: {
      policyId,
    },
  };
}

function getPolicyVarsSuccess(variables) {
  return {
    type: ACTION_TYPES.GET_POLICY_VARS_SUCCESS,
    payload: variables,
  };
}

function getPolicyVarsFailure(error) {
  return {
    type: ACTION_TYPES.GET_POLICY_VARS_FAILURE,
    error,
  };
}

/**
 * Get policy checkout
 * =========================
 */

function getPolicyCheckoutRequest(policyId) {
  return {
    type: ACTION_TYPES.GET_POLICY_CHECKOUT,
    payload: {
      policyId,
    },
  };
}

function getPolicyCheckoutSuccess(checkout) {
  return {
    type: ACTION_TYPES.GET_POLICY_CHECKOUT_SUCCESS,
    payload: checkout,
  };
}

function getPolicyCheckoutFailure(error) {
  return {
    type: ACTION_TYPES.GET_POLICY_CHECKOUT_FAILURE,
    error,
  };
}

/**
 * Cancel policy
 * =========================
 */

function cancelPolicyRequest(reason) {
  return {
    type: ACTION_TYPES.CANCEL_POLICY,
    payload: {
      reason,
    },
  };
}

function cancelPolicySuccess(policy) {
  return {
    type: ACTION_TYPES.CANCEL_POLICY_SUCCESS,
    payload: {
      policy,
    },
  };
}

function cancelPolicyFailure(error) {
  return {
    type: ACTION_TYPES.CANCEL_POLICY_FAILURE,
    error,
  };
}

/**
 * Get the user
 * =========================
 */

function getUserRequest() {
  return {
    type: ACTION_TYPES.GET_USER,
  };
}

function getUserSuccess(user) {
  return {
    type: ACTION_TYPES.GET_USER_SUCCESS,
    payload: user,
  };
}

function getUserFailure(error) {
  return {
    type: ACTION_TYPES.GET_USER_FAILURE,
    error,
  };
}

/**
 * Refresh okta tokens
 * =========================
 */

function refreshOktaTokensRequest() {
  return {
    type: ACTION_TYPES.REFRESH_OKTA_TOKENS,
  };
}

function refreshOktaTokensSuccess([renewedIdToken, renewedAccessToken]) {
  return {
    type: ACTION_TYPES.REFRESH_OKTA_TOKENS_SUCCESS,
    payload: {
      idToken: renewedIdToken,
      accessToken: renewedAccessToken,
    },
  };
}

function refreshOktaTokensFailure(error) {
  return {
    type: ACTION_TYPES.REFRESH_OKTA_TOKENS_FAILURE,
    error,
  };
}

/**
 * Get claims
 * =========================
 */

function getPolicyClaims(policies) {
  return {
    type: ACTION_TYPES.GET_CLAIMS,
  };
}

function getPolicyClaimsSuccess(claims) {
  return {
    type: ACTION_TYPES.GET_CLAIMS_SUCCESS,
    payload: claims,
  };
}

function getPolicyClaimsFailure(error) {
  return {
    type: ACTION_TYPES.GET_CLAIMS_FAILURE,
    error,
  };
}

/**
 * Exchange quote auth token
 * =========================
 */

function exchangeQuoteAuthTokenRequest(quoteNumber, token) {
  return {
    type: ACTION_TYPES.EXCHANGE_QUOTE_AUTH_TOKEN,
    payload: {
      quoteNumber,
      token,
    },
  };
}

function exchangeQuoteAuthTokenSuccess(token) {
  return {
    type: ACTION_TYPES.EXCHANGE_QUOTE_AUTH_TOKEN_SUCCESS,
    payload: {
      token,
    },
  };
}

function exchangeQuoteAuthTokenFailure(error) {
  return {
    type: ACTION_TYPES.EXCHANGE_QUOTE_AUTH_TOKEN_FAILURE,
    error,
  };
}

/**
 * Get feature flags
 * =========================
 */

function getFeatureFlagsRequest() {
  return {
    type: ACTION_TYPES.GET_FEATURE_FLAGS,
  };
}

function getFeatureFlagsSuccess(featureFlags) {
  return {
    type: ACTION_TYPES.GET_FEATURE_FLAGS_SUCCESS,
    payload: featureFlags,
  };
}

function getFeatureFlagsFailure(error) {
  return {
    type: ACTION_TYPES.GET_FEATURE_FLAGS_FAILURE,
    error,
  };
}

/**
 * Action creators
 * =========================
 */

export function useActions() {
  const dispatch = useContext(ActionsContext);
  if (typeof dispatch === undefined) {
    throw new Error('useActions must be used within a GlobalProvider');
  }

  const saveOktaIdToken = useCallback(
    (tokenObj, auth) => {
      return new Promise((resolve) => {
        auth._oktaAuth.tokenManager.add(OKTA_ID_TOKEN_KEY, tokenObj);
        dispatch({
          type: ACTION_TYPES.SAVE_OKTA_ID_TOKEN,
          payload: tokenObj,
        });
        resolve();
      });
    },
    [dispatch]
  );

  const saveOktaAccessToken = useCallback(
    (tokenObj, auth) => {
      return new Promise((resolve) => {
        api.setAuthHeader(tokenObj.accessToken).then(() => {
          auth._oktaAuth.tokenManager.add(OKTA_ACCESS_TOKEN_KEY, tokenObj);
          dispatch({
            type: ACTION_TYPES.SAVE_OKTA_ACCESS_TOKEN,
            payload: tokenObj,
          });
          resolve();
        });
      });
    },
    [dispatch]
  );

  const saveOktaLoginInfo = useCallback(
    (loginInfo) => {
      dispatch({
        type: ACTION_TYPES.SAVE_OKTA_LOGIN_INFO,
        payload: loginInfo,
      });
    },
    [dispatch]
  );

  const clearOktaLoginInfo = useCallback(() => {
    dispatch({
      type: ACTION_TYPES.CLEAR_OKTA_LOGIN_INFO,
      payload: null,
    });
  }, [dispatch]);

  const updateEnrollmentApplication = useCallback(
    (application) => {
      dispatch({
        type: ACTION_TYPES.UPDATE_ENROLLMENT_APPLICATION,
        payload: application,
      });
    },
    [dispatch]
  );

  const clearEnrollmentApplication = useCallback(() => {
    dispatch({
      type: ACTION_TYPES.CLEAR_ENROLLMENT_APPLICATION,
    });
  }, [dispatch]);

  const clearQuote = useCallback(() => {
    dispatch({
      type: ACTION_TYPES.CLEAR_QUOTE,
    });
  }, [dispatch]);

  const closeCCPABanner = useCallback(() => {
    dispatch({
      type: ACTION_TYPES.CLOSE_CCPA_BANNER,
      payload: false,
    });
  }, [dispatch]);

  const createQuote = useCallback(
    (enrollmentQuote) => {
      dispatch(createQuoteRequest(enrollmentQuote));
      return new Promise((resolve, reject) => {
        api
          .createQuote(enrollmentQuote)
          .then((quote) => {
            dispatch(createQuoteSuccess(quote));
            resolve(quote);
          })
          .catch((error) => {
            dispatch(createQuoteFailure(error));
            reject(error);
          });
      });
    },
    [dispatch]
  );

  const getQuote = useCallback(
    (quoteNumber) => {
      dispatch(getQuoteRequest(quoteNumber));
      return new Promise((resolve, reject) => {
        api
          .getQuote(quoteNumber)
          .then((quote) => {
            dispatch(getQuoteSuccess(quote));
            resolve(quote);
          })
          .catch((error) => {
            dispatch(getQuoteFailure(error));
            reject(error);
          });
      });
    },
    [dispatch]
  );

  const updateQuote = useCallback(
    (quoteNumber, quote) => {
      dispatch(updateQuoteRequest(quoteNumber, quote));
      return new Promise((resolve, reject) => {
        api
          .updateQuote(quoteNumber, quote)
          .then((quote) => {
            dispatch(updateQuoteSuccess(quote));
            resolve(quote);
          })
          .catch((error) => {
            dispatch(updateQuoteFailure(error));
            reject(error);
          });
      });
    },
    [dispatch]
  );

  const getQuoteVars = useCallback(
    (quoteNumber) => {
      dispatch(getQuoteVarsRequest(quoteNumber));
      return new Promise((resolve, reject) => {
        api
          .getQuoteVars(quoteNumber)
          .then((variables) => {
            dispatch(getQuoteVarsSuccess(variables));
            resolve(variables);
          })
          .catch((error) => {
            dispatch(getQuoteVarsFailure(error));
            reject(error);
          });
      });
    },
    [dispatch]
  );

  const getQuoteCheckout = useCallback(
    (quoteNumber, application) => {
      dispatch(getQuoteCheckoutRequest(quoteNumber, application));
      return new Promise((resolve, reject) => {
        api
          .getQuoteCheckout(quoteNumber, application)
          .then((quoteCheckout) => {
            dispatch(getQuoteCheckoutSuccess(quoteCheckout));
            resolve(quoteCheckout);
          })
          .catch((error) => {
            dispatch(getQuoteCheckoutFailure(error));
            reject(error);
          });
      });
    },
    [dispatch]
  );

  const finalizeQuote = useCallback(
    (quoteNumber, opts) => {
      dispatch(finalizeQuoteRequest(quoteNumber));
      return new Promise((resolve, reject) => {
        api
          .finalizeQuote(quoteNumber, opts)
          .then((finalizeInfo) => {
            dispatch(finalizeQuoteSuccess(finalizeInfo));
            resolve(finalizeInfo);
          })
          .catch((error) => {
            dispatch(finalizeQuoteFailure(error));
            reject(error);
          });
      });
    },
    [dispatch]
  );

  const getPolicies = useCallback(() => {
    dispatch(getPoliciesRequest());
    return new Promise((resolve, reject) => {
      api
        .getPolicies()
        .then((quote) => {
          dispatch(getPoliciesSuccess(quote));
          resolve(quote);
        })
        .catch((error) => {
          dispatch(getPoliciesFailure(error));
          reject(error);
        });
    });
  }, [dispatch]);

  const getPolicy = useCallback(
    (policyId) => {
      dispatch(getPolicyRequest(policyId));
      return new Promise((resolve, reject) => {
        api
          .getPolicy(policyId)
          .then((policy) => {
            dispatch(getPolicySuccess(policy));
            resolve(policy);
          })
          .catch((error) => {
            dispatch(getPolicyFailure(error));
            reject(error);
          });
      });
    },
    [dispatch]
  );

  const updatePolicy = useCallback(
    (updatedPolicy) => {
      const hasOpenOrLostBillingDispute = getHasOpenOrLostBillingDispute(
        updatedPolicy
      );
      return new Promise((resolve, reject) => {
        if (hasOpenOrLostBillingDispute) {
          reject(
            Object.assign(new Error(), {
              code: POLICY_UNDER_DISPUTE_ERROR_CODE,
            })
          );
        } else {
          dispatch(updatePolicyRequest(updatedPolicy));
          api
            .updatePolicy(updatedPolicy)
            .then((policy) => {
              dispatch(updatePolicySuccess(policy));
              resolve(policy);
            })
            .catch((error) => {
              dispatch(updatePolicyFailure(error));
              reject(error);
            });
        }
      });
    },
    [dispatch]
  );

  const getPolicyVars = useCallback(
    (policyId) => {
      dispatch(getPolicyVarsRequest(policyId));
      return new Promise((resolve, reject) => {
        api
          .getPolicyVars(policyId)
          .then((variables) => {
            dispatch(getPolicyVarsSuccess(variables));
            resolve(variables);
          })
          .catch((error) => {
            dispatch(getPolicyVarsFailure(error));
            reject(error);
          });
      });
    },
    [dispatch]
  );

  const getPolicyCheckout = useCallback(
    (policy) => {
      dispatch(getPolicyCheckoutRequest(policy));
      return new Promise((resolve, reject) => {
        api
          .getPolicyCheckout(policy)
          .then((policyCheckout) => {
            dispatch(getPolicyCheckoutSuccess(policyCheckout));
            resolve(policyCheckout);
          })
          .catch((error) => {
            dispatch(getPolicyCheckoutFailure(error));
            reject(error);
          });
      });
    },
    [dispatch]
  );

  const cancelPolicy = useCallback(
    (policy, reason) => {
      const hasOpenOrLostBillingDispute = getHasOpenOrLostBillingDispute(
        policy
      );

      return new Promise((resolve, reject) => {
        if (hasOpenOrLostBillingDispute) {
          reject(
            Object.assign(new Error(), {
              code: POLICY_UNDER_DISPUTE_ERROR_CODE,
            })
          );
        } else {
          dispatch(cancelPolicyRequest(reason));
          api
            .cancelPolicy(policy, reason)
            .then((policy) => {
              dispatch(cancelPolicySuccess(policy));
              resolve(policy);
            })
            .catch((error) => {
              dispatch(cancelPolicyFailure(error));
              reject(error);
            });
        }
      });
    },
    [dispatch]
  );

  const getUser = useCallback(() => {
    dispatch(getUserRequest());
    return new Promise((resolve, reject) => {
      api
        .getUser()
        .then((user) => {
          window.analytics.identify({
            first_name: get(user, 'client.person.first_name'),
            last_name: get(user, 'client.person.last_name'),
            email: get(user, 'client.person.email'),
            phone_number: get(user, 'client.person.phone_number'),
            address: omit(get(user, 'client.person.address'), 'id'),
          });
          dispatch(getUserSuccess(user));
          resolve(user);
        })
        .catch((error) => {
          dispatch(getUserFailure(error));
          reject(error);
        });
    });
  }, [dispatch]);

  const refreshOktaTokens = useCallback(
    (auth) => {
      dispatch(refreshOktaTokensRequest());
      return Promise.all([
        auth._oktaAuth.tokenManager.renew(OKTA_ID_TOKEN_KEY),
        auth._oktaAuth.tokenManager.renew(OKTA_ACCESS_TOKEN_KEY),
      ])
        .then(([renewedIdToken, renewedAccessToken]) => {
          window.analytics.identify(get(renewedIdToken, 'claims.sub'));
          api.setAuthHeader(renewedAccessToken.accessToken);
          dispatch(
            refreshOktaTokensSuccess([renewedIdToken, renewedAccessToken])
          );
          return [renewedIdToken, renewedAccessToken];
        })
        .catch((error) => {
          dispatch(refreshOktaTokensFailure(error));
          return error;
        });
    },
    [dispatch]
  );

  const logout = useCallback(
    (auth) => {
      auth._oktaAuth.tokenManager.remove(OKTA_ID_TOKEN_KEY);
      auth._oktaAuth.tokenManager.remove(OKTA_ACCESS_TOKEN_KEY);
      api.deleteAuthHeader();
      window.analytics.reset();
      dispatch({
        type: ACTION_TYPES.LOGOUT,
      });
    },
    [dispatch]
  );

  const getClaims = useCallback(
    (policies) => {
      return new Promise((resolve, reject) => {
        dispatch(getPolicyClaims(policies));

        const policyClaimsCalls = policies.map((policy) =>
          api.getClaims(policy.id).then((claims) => {
            return claims.map((claim) => {
              claim.policyNumber = policy.policy_number;
              return claim;
            });
          })
        );

        return Promise.all(policyClaimsCalls)
          .then((aggregateClaims) => {
            const flattenedClaims = flatMap(aggregateClaims);
            dispatch(getPolicyClaimsSuccess(flattenedClaims));
            resolve(flattenedClaims);
          })
          .catch((error) => {
            dispatch(getPolicyClaimsFailure(error));
            reject(error);
          });
      });
    },
    [dispatch]
  );

  const updateCachedQuoteHealthCheckState = useCallback(
    (payload) =>
      dispatch({
        type: ACTION_TYPES.UPDATE_CACHED_QUOTE_HEALTH_CHECK_STATE,
        payload,
      }),
    [dispatch]
  );

  const exchangeQuoteAuthToken = useCallback(
    (quoteNumber, token) => {
      dispatch(exchangeQuoteAuthTokenRequest(quoteNumber, token));
      return new Promise((resolve, reject) => {
        api
          .exchangeQuoteAuthToken(quoteNumber, token)
          .then(({ token }) => {
            api.setAuthHeader(token);
            dispatch(exchangeQuoteAuthTokenSuccess(token));
            resolve(token);
          })
          .catch((error) => {
            dispatch(exchangeQuoteAuthTokenFailure(error));
            reject(error);
          });
      });
    },
    [dispatch]
  );

  const getFeatureFlags = useCallback(() => {
    dispatch(getFeatureFlagsRequest());
    return new Promise((resolve, reject) => {
      api
        .getFeatureFlags()
        .then((featureFlags) => {
          dispatch(getFeatureFlagsSuccess(featureFlags));
          resolve(featureFlags);
        })
        .catch((error) => {
          dispatch(getFeatureFlagsFailure(error));
          reject(error);
        });
    });
  }, [dispatch]);

  return {
    updateEnrollmentApplication,
    clearEnrollmentApplication,
    clearQuote,
    createQuote,
    getQuote,
    updateQuote,
    getQuoteVars,
    getQuoteCheckout,
    updateCachedQuoteHealthCheckState,
    finalizeQuote,

    getPolicies,
    getPolicy,
    updatePolicy,
    getPolicyVars,
    getPolicyCheckout,
    cancelPolicy,

    saveOktaIdToken,
    saveOktaAccessToken,
    saveOktaLoginInfo,
    clearOktaLoginInfo,
    refreshOktaTokens,

    getUser,

    getClaims,

    logout,

    exchangeQuoteAuthToken,

    closeCCPABanner,

    getFeatureFlags,
  };
}
