import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';
import classnames from 'classnames';
import wlConfig from '../../constants/wlConfig';

const {
  theme: {
    styles: {
      buttonStyles: { mobileTenantButton, tenantButton },
    },
  },
} = wlConfig;

const useStyles = makeStyles((theme) => {
  const { breakpoints } = theme;

  return {
    tenantButton: {
      ...tenantButton,
      [breakpoints.down('sm')]: {
        ...mobileTenantButton,
      },
    },
  };
});

function Button(props) {
  const { children, className, variant, color, ...otherProps } = props;
  const classes = useStyles();

  if (variant === 'tenant') {
    return (
      <MuiButton
        className={classnames(classes.tenantButton, className)}
        variant="contained"
        color="primary"
        data-testid="button_tenant"
        {...otherProps}
      >
        {children}
      </MuiButton>
    );
  } else {
    return <MuiButton {...props} />;
  }
}

export default Button;
