import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import compact from 'lodash/compact';

import { FOOTER_NAV_ITEMS } from '../../constants/navigationItems';
import { ReactComponent as TwitterIcon } from '../../assets/img/social/icon-twitter.svg';
import { ReactComponent as LinkedinIcon } from '../../assets/img/social/icon-linkedin.svg';
import { ReactComponent as InstagramIcon } from '../../assets/img/social/icon-instagram.svg';
import { ReactComponent as FacebookIcon } from '../../assets/img/social/icon-facebook.svg';
import { ReactComponent as BuiltOnSureLogo } from '../../assets/img/built-on-sure.svg';

import wlConfig from '../../constants/wlConfig';

const {
  theme: {
    styles: {
      footerStyles: { backgroundColor, color, footerCopy, builtOnSure },
    },
  },
  footerConfig: {
    hasFooterNavItems,
    copyrightDisclaimer,
    hasSocialListItems,
    copyrightCopy,
    showRightsReserved,
    footerCopyText,
  },
} = wlConfig;

const propTypes = {
  customClasses: PropTypes.object,
  withRightsReserved: PropTypes.bool,
};

const defaultProps = {
  copyrightCopy,
  customClasses: {},
  footerCopyText,
  hasFooterNavItems,
  hasSocialListItems,
  showRightsReserved,
  withRightsReserved: false,
};

const useStyles = makeStyles((theme) => {
  const {
    palette: { tenant },
    breakpoints,
  } = theme;

  return {
    container: {
      background: backgroundColor,
      color: tenant.white,
      textAlign: 'center',
      paddingTop: 32,
      paddingBottom: 100,
      [breakpoints.up('sm')]: {
        paddingTop: 56,
      },
      [breakpoints.up('md')]: {
        paddingTop: 48,
      },
      [breakpoints.up('lg')]: {
        paddingBottom: 50,
      },
    },
    list: {
      listStyleType: 'none',
      margin: 0,
      padding: 0,
    },
    navList: {},
    socialList: {
      margin: '50px auto 30px auto',
      maxWidth: 300,
    },
    navItem: {
      padding: 8,
      fontWeight: 'bold',
    },
    navLink: {
      color: tenant.white,
      textDecoration: 'none',
      '&:hover': {
        color: tenant.primaryHighlight,
      },
    },
    navLogo: {
      cursor: 'pointer',
    },
    copyright: {
      color: color,
      margin: '20px auto 0px auto',
      fontWeight: 'normal',
      fontSize: 16,
    },
    copyrightParagraph: {
      marginTop: 10,
    },
    rightsReservedContainer: {
      padding: '32px 24px',
      [breakpoints.up('sm')]: {
        padding: '56px 64px',
      },
      [breakpoints.up('md')]: {
        padding: '56px 148px',
      },
    },
    rightsReservedText: {
      lineHeight: '24px',
    },
    footerCopy,
    builtOnSure,
    builtOnSureWrapper: {
      marginTop: 32,
      marginBottom: 16,
    },
  };
});

const SOCIAL_LIST_ITEMS = [
  hasSocialListItems.facebook
    ? {
        Icon: FacebookIcon,
        url: hasSocialListItems.facebook.url,
      }
    : null,
  hasSocialListItems.twitter
    ? {
        Icon: TwitterIcon,
        url: hasSocialListItems.twitter.url,
      }
    : null,
  hasSocialListItems.instagram
    ? {
        Icon: InstagramIcon,
        url: hasSocialListItems.instagram.url,
      }
    : null,
  hasSocialListItems.linkedin
    ? {
        Icon: LinkedinIcon,
        url: hasSocialListItems.linkedin.url,
      }
    : null,
];

const CURRENT_YEAR = moment().format('YYYY');

function RightsReservedFooter({ classes }) {
  return (
    <Container
      className={classes.rightsReservedContainer}
      data-testid="Footer-rightsReservedContainer"
    >
      <Typography
        className={classes.rightsReservedText}
        variant="subtitle2"
        gutterBottom
      >
        &copy;{CURRENT_YEAR} Farmers Group, Inc. All rights reserved.
      </Typography>
      <br />
      <Typography
        className={classes.rightsReservedText}
        variant="subtitle2"
        gutterBottom
      >
        Advertisement produced on behalf of the following specific insurers and
        seeking to obtain business for insurance underwritten by Mid-Century
        Insurance Company. Home office, Los Angeles, CA.
      </Typography>
      <br />
      <Typography
        className={classes.rightsReservedText}
        variant="subtitle2"
        gutterBottom
      >
        Each of following insurers who transact business in California are
        domiciled in California and have their principal place of business in
        Los Angeles, CA: Mid-Century Insurance Company (#1428-2).
      </Typography>
      <br />
      <Typography
        className={classes.rightsReservedText}
        variant="subtitle2"
        gutterBottom
      >
        Each insurer has sole financial responsibility for its own insurance.
        List of all insurers at farmers.com. Not all insurers are authorized in
        all states. Not all products, coverages, features and discounts are
        available in every state and may vary by state. See your policy contract
        for complete details, including controlling terms and conditions.
        Restrictions, exclusions, and limits apply.
      </Typography>
    </Container>
  );
}

function Footer({
  copyrightCopy,
  customClasses,
  footerCopyText,
  footerLogo,
  hasBuiltOnSure,
  hasFooterNavItems,
  hasSocialListItems,
  history,
  showRightsReserved,
  withRightsReserved,
}) {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  return (
    <>
      <Container
        maxWidth={false}
        className={classnames(classes.container, customClasses.footer)}
        data-testid="Footer-base"
      >
        <Container maxWidth="md">
          {footerCopyText && (
            <Typography
              className={classes.footerCopy}
              variant="subtitle2"
              gutterBottom
              data-testid="Footer-copyText"
            >
              {footerCopyText}
            </Typography>
          )}
          {hasFooterNavItems && (
            <Grid
              container
              direction={isMobile ? 'row' : 'column'}
              justify="space-around"
              alignItems="center"
              className={classnames(classes.list, classes.navList)}
              component="ul"
              data-testid="Footer-navItems"
            >
              {FOOTER_NAV_ITEMS.map(
                ({ label, url, testid, isDifferentDomain = false }, i) => (
                  <li key={i} className={classes.navItem}>
                    {isDifferentDomain ? (
                      <a
                        href={url}
                        className={classes.navLink}
                        data-testid={`footer_link_${testid}`}
                      >
                        {label}
                      </a>
                    ) : (
                      <RouterLink
                        to={url}
                        className={classes.navLink}
                        data-testid={`footer_link_${testid}`}
                      >
                        {label}
                      </RouterLink>
                    )}
                  </li>
                )
              )}
            </Grid>
          )}
          {hasSocialListItems && (
            <Grid
              container
              direction="row"
              justify="space-evenly"
              alignItems="center"
              className={classnames(classes.list, classes.socialList)}
              component="ul"
              data-testid="Footer-socialListItems"
            >
              {compact(SOCIAL_LIST_ITEMS).map(({ Icon, url }, i) => (
                <li key={i} className={classes.navItem}>
                  <Link
                    href={url}
                    target="_blank"
                    rel="nofollow noreferrer noopener"
                  >
                    <Icon />
                  </Link>
                </li>
              ))}
            </Grid>
          )}
          {hasBuiltOnSure && (
            <Box
              data-testid="Footer-builtOnSureLogo"
              className={classes.builtOnSureWrapper}
            >
              <BuiltOnSureLogo className={classes.builtOnSure} />
            </Box>
          )}
          {!!footerLogo && (
            <img
              src={footerLogo}
              alt="logo"
              className={classes.navLogo}
              onClick={() => history.push('/')}
              data-testid="Footer-footerLogo"
            />
          )}
          {!!copyrightCopy && (
            <>
              <Typography
                align="center"
                variant="subtitle2"
                className={classes.copyright}
                data-testid="Footer-copyright"
              >
                &copy;{CURRENT_YEAR} {copyrightCopy}
              </Typography>
              {copyrightDisclaimer &&
                copyrightDisclaimer.map((text) => (
                  <Typography key={text} className={classes.copyrightParagraph}>
                    {text}
                  </Typography>
                ))}
            </>
          )}
        </Container>
      </Container>
      {showRightsReserved && withRightsReserved && (
        <RightsReservedFooter classes={classes} />
      )}
    </>
  );
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default withRouter(Footer);
