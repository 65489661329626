import React, { useContext, createContext, useEffect } from 'react';
import reducers from './reducers';
import { useReducerWithLogger } from './middleware';
import { ActionsContext } from './actions';

const GlobalStateContext = createContext();

export function GlobalProvider({ children, initialStore = {} }) {
  const [state, dispatch] = useReducerWithLogger(reducers, initialStore);

  useEffect(
    function hydrateInitialStoreWithDefaults() {
      reducers(initialStore, {});
    },
    [initialStore]
  );

  return (
    <GlobalStateContext.Provider value={state}>
      <ActionsContext.Provider value={dispatch}>
        {children}
      </ActionsContext.Provider>
    </GlobalStateContext.Provider>
  );
}

export function useGlobalState() {
  const globalState = useContext(GlobalStateContext);
  if (typeof globalState === undefined) {
    throw new Error('useGlobalState must be used within a GlobalProvider');
  }
  return { isLoggedIn: !!globalState.oktaAccessToken, ...globalState };
}
