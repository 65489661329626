import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import logException from '../../services/logException';
import ErrorView from '../../views/ErrorView';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      info: null,
    };
  }

  componentDidCatch(error, info) {
    console.error('Error Boundary', error, info);
    logException(error, info);
    this.setState({
      error,
      info,
    });
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;
    return error ? <ErrorView /> : children;
  }
}

export default withRouter(ErrorBoundary);
