import React from 'react';
import LayoutRoot from '../../components/LayoutRoot';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import { useGlobalState } from '../../store';
import wlConfig from '../../constants/wlConfig';

const {
  theme: {
    fonts: { headerFonts },
    styles: {
      faqStyles: {
        container,
        faqHeaderColor,
        topicSectionLabelColor,
        topicLabelColor,
        faqBackground,
        topicAnswerColor,
        labelDividerColor,
      },
    },
  },
  faqConfig,
} = wlConfig;

const useStyles = makeStyles((theme) => {
  const { breakpoints } = theme;

  return {
    faqContainer: {
      background: faqBackground,
      padding: '32px 24px',
      [breakpoints.up('sm')]: {
        padding: '56px 64px',
      },
      [breakpoints.up('md')]: {
        padding: '58px 148px',
      },
      ...container,
    },
    faqHeader: {
      color: faqHeaderColor,
      marginBottom: 16,
      [breakpoints.up('sm')]: {
        marginBottom: 40,
      },
    },
    faqSubHeader: {
      marginBottom: 24,
    },
    topicGrid: {
      marginBottom: 64,
      '&:last-of-type': {
        marginBottom: 0,
      },
    },
    expansionPanel: {
      background: 'none',
      borderTop: `1px solid ${labelDividerColor}`,
      '&:before': {
        height: 0,
        backgroundColor: labelDividerColor,
      },
    },
    expansionIcon: {
      color: faqHeaderColor,
    },
    topicSectionLabel: {
      color: topicSectionLabelColor,
      [breakpoints.down('lg')]: {
        marginBottom: 24,
      },
      [breakpoints.down('md')]: {
        marginBottom: 32,
      },
    },
    topicLabel: {
      color: topicLabelColor,
    },
    answerText: {
      fontFamily: headerFonts.names.join(','),
      whiteSpace: 'pre-wrap',
      color: topicAnswerColor,
      [breakpoints.down('md')]: {
        fontSize: 14,
      },
    },
  };
});

function TopicQuestionPanels({ classes, isDesktop, label, questions }) {
  return (
    <Grid
      container
      className={classes.topicGrid}
      direction={isDesktop ? 'row' : 'column'}
      alignItems="flex-start"
    >
      <Grid item xs={12} sm={12} md={12} lg={3}>
        <Typography
          className={classes.topicSectionLabel}
          component="h3"
          variant="h4"
          align="left"
        >
          {label}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={9}>
        {questions.map(({ question, answer }, i) => (
          <ExpansionPanel
            className={classes.expansionPanel}
            key={i}
            square={true}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon className={classes.expansionIcon} />}
            >
              <Typography
                component="h4"
                variant="h5"
                className={classes.topicLabel}
              >
                {question}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography
                variant="body1"
                component="p"
                className={classes.answerText}
              >
                {answer}
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))}
      </Grid>
    </Grid>
  );
}

function FAQView() {
  const classes = useStyles();
  const { isLoggedIn } = useGlobalState();
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  return (
    <LayoutRoot
      withFooter
      withRightsReservedFooter={!isLoggedIn}
      withTopNavigation
      withAlternateBackground={isDesktop}
    >
      <Container className={classes.faqContainer} maxWidth={false}>
        <Container>
          <Typography
            className={classes.faqHeader}
            component="h1"
            variant="h3"
            align="left"
          >
            Frequently Asked Questions
          </Typography>
          <Typography
            className={classes.faqSubHeader}
            component="h2"
            variant="h5"
            align="left"
          >
            Popular topics
          </Typography>
          {faqConfig.map(({ label, questions }, i) => (
            <TopicQuestionPanels
              key={i}
              classes={classes}
              isDesktop={isDesktop}
              label={label}
              questions={questions}
            />
          ))}
        </Container>
      </Container>
    </LayoutRoot>
  );
}

export default FAQView;
