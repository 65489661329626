import zipObject from 'lodash/zipObject';

/*
 * App actions
 */

const ACTIONS = [
  'UPDATE_ENROLLMENT_APPLICATION',
  'CLEAR_ENROLLMENT_APPLICATION',

  'CLEAR_QUOTE',

  'CREATE_QUOTE',
  'CREATE_QUOTE_SUCCESS',
  'CREATE_QUOTE_FAILURE',

  'GET_QUOTE',
  'GET_QUOTE_SUCCESS',
  'GET_QUOTE_FAILURE',

  'UPDATE_QUOTE',
  'UPDATE_QUOTE_SUCCESS',
  'UPDATE_QUOTE_FAILURE',

  'GET_QUOTE_VARS',
  'GET_QUOTE_VARS_SUCCESS',
  'GET_QUOTE_VARS_FAILURE',

  'GET_QUOTE_CHECKOUT',
  'GET_QUOTE_CHECKOUT_SUCCESS',
  'GET_QUOTE_CHECKOUT_FAILURE',

  'GET_VEHICLES_YEARS',
  'GET_VEHICLES_YEARS_SUCCESS',
  'GET_VEHICLES_YEARS_FAILURE',

  'GET_VEHICLES_MAKES',
  'GET_VEHICLES_MAKES_SUCCESS',
  'GET_VEHICLES_MAKES_FAILURE',

  'GET_VEHICLES_MODELS',
  'GET_VEHICLES_MODELS_SUCCESS',
  'GET_VEHICLES_MODELS_FAILURE',

  'UPDATE_CACHED_QUOTE_HEALTH_CHECK_STATE',

  'FINALIZE_QUOTE',
  'FINALIZE_QUOTE_SUCCESS',
  'FINALIZE_QUOTE_FAILURE',

  'GET_POLICIES',
  'GET_POLICIES_SUCCESS',
  'GET_POLICIES_FAILURE',

  'GET_POLICY',
  'GET_POLICY_SUCCESS',
  'GET_POLICY_FAILURE',

  'UPDATE_POLICY',
  'UPDATE_POLICY_SUCCESS',
  'UPDATE_POLICY_FAILURE',

  'GET_POLICY_VARS',
  'GET_POLICY_VARS_SUCCESS',
  'GET_POLICY_VARS_FAILURE',

  'GET_POLICY_CHECKOUT',
  'GET_POLICY_CHECKOUT_SUCCESS',
  'GET_POLICY_CHECKOUT_FAILURE',

  'CANCEL_POLICY',
  'CANCEL_POLICY_SUCCESS',
  'CANCEL_POLICY_FAILURE',

  'SAVE_OKTA_ID_TOKEN',
  'SAVE_OKTA_ACCESS_TOKEN',
  'SAVE_OKTA_LOGIN_INFO',
  'CLEAR_OKTA_LOGIN_INFO',

  'REFRESH_OKTA_TOKENS',
  'REFRESH_OKTA_TOKENS_SUCCESS',
  'REFRESH_OKTA_TOKENS_FAILURE',

  'GET_USER',
  'GET_USER_SUCCESS',
  'GET_USER_FAILURE',

  'GET_CLAIMS',
  'GET_CLAIMS_SUCCESS',
  'GET_CLAIMS_FAILURE',

  'LOGOUT',

  'CLOSE_CCPA_BANNER',

  'EXCHANGE_QUOTE_AUTH_TOKEN',
  'EXCHANGE_QUOTE_AUTH_TOKEN_SUCCESS',
  'EXCHANGE_QUOTE_AUTH_TOKEN_FAILURE',

  'GET_FEATURE_FLAGS',
  'GET_FEATURE_FLAGS_SUCCESS',
  'GET_FEATURE_FLAGS_FAILURE',
];

const ACTION_TYPES = zipObject(ACTIONS, ACTIONS);

export default ACTION_TYPES;
