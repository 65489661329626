import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  createContext,
  useCallback,
} from 'react';
import { withRouter } from 'react-router-dom';
import logException from '../../services/logException';
import qs from 'qs';

const ErrorContext = createContext();

export function useErrorHandler() {
  const { setError, setErrorInfo } = useContext(ErrorContext);

  if (typeof setError === undefined) {
    throw new Error(
      'useErrorHandler must be used within an ErrorContextProvider'
    );
  }

  return useCallback(
    (error, info) => {
      setError(error);
      setErrorInfo(info);
    },
    [setError, setErrorInfo]
  );
}

export const ErrorContextProvider = withRouter(({ children, history }) => {
  const [error, setError] = useState(null);
  const [errorInfo, setErrorInfo] = useState(null);

  const cachedHistory = useMemo(() => history, [history]);

  useEffect(() => {
    if (error) {
      function handleError(errorCode, sentryEventID) {
        switch (errorCode) {
          default:
            const params = {
              code: errorCode,
            };
            if (process.env.REACT_APP_ENV !== 'production') {
              params.id = sentryEventID;
            }
            const queryStr = qs.stringify(params);
            cachedHistory.push(`/error?${queryStr}`);
            break;
        }
      }

      logException(error, errorInfo)
        .then((sentryEventID) => {
          handleError(error.code, sentryEventID);
        })
        .catch(() => {
          handleError(error.code);
        });
      setError(null);
      setErrorInfo(null);
    }
  }, [cachedHistory, error, errorInfo]);

  return (
    <ErrorContext.Provider value={{ setError, setErrorInfo }}>
      {children}
    </ErrorContext.Provider>
  );
});
