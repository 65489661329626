import React, { useContext, createContext, useState, useEffect } from 'react';
import ModalDialog from '../components/ModalDialog';
import Typography from '@material-ui/core/Typography';
import moment from 'moment-timezone';

const SessionTimeoutContext = createContext();

export function useSessionTimeout(
  timestamp,
  maxElapsedTimeMinutes,
  onConfirmDestination
) {
  const { setModalOnConfirmDestination } = useContext(SessionTimeoutContext);

  function checkSessionTimeout() {
    return new Promise((resolve) => {
      const timeSinceUpdate = moment
        .utc()
        .diff(moment.utc(timestamp), 'minutes');
      if (timeSinceUpdate > maxElapsedTimeMinutes) {
        setModalOnConfirmDestination(onConfirmDestination);
      } else {
        resolve();
      }
    });
  }

  useEffect(
    function checkSessionTimeoutOnLoad() {
      const timeSinceUpdate = moment
        .utc()
        .diff(moment.utc(timestamp), 'minutes');
      if (timeSinceUpdate > maxElapsedTimeMinutes) {
        setModalOnConfirmDestination(onConfirmDestination);
      }
    },
    [
      maxElapsedTimeMinutes,
      onConfirmDestination,
      setModalOnConfirmDestination,
      timestamp,
    ]
  );

  return {
    checkSessionTimeout,
  };
}

export function SessionTimeoutContextProvider({ children }) {
  const [modalOnConfirmDestination, setModalOnConfirmDestination] = useState(
    ''
  );

  return (
    <SessionTimeoutContext.Provider
      value={{
        setModalOnConfirmDestination,
      }}
    >
      {children}
      <ModalDialog
        open={!!modalOnConfirmDestination}
        onDismiss={() => {}}
        modalTitle="Session timed out"
        confirmLabel="Start new quote"
        dismissLabel=""
        onConfirm={() => (window.location.href = modalOnConfirmDestination)}
        isAlert
        data-testid="sessionExpired_modal"
      >
        <Typography variant="body1">
          Your quote session has timed out due to inactivity.
        </Typography>
      </ModalDialog>
    </SessionTimeoutContext.Provider>
  );
}
