import {
  COVERAGE_UPDATE_SNACKBARS,
  coverageUpdateSnackbarConfig as COVERAGE_SNACKBARS_CONFIG,
} from './coverageUpdateSnackbars';

export const SNACKBARS = {
  AGE_INVALID_ERROR: 'AGE_INVALID_ERROR',
  INVALID_DATE_ERROR: 'INVALID_DATE_ERROR',
  FUTURE_DATE_ERROR: 'FUTURE_DATE_ERROR',
  PAST_DATE_ERROR: 'PAST_DATE_ERROR',
  ATTACHED_RIDESHARE: 'ATTACHED_RIDESHARE',
  TERMS_OF_USE: 'TERMS_OF_USE',
  DRIVER_RELATIONSHIP_ERROR: 'DRIVER_RELATIONSHIP_ERROR',
  MISSING_SIGNATURES: 'MISSING_SIGNATURES',
  REVIEW_QUOTE_INVALID_DATE_ERROR: 'REVIEW_QUOTE_INVALID_DATE_ERROR',
  CHECKOUT_ERROR: 'CHECKOUT_ERROR',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',
  OLD_PASSWORD_INCORRECT: 'OLD_PASSWORD_INCORRECT',
  ADD_DRIVER_SUCCESS: 'ADD_DRIVER_SUCCESS',
  UPDATE_DRIVER_SUCCESS: 'UPDATE_DRIVER_SUCCESS',
  REMOVE_DRIVER_SUCCESS: 'REMOVE_DRIVER_SUCCESS',
  DRIVER_LIMIT: 'DRIVER_LIMIT',
  ADD_VEHICLE_SUCCESS: 'ADD_VEHICLE_SUCCESS',
  UPDATE_VEHICLE_SUCCESS: 'UPDATE_VEHICLE_SUCCESS',
  REMOVE_VEHICLE_SUCCESS: 'REMOVE_VEHICLE_SUCCESS',
  VEHICLE_LIMIT: 'VEHICLE_LIMIT',
  VEHICLE_MODAL_ERROR: 'VEHICLE_MODAL_ERROR',
  DRIVER_MODAL_ERROR: 'DRIVER_MODAL_ERROR',
  DRIVER_MODAL_DOB_ERROR: 'DRIVER_MODAL_DOB_ERROR',
  ...COVERAGE_UPDATE_SNACKBARS,
  UPDATE_PAYMENT_AND_CHARGE_CARD: 'UPDATE_PAYMENT_AND_CHARGE_CARD',
  CARD_INCOMPLETE: 'CARD_INCOMPLETE',
  INVALID_DRIVER: 'INVALID_DRIVER',
  UPDATE_PAYMENT_SUCCESS: 'UPDATE_PAYMENT_SUCCESS',
  UPDATE_POLICY_SUCCESS: 'UPDATE_POLICY_SUCCESS',
  UNSTAGE_POLICY_CHANGES: 'UNSTAGE_POLICY_CHANGES',
  ADDRESS_MODAL_ERROR: 'ADDRESS_MODAL_ERROR',
  INVALID_FORM_ERROR: 'INVALID_FORM_ERROR',
  PREPAID_CARD: 'PREPAID_CARD',
  DOWNLOAD_DIGITAL_ID_ERROR: 'DOWNLOAD_DIGITAL_ID_ERROR',
  POLICY_LOCKED: 'POLICY_LOCKED',
};

const GENERAL_SNACKBARS = [
  {
    id: SNACKBARS.INVALID_FORM_ERROR,
    dataTestid: 'invalid_form_error_snackbar',
    message: 'Please fill in required information.',
  },
];

const COVERAGE_DATE_SNACKBARS = [
  {
    id: SNACKBARS.FUTURE_DATE_ERROR,
    dataTestid: 'future_date_error_snackbar',
    message: 'The coverage start date cannot be over 30 days in the future',
  },
  {
    id: SNACKBARS.PAST_DATE_ERROR,
    dataTestid: 'past_date_error_snackbar',
    message: 'The coverage start date cannot be in the past',
  },
  {
    id: SNACKBARS.INVALID_DATE_ERROR,
    dataTestid: 'invalid_date_error_snackbar',
    message: 'Please enter a valid date',
  },
];

const DRIVER_SNACKBARS = [
  {
    id: SNACKBARS.ADD_DRIVER_SUCCESS,
    dataTestid: 'add_driver_success_snackbar',
    message: 'Driver added successfully',
    hasUndoAction: true,
  },
  {
    id: SNACKBARS.UPDATE_DRIVER_SUCCESS,
    dataTestid: 'update_driver_success_snackbar',
    message: 'Driver updated successfully',
  },
  {
    id: SNACKBARS.DRIVER_LIMIT,
    dataTestid: 'driver_limit_snackbar',
    message:
      'You have reached the maximum number of drivers allowed on a policy.',
  },
  {
    id: SNACKBARS.REMOVE_DRIVER_SUCCESS,
    dataTestid: 'remove_driver_success_snackbar',
    message: 'Driver removed',
    hasUndoAction: true,
  },
  {
    id: SNACKBARS.DRIVER_MODAL_DOB_ERROR,
    dataTestid: 'invalid_driver_age_error_snackbar',
    message: 'Age should be above 15 years.',
  },
];

const VEHICLE_SNACKBARS = [
  {
    id: SNACKBARS.ADD_VEHICLE_SUCCESS,
    dataTestid: 'add_vehicle_success_snackbar',
    message: 'Vehicle added successfully',
    hasUndoAction: true,
  },
  {
    id: SNACKBARS.UPDATE_VEHICLE_SUCCESS,
    dataTestid: 'update_vehicle_success_snackbar',
    message: 'Vehicle updated successfully',
  },
  {
    id: SNACKBARS.VEHICLE_LIMIT,
    dataTestid: 'vehicle_limit_snackbar',
    message:
      'You have reached the maximum number of vehicles allowed on a policy.',
  },
  {
    id: SNACKBARS.REMOVE_VEHICLE_SUCCESS,
    dataTestid: 'remove_vehicle_success_snackbar',
    message: 'Vehicle removed',
    hasUndoAction: true,
  },
];

const PAYMENT_SNACKBARS = [
  {
    id: SNACKBARS.POLICY_LOCKED,
    dataTestid: 'policy_locked_error_snackbar',
    message:
      'Sorry, changes cannot be saved at this time. Please try again later.',
  },
  {
    id: SNACKBARS.CARD_INCOMPLETE,
    dataTestid: 'card_incomplete_error_snackbar',
    message:
      'Unfortunately, we were unable to process your payment. Please re-enter your payment information or provide another form of payment.',
  },
  {
    id: SNACKBARS.PREPAID_CARD,
    dataTestid: 'prepaid_card_error_snackbar',
    message: `Unable to complete transaction. Prepaid cards can't be used.`,
  },
];

const DISCLOSURE_SNACKBARS = [
  {
    id: SNACKBARS.MISSING_SIGNATURES,
    dataTestid: 'missing_signatures_error_snackbar',
    message: 'Please read and sign each document.',
  },
];

export const EnrollmentFlowSnackbarConfig = [
  ...GENERAL_SNACKBARS,
  ...COVERAGE_DATE_SNACKBARS,
  {
    id: SNACKBARS.AGE_INVALID_ERROR,
    dataTestid: 'invalid_age_error_snackbar',
    message: 'Age should be above 18 years',
  },
];

export const PurchaseFlowSnackbarConfig = [
  ...EnrollmentFlowSnackbarConfig,
  ...VEHICLE_SNACKBARS,
  ...DRIVER_SNACKBARS,
  ...COVERAGE_SNACKBARS_CONFIG,
  ...PAYMENT_SNACKBARS,
  ...DISCLOSURE_SNACKBARS,
  {
    id: SNACKBARS.DRIVER_RELATIONSHIP_ERROR,
    dataTestid: 'invalid_driver_relationship_error_snackbar',
    message: 'Please define relationship.',
  },
  {
    id: SNACKBARS.CHECKOUT_ERROR,
    dataTestid: 'checkout_error_snackbar',
    message: 'Please fill in required inputs.',
  },
  {
    id: SNACKBARS.TERMS_OF_USE,
    dataTestid: 'checkout_terms_of_use_error_snackbar',
    message: 'Please read and accept the Privacy Policy and Terms of Use.',
  },
  {
    id: SNACKBARS.ATTACHED_RIDESHARE,
    dataTestid: 'rideshare_endorsement_attached_snackbar',
    message: 'We’ve attached a Rideshare Coverage Endorsement to your policy.',
  },
];

export const MyAccountSnackbarConfig = [
  ...GENERAL_SNACKBARS,
  ...PAYMENT_SNACKBARS,
  {
    id: SNACKBARS.CHANGE_PASSWORD_SUCCESS,
    dataTestid: 'change_password_success_snackbar',
    message: 'Your password has been updated.',
  },
  {
    id: SNACKBARS.CHANGE_PASSWORD_FAILURE,
    dataTestid: 'change_password_error_snackbar',
    message: 'There was an issue updating your password. Please try again.',
  },
  {
    id: SNACKBARS.OLD_PASSWORD_INCORRECT,
    dataTestid: 'old_password_incorrect_error_snackbar',
    message: 'Old password is in incorrect.',
  },
];

export const SelfServiceSnackbarConfig = [
  ...GENERAL_SNACKBARS,
  ...DRIVER_SNACKBARS,
  ...VEHICLE_SNACKBARS,
  ...COVERAGE_SNACKBARS_CONFIG,
  ...PAYMENT_SNACKBARS,
  ...DISCLOSURE_SNACKBARS,
  {
    id: SNACKBARS.INVALID_DRIVER,
    dataTestid: 'invalid_driver_error_snackbar',
    message:
      'Policy drivers cannot require SR-22 and must not have a suspended/revoked license',
  },
  {
    id: SNACKBARS.UPDATE_PAYMENT_SUCCESS,
    dataTestid: 'update_payment_success_snackbar',
    message: 'Payment method updated',
    hasUndoAction: true,
  },
  {
    id: SNACKBARS.UPDATE_POLICY_SUCCESS,
    dataTestid: 'update_policy_success_snackbar',
    message: 'Your policy has been successfully updated.',
  },
  {
    id: SNACKBARS.UNSTAGE_POLICY_CHANGES,
    dataTestid: 'unstage_policy_changes_success_snackbar',
    message: 'Your policy changes have been removed.',
  },
  {
    id: SNACKBARS.DOWNLOAD_DIGITAL_ID_ERROR,
    dataTestid: 'download_digital_id_error_snackbar',
    message:
      'Looks like something went wrong. Please try downloading the pass again.',
  },
];
