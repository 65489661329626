import React, { createContext, useContext, useEffect } from 'react';
import { withRouter } from 'react-router';

import { useActions } from '../store/actions';
import { useGlobalState } from '../store';

const FeatureFlagsContext = createContext([]);

export function useFeatureFlags(code) {
  const flags = useContext(FeatureFlagsContext);
  if (typeof flags === 'undefined') {
    throw new Error(
      'useFeatureFlags must be used within a FeatureFlagsProvider'
    );
  }

  const flag = flags?.find(({ code: flagCode }) => flagCode === code);
  return flag ? flag?.status || false : false;
}

export const FeatureFlagProvider = withRouter(({ children }) => {
  const { getFeatureFlags } = useActions();
  const { featureFlags } = useGlobalState();

  useEffect(
    function loadFeatureFlags() {
      async function fetchFlags() {
        try {
          await getFeatureFlags();
        } catch {
          throw new Error('Error loading feature flags');
        }
      }

      fetchFlags();
    },
    [getFeatureFlags]
  );

  return (
    <FeatureFlagsContext.Provider value={featureFlags}>
      {children}
    </FeatureFlagsContext.Provider>
  );
});
