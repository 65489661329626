const activeDisputeStatuses = [
  'warning_needs_response',
  'warning_under_review',
  'evidence_submitted',
  'needs_response',
  'under_review',
];

function getHasOpenOrLostBillingDispute(policy) {
  return policy.bills.some((bill) =>
    (bill.details.disputes || []).some(({ status }) =>
      [...activeDisputeStatuses, 'lost'].includes(status)
    )
  );
}

export default getHasOpenOrLostBillingDispute;
