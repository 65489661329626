import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './containers/App';
import * as Sentry from '@sentry/browser';
import * as serviceWorker from './serviceWorker';

if (
  !!process.env.REACT_APP_SENTRY_DSN &&
  process.env.REACT_APP_ENV !== 'development'
) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV,
    release: window.__COMMIT_SHA__,
  });
}

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);

serviceWorker.unregister();
