import React from 'react';
import { Link } from 'react-router-dom';
import { PATHNAMES } from '../../constants/paths';

export default [
  {
    label: 'Getting Started',
    questions: [
      {
        question:
          'Does applying for a policy negatively impact my credit score?',
        answer:
          'Applying for a policy will not affect your credit score. We do a “soft pull” credit inquiry (in states where credit is used in your rate) and soft pulls are not noted on your credit report except for listing the requester’s name.',
      },
      {
        question: 'Who should I include on my auto insurance policy?',
        answer:
          "In addition to yourself, you should include anyone in your household that's 15 years of age or older (even if temporarily away from home), any guests staying in your home in excess of 90 days, and anyone who regularly drives a vehicle on your policy more than 30 days per year.",
      },
      {
        question:
          'How many drivers and vehicles can I include on my auto insurance policy?',
        answer: 'You can include up to 8 drivers and 8 vehicles.',
      },
      {
        question:
          'What traffic citations and/or moving violations should I include on my auto insurance quote?',
        answer:
          'All violations occurring in the past 3 years except parking tickets should be disclosed during the quote process.',
      },
      {
        question: 'What does “driver exclusion” mean?',
        answer:
          'An excluded driver will not be covered when driving any vehicles covered on the policy. If you exclude any drivers, you will be required to electronically sign a named driver exclusion form. Please read this form carefully to understand how your coverage will be affected. Refer to your Declarations Page for the driver information specific to your policy.',
      },
      {
        question: 'Do you use my prior insurance information?',
        answer:
          'We review your history of insurance coverage in order to determine whether you qualify for coverage, establish your premium, or to determine whether you are eligible for applicable discounts.',
      },
      {
        question:
          'Do you offer policies to drivers who require Financial Responsibility Filings or SR-22s?',
        answer:
          'At this time, we are not able to file SR-22s or any other kind of Financial Responsibility Filing for drivers in any state. If that is needed, we will direct you to an affiliated company in the Bristol West Insurance Group.',
      },
      {
        question: 'How much auto insurance do I need?',
        answer:
          'Most states require you to carry a minimum amount of liability insurance. To determine how much auto insurance you need, you should consider the value of your assets, how much you drive, when and where you drive, and what kind of vehicle you drive.',
      },
      {
        question: 'How far in advance can I purchase a policy?',
        answer:
          'You can purchase your policy up to 30 days in advance of your desired coverage start date.',
      },
      {
        question: 'Can you help me cancel my previous car insurance policy?',
        answer: (
          <p>
            We can help you cancel your previous auto insurance policy - just{' '}
            <a href={PATHNAMES.CONTACT_US}>Contact Us</a> and we will submit the
            cancellation request to your prior insurance company on your behalf.
            Once they receive it, they will process any refund that may be owed
            to you.
          </p>
        ),
      },
      {
        question:
          'Do I get assigned to a Farmers Agent when I sign up for a policy?',
        answer:
          'Since this is a digital experience, you will not have one specific agent assigned to your policy but our licensed agents are available to assist you via chat Monday - Friday from 9am to 12am EST and on Saturday from 10am to 9pm EST and via email after hours.',
      },
    ],
  },
  {
    label: 'Account Management',
    questions: [
      {
        question: 'I forgot my username and password. How do I log in?',
        answer: (
          <p>
            Please visit our{' '}
            <Link to="/login?forgotPassword=true">Forgot Password</Link> link to
            reset or recover your password.{' '}
            <a href={PATHNAMES.CONTACT_US}>Contact Us</a> via chat or email if
            you need further assistance.
          </p>
        ),
      },
      {
        question: 'How can I contact you? ',
        answer:
          'We are available to assist you via chat Monday - Friday from 9am to 12am EST and on Saturday from 10am to 9pm EST and via email after hours. If you would like to speak to a licensed agent, please contact us and we will be happy to give you a call at your convenience.',
      },
      {
        question:
          'Where can I find my ID card, proof of insurance, and other policy documents?',
        answer:
          'You can log in to your account anytime and view, save, or print a copy of your policy documents, including ID card and proof of insurance (declarations page).',
      },
      {
        question: 'How do I update my coverage or personal information?',
        answer:
          'Just log onto your account and start updating your coverage and policy information at any time.',
      },
      {
        question: 'How do I cancel my policy?',
        answer:
          'You can cancel your policy online at any time by logging in to your account. If you need assistance, our licensed agents are available via chat and email.',
      },
    ],
  },
  {
    label: 'Policy and Coverage',
    questions: [
      {
        question: 'Who is covered under my policy?',
        answer:
          'Your policy covers anyone listed on your Declarations Page as a primary driver and any additional drivers who have your permission to occasionally operate your insured vehicle(s). Please note that every claim is subject to a full review process in which we will determine whether you and/or other drivers are eligible for coverage.',
      },
      {
        question: `Am I covered if I drive someone else's vehicle?`,
        answer: `Generally speaking, the insurance goes with the car. For example, if you have an accident while driving someone else's car, the owner's insurance would apply toward damages first. Your auto insurance would generally apply in the event the owner of the vehicle had no auto insurance or did not have enough auto insurance to pay the damages. Please note that every claim is subject to a full review process in which we will determine whether you and/or other drivers are eligible for coverage.`,
      },
      {
        question: 'If I rent a car, will my FarmersDirect policy cover me?',
        answer:
          'Generally speaking, your policy will provide insurance coverage if you get into an accident in a car that you have rented for a period of less than 30 days.  Please note that every claim is subject to a full review process in which we will determine whether you and/or other drivers are eligible for coverage.  You can also buy liability insurance and collision damage waivers ("CDWs") directly from rental car companies when you rent your vehicle.',
      },
      {
        question:
          'If I drive for a ridesharing company (e.g. Uber or Lyft), will my policy cover me?',
        answer:
          'In order to purchase Rideshare Coverage, you must indicate if your vehicle is used for rideshare driving. If so, we will automatically add the endorsement to your policy. The Rideshare Coverage endorsement provides for an extension of your insurance coverage when you are logged into a ride sharing app (like Uber or Lyft) and are awaiting a match. The endorsement does not cover you while you are en route to pick up a passenger or while you have a passenger in the car.',
      },
      {
        question: 'Will my policy be canceled if I have an accident?',
        answer:
          'Generally, being in an accident will not cause your policy to be canceled. However, every claim is subject to a full review process and after the facts of the situation are reviewed, you may be required to pay a higher premium for your policy upon renewal.',
      },
      {
        question: 'What is uninsured/underinsured motorist coverage?',
        answer:
          'Applicable in certain states, UM (Uninsured Motorist) or UIM (Underinsured Motorist) pays the amount you are legally entitled to recover for damages from bodily injury and/or physical damage to your vehicle, up to the selected limits, caused by an uninsured (a person who does not have insurance) or underinsured motorist (a person who does not have enough insurance to compensate you for your damages).',
      },
      {
        question:
          'What’s the difference between comprehensive and collision coverage?',
        answer:
          'Also referred to as "physical damage coverage," comprehensive and collision coverage help repair your car after a covered loss. Comprehensive or "comp" coverage generally reimburses you when your car is damaged from theft, vandalism, fire or hitting an animal…or if your vehicle is damaged from weather-related events like hail. Collision coverage provides coverage when damage occurs to your car while moving, parked or struck by another vehicle.',
      },
      {
        question: 'How does bodily injury coverage work?',
        answer:
          'This coverage protects you (up to your policy limits) from liability claims against you for bodily injury to others as the result of an auto accident.',
      },
      {
        question: 'What is medical coverage?',
        answer:
          'Medical expense coverage pays reasonable medical and funeral expenses incurred by you or your passengers, up to the limit selected and regardless of fault. This is a mandatory coverage in some states and the limits chosen are per policy (not per vehicle).',
      },
    ],
  },
  {
    label: 'Pricing and Payment',
    questions: [
      {
        question: 'What kinds of discounts are available for auto insurance?',
        answer: `There is a wide range of auto insurance discounts that you may qualify for, depending on your situation and the state you live in. They include but aren't limited to whether you are a homeowner, a good student, are accident/claims free, or own a car with Automated Emergency Braking System.`,
      },
      {
        question: 'How do I pay for my policy?',
        answer:
          'Using the credit card or debit card that you provide, we’ll charge the first premium payment at the time of purchase and then automatically deduct your monthly premium at the start of your billing period each month (which coincides with your coverage start date). If you ever need to change the card on file, just log into your account at any time and go to Billing under View Policy.',
      },
      {
        question: 'Can I pay for a full six months now? ',
        answer:
          'Not right now, we hope to have that option available soon. Currently, you can pay monthly.',
      },
      {
        question: 'How do I view my payment history?',
        answer: (
          <p>
            To view your payment history, log into your online account and click{' '}
            <Link to="/my-account">View Policy</Link>. Under Billing, you can
            see your payment history by clicking{' '}
            <Link to="my-account">View Activity</Link>.
          </p>
        ),
      },
      {
        question:
          'What happens to my payment schedule if I change my coverage?',
        answer:
          'If you make a coverage change that increases your monthly cost, you’ll be charged instantly. If your change results in a lower monthly cost, we’ll refund the applicable amount to your debit or credit card, typically within 5 business days. In rare cases, your bank or credit card issuer may be unable to process your refund and will notify us. If that’s the case, we’ll issue you a check with your refund. Moving forward, the change amount will be incorporated into your regular monthly payment.',
      },
    ],
  },
  {
    label: 'Claims',
    questions: [
      {
        question: 'How do I report a loss?',
        answer: (
          <p>
            You can report a claim directly on our website - just log into your
            account and click <Link to="/claims">Claims</Link> - or by calling{' '}
            <a href="tel:800-435-7764">1-800-435-7764</a>.
          </p>
        ),
      },
      {
        question: 'How long will it take to handle my claim?',
        answer: `It depends on how complex the claim is, how serious the damages or injuries are, and how willing other involved parties are to cooperate. Farmers claims representatives always work toward prompt resolution of every claim. If a claim requires follow-up work, we'll be there with you every step of the way. And, if your claim has been concluded and additional damage or injuries are identified, we'll reopen the claim and determine if your policy provides coverage for the additional expense.`,
      },
      {
        question: 'How do I know if my claim will be covered?',
        answer: `A good place to start is by reviewing your policy. Our licensed agents are also available via chat Monday - Friday from 9am to 12am EST and on Saturday from 10am to 9pm EST and via email after hours to help you understand what coverage you've purchased and how it applies to your particular claim.`,
      },
      {
        question: 'How do I track and manage my existing claim?',
        answer: (
          <p>
            To view your claims status, log into your online account and click{' '}
            <Link to="/claims">Claims</Link> or call{' '}
            <a href="tel:800-435-7764">1-800-435-7764</a>.
          </p>
        ),
      },
      {
        question: `My car was hit by another party, should I file a claim with Farmers or pursue damages through the other party's carrier?`,
        answer: `You may have a hard time recovering from the at-fault party; they may believe that they're not at fault – or they may not have auto insurance. So, by filing a claim with Farmers, we may be able to help you recover your costs – including your deductible – from the insurance company providing coverage to the at-fault party.`,
      },
      {
        question: 'Should I file a police report for a loss or theft?',
        answer:
          'We recommend filing a police report immediately any time you experience an auto accident, property theft, or vandalism to your property.',
      },
      {
        question: 'How do I request Emergency Roadside Assistance?',
        answer: (
          <p>
            Please visit the{' '}
            <Link to="/claims/new-claim">Roadside Assistance link</Link> on our
            website to get help. Please note that Towing and Road Service is an
            optional coverage. If you have questions about the coverage on your
            policy, our licensed agents are available via chat Monday - Friday
            from 9am to 12am EST and on Saturday from 10am to 9pm EST and via
            email after hours.
          </p>
        ),
      },
    ],
  },
];
