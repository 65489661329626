import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import Footer from '../Footer';
import Divider from '@material-ui/core/Divider';
import { useGlobalState } from '../../store';
import {
  DRAWER_LINKS,
  DRAWER_ACCOUNT_ACTIONS,
  TOP_BAR_LINKS,
  TOP_BAR_ACCOUNT_ACTIONS,
} from '../../constants/navigationItems';
import startsWith from 'lodash/startsWith';
import get from 'lodash/get';

import wlConfig from '../../constants/wlConfig';

const {
  featureFlags: { isUseLinkApiReturnUrl },
  footerConfig: { hasBuiltOnSure },
  layoutRoot: { alwaysShowsAccountActions },
  logos: { logoPrimary, logoAlternate, footerLogo },
  theme: {
    styles: {
      layoutRootStyles: {
        hasCenteredTopBarLogo,
        topBar: {
          styles: {
            root: topBar,
            logo,
            drawerCloseIcon,
            drawerIcon,
            drawerHeader,
            drawerLink,
            drawerLink_disabled,
            drawerPaper,
            topBar_alternateBackground,
            topBar_drawerIcon_alternate,
          },
          desktopStyles: {
            root: topBarDesktop,
            logo: logoDesktop,
            topBar_link: topBar_linkDesktop,
            topBar_link_alternate: topBar_linkDesktop_alternate,
            topBarGroup_links: topBarGroup_linksDesktop,
          },
        },
      },
    },
  },
  redirectUrls: { logoRedirectUrl },
} = wlConfig;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      height: '100vh',
    },
  },
  content: {
    flex: '1 1 auto',
    [theme.breakpoints.only('xs')]: {
      minHeight: '100vh',
    },
  },
  topBar: {
    alignItems: 'center',
    boxShadow:
      '0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14);',
    display: 'flex',
    flexShrink: 0,
    width: '100%',
    minHeight: 56,
    zIndex: 1,
    justifyContent: 'space-between',
    padding: '0 16px',
    background: theme.palette.tenant.primary,
    ...topBar,
    [theme.breakpoints.up('lg')]: {
      padding: '0 148px',
      minHeight: 85,
      '&$topBar_centeredLogo': {
        justifyContent: 'center',
      },
      ...topBarDesktop,
    },
  },
  topBar_centeredLogo: {},
  topBar_alternateBackground,
  topBar_drawerIcon: {
    color: theme.palette.tenant.white,
    flexShrink: 0,
    ...drawerIcon,
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  topBar_drawerIcon_alternate,
  topBar_logo: {
    cursor: 'pointer',
    height: 40,
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    flexShrink: 0,
    ...logo,
    [theme.breakpoints.up('lg')]: {
      position: 'relative',
      left: 'auto',
      transform: 'none',
      height: 50,
      margin: 0,
      ...logoDesktop,
    },
  },
  topBar_group: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'flex-end',
  },
  topBar_group_links: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      ...topBarGroup_linksDesktop,
    },
  },
  topBar_group_actions: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    '& $topBar_link:last-of-type': {
      marginRight: 0,
    },
  },
  topBar_link: {
    color: theme.palette.tenant.white,
    marginRight: 8,
    [theme.breakpoints.up('lg')]: {
      color: theme.palette.tenant.primary,
      marginRight: 16,
      ...topBar_linkDesktop,
    },
  },
  topBar_linkDesktop_alternate,
  topBar_link_contained: {
    color: theme.palette.tenant.white,
    paddingRight: 16,
    paddingLeft: 16,
  },
  drawer_paper: {
    padding: 0,
    width: 250,
    borderRight: 'none',
    ...drawerPaper,
  },
  drawer_header: {
    backgroundColor: theme.palette.tenant.primary,
    height: 56,
    ...drawerHeader,
  },
  drawer_closeIcon: {
    color: theme.palette.tenant.white,
    position: 'absolute',
    right: 0,
    ...drawerCloseIcon,
  },
  drawer_group: {
    padding: 8,
  },
  drawer_link: {
    display: 'block',
    marginTop: 20,
    marginLeft: 16,
    minWidth: 'auto',
    color: theme.palette.tenant.primaryMid,
    ...drawerLink,
    '&:disabled': {
      color: theme.palette.tenant.accent,
      ...drawerLink_disabled,
    },
  },
  logout_divider: {
    marginTop: 20,
    marginLeft: 24,
    marginRight: 24,
    [theme.breakpoints.up('md')]: {
      marginLeft: 16,
      marginRight: 16,
    },
  },
}));

function LayoutRoot({
  children,
  history,
  location,
  withFooter,
  withRightsReservedFooter,
  withTopNavigation,
  withAlternateBackground,
}) {
  const classes = useStyles();
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const topBarRootClass = classnames(classes.topBar, {
    [classes.topBar_alternateBackground]: withAlternateBackground,
    [classes.topBar_centeredLogo]:
      isDesktop && !withTopNavigation && hasCenteredTopBarLogo,
  });
  const drawerIconClass = classnames(classes.topBar_drawerIcon, {
    [classes.topBar_drawerIcon_alternate]: withAlternateBackground,
  });
  const topBarLinkClass = (buttonVariant) => {
    return classnames(classes.topBar_link, {
      [classes.topBar_link_contained]: buttonVariant === 'contained',
      [classes.topBar_linkDesktop_alternate]: withAlternateBackground,
    });
  };

  const { isLoggedIn, quote } = useGlobalState();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const topBarLinks = TOP_BAR_LINKS.filter(
    ({ requiresLogin, requiresAnonymous }) =>
      isDesktop &&
      ((isLoggedIn && !requiresAnonymous) || (!isLoggedIn && !requiresLogin))
  );

  const topBarActions = TOP_BAR_ACCOUNT_ACTIONS.filter(
    ({ requiresLogin, requiresAnonymous }) =>
      (isDesktop || alwaysShowsAccountActions) &&
      ((isLoggedIn && !requiresAnonymous) || (!isLoggedIn && !requiresLogin))
  );

  function handleLogoRedirect() {
    const returnUrl = get(quote, 'application.metadata.return_redirect_url');
    if (isUseLinkApiReturnUrl && !!returnUrl) {
      window.location.href = returnUrl;
    } else if (logoRedirectUrl) {
      if (startsWith(logoRedirectUrl, 'http')) {
        window.location.href = logoRedirectUrl;
      } else {
        history.push(logoRedirectUrl);
      }
    }
  }

  return (
    <>
      <Box className={classes.root} data-testid="layoutroot">
        <Box
          className={topBarRootClass}
          id="layoutroot_topbar"
          data-testid="layoutroot_topbar"
        >
          {!isDesktop && withTopNavigation && (
            <IconButton
              aria-label="open drawer"
              onClick={() => setIsDrawerOpen(true)}
              className={drawerIconClass}
              data-testid="open_nav_drawer_button"
            >
              <MenuIcon className={drawerIconClass} />
            </IconButton>
          )}
          {withAlternateBackground && logoAlternate ? (
            <img
              src={logoAlternate}
              alt="desktop_logo"
              className={classes.topBar_logo}
              data-testid="LayoutRoot_logo_desktop"
              onClick={handleLogoRedirect}
            />
          ) : (
            <img
              src={logoPrimary}
              alt="mobile_tablet_logo"
              className={classes.topBar_logo}
              data-testid="LayoutRoot_logo_mobile_tablet"
              onClick={handleLogoRedirect}
            />
          )}

          {withTopNavigation &&
            (!!topBarLinks.length || !!topBarActions.length) && (
              <Box className={classes.topBar_group}>
                <Box className={classes.topBar_group_links}>
                  {topBarLinks.map(
                    ({
                      name,
                      testid,
                      href,
                      buttonVariant,
                      isDifferentDomain = false,
                    }) => {
                      const buttonLinkProps = isDifferentDomain
                        ? { href }
                        : { onClick: () => history.push(href) };
                      return (
                        <Button
                          key={testid}
                          color="primary"
                          size="small"
                          variant={buttonVariant || 'text'}
                          className={topBarLinkClass(buttonVariant)}
                          data-testid={`topbarLink_${testid}`}
                          {...buttonLinkProps}
                        >
                          {name}
                        </Button>
                      );
                    }
                  )}
                </Box>
                <Box className={classes.topBar_group_actions}>
                  {topBarActions.map(
                    ({ name, testid, href, buttonVariant }) => (
                      <Button
                        key={testid}
                        color="primary"
                        size="small"
                        variant={buttonVariant || 'text'}
                        className={topBarLinkClass(buttonVariant)}
                        data-testid={`topbarLink_${testid}`}
                        onClick={() => history.push(href)}
                      >
                        {name}
                      </Button>
                    )
                  )}
                </Box>
              </Box>
            )}
        </Box>
        <Box className={classes.content}>{children}</Box>
        {(withFooter || withRightsReservedFooter) && (
          <Footer
            withRightsReserved={withRightsReservedFooter}
            hasBuiltOnSure={hasBuiltOnSure}
            footerLogo={footerLogo}
          />
        )}
      </Box>
      {!isDesktop && withTopNavigation && (
        <Drawer
          variant="temporary"
          anchor="left"
          open={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          classes={{
            paper: classes.drawer_paper,
          }}
          PaperProps={{
            'data-testid': 'nav_drawer_content',
          }}
        >
          <Box className={classes.drawer_header}>
            <IconButton
              onClick={() => setIsDrawerOpen(false)}
              className={classes.drawer_closeIcon}
              data-testid="nav_drawer_closeButton"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box className={classes.drawer_group}>
            {[...DRAWER_LINKS, ...DRAWER_ACCOUNT_ACTIONS].map(
              ({
                name,
                testid,
                href,
                requiresLogin,
                requiresAnonymous,
                hasDivider = false,
                isDifferentDomain = false,
              }) => {
                if (
                  (isLoggedIn && !requiresAnonymous) ||
                  (!isLoggedIn && !requiresLogin)
                ) {
                  const buttonLinkProps = isDifferentDomain
                    ? { href }
                    : { onClick: () => history.push(href) };
                  return (
                    <Box key={`navLink${testid}`}>
                      {hasDivider && (
                        <Divider className={classes.logout_divider} />
                      )}
                      <Button
                        data-testid={`nav_drawer_link_${testid}`}
                        color="primary"
                        size="small"
                        className={classes.drawer_link}
                        disabled={href === location.pathname}
                        {...buttonLinkProps}
                      >
                        {name}
                      </Button>
                    </Box>
                  );
                } else return null;
              }
            )}
          </Box>
        </Drawer>
      )}
    </>
  );
}

export default withRouter(LayoutRoot);
