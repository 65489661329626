import React from 'react';

const bristolWestIcon = require('../../assets/farmers-direct/img/icon-phone.svg');

export default {
  bristolWest: {
    dataTestId: 'bristolWestError',
    iconSrc: bristolWestIcon,
    body: (
      <>
        <div style={{ fontSize: 24, fontWeight: 'normal' }}>
          We are unable to provide a quote for you, however it appears you may
          qualify for Bristol West insurance.
          <br />
          <br />
          Please call{' '}
          <a style={{ color: 'inherit' }} href="tel:1-800-206-9469">
            1-800-206-9469
          </a>{' '}
          to get a quote.
        </div>
        <br />
        <div style={{ fontSize: 16, fontWeight: 500 }}>
          Bristol West is a Farmers company that often writes auto insurance
          policies when Toggle cannot. Examples may include a lapse in coverage,
          no prior insurance, a history of losses, or a salvage title.
        </div>
      </>
    ),
  },
};
