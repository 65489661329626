import React, { useContext, createContext, useState, useEffect } from 'react';
import ModalDialog from '../components/ModalDialog';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';

const NavBlockerContext = createContext();

export function useNavBlocker(condition, onConfirm) {
  const { setIsDialogOpen, setOnConfirm, history } = useContext(
    NavBlockerContext
  );

  useEffect(
    function openDialogOnCondition() {
      const unblock = history.block(({ pathname }, action) => {
        if (condition(pathname, action)) {
          setIsDialogOpen(true);
          setOnConfirm(() => {
            return () => {
              onConfirm(pathname);
              setIsDialogOpen(false);
            };
          });
          return false;
        }
      });
      return () => {
        unblock();
      };
    },
    [history, setIsDialogOpen, condition, setOnConfirm, onConfirm]
  );
}

export const NavBlockerContextProvider = withRouter(
  ({ children, location, history }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [onConfirm, setOnConfirm] = useState(null);

    return (
      <NavBlockerContext.Provider
        value={{
          setIsDialogOpen,
          setOnConfirm,
          history,
        }}
      >
        {children}
        <ModalDialog
          open={isDialogOpen}
          onDismiss={() => {
            if (location.pathname !== window.location.pathname) {
              history.goForward();
            }
            setIsDialogOpen(false);
          }}
          modalTitle="Are you sure you want to leave this page?"
          confirmLabel="Leave"
          onConfirm={onConfirm}
          isAlert
        >
          <Typography variant="subtitle1">
            Your progress will be lost and you will need to start over.
          </Typography>
        </ModalDialog>
      </NavBlockerContext.Provider>
    );
  }
);
