import ACTION_TYPES from '../constants/actionTypes';
import head from 'lodash/head';
import omit from 'lodash/omit';
import localforage from 'localforage';
import { setSessionStorage } from '../services/sessionStorageUtils';
import moment from 'moment-timezone';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';

export const persistedStoreKey = 'persistedStore';
export const persistWhitelist = [
  'authToken',
  'oktaAccessToken',
  'oktaIdToken',
  'oktaLoginInfo',
  'quote',
  'quoteCheckout',
  'policies',
  'policyCheckout',
  'user',
  'isCCPABannerOpen',
];
export const sessionStorageWhitelist = [
  'enrollmentApplication',
  'quoteVars',
  'policyVars',
  'quoteExtraData',
  'quoteHealthCheckCachedDrivers',
  'quoteHealthCheckCachedVehicles',
  'quoteHealthCheckCachedOrders',
];

const reducers = {
  authToken(state = null, action) {
    switch (action.type) {
      case ACTION_TYPES.EXCHANGE_QUOTE_AUTH_TOKEN_SUCCESS:
        return action.payload.token;
      case ACTION_TYPES.EXCHANGE_QUOTE_AUTH_TOKEN_FAILURE:
      case ACTION_TYPES.SAVE_OKTA_ACCESS_TOKEN:
      case ACTION_TYPES.CLEAR_OKTA_LOGIN_INFO:
        return null;
      default:
        return state;
    }
  },
  oktaIdToken(state = null, action) {
    switch (action.type) {
      case ACTION_TYPES.SAVE_OKTA_ID_TOKEN:
        return action.payload;
      case ACTION_TYPES.REFRESH_OKTA_TOKENS_SUCCESS:
        return action.payload.idToken;
      case ACTION_TYPES.LOGOUT:
        return null;
      default:
        return state;
    }
  },

  oktaAccessToken(state = null, action) {
    switch (action.type) {
      case ACTION_TYPES.SAVE_OKTA_ACCESS_TOKEN:
        return action.payload;
      case ACTION_TYPES.REFRESH_OKTA_TOKENS_SUCCESS:
        return action.payload.accessToken;
      case ACTION_TYPES.LOGOUT:
        return null;
      default:
        return state;
    }
  },

  oktaLoginInfo(state = null, action) {
    switch (action.type) {
      case ACTION_TYPES.SAVE_OKTA_LOGIN_INFO:
        return action.payload;
      case ACTION_TYPES.SAVE_OKTA_ID_TOKEN:
      case ACTION_TYPES.SAVE_OKTA_ACCESS_TOKEN:
      case ACTION_TYPES.REFRESH_OKTA_TOKENS_SUCCESS:
      case ACTION_TYPES.CLEAR_OKTA_LOGIN_INFO:
      case ACTION_TYPES.LOGOUT:
        return null;
      default:
        return state;
    }
  },

  enrollmentApplication(state = null, action) {
    switch (action.type) {
      case ACTION_TYPES.UPDATE_ENROLLMENT_APPLICATION:
        return action.payload;
      case ACTION_TYPES.GET_QUOTE_SUCCESS:
        const application = get(action.payload, 'application', {});
        return {
          firstName: get(application.client, 'first_name'),
          lastName: get(application.client, 'last_name'),
          dob: get(application.client, 'dob'),
          address: application.rating_address,
          selfReportedIncidentCount: application.self_reported_incident_count,
          coverageEffectiveDate: application.policy_effective_date,
        };
      case ACTION_TYPES.CLEAR_ENROLLMENT_APPLICATION:
      case ACTION_TYPES.LOGOUT:
        return null;
      default:
        return state;
    }
  },

  quote(state = null, action) {
    switch (action.type) {
      case ACTION_TYPES.CREATE_QUOTE_SUCCESS:
      case ACTION_TYPES.GET_QUOTE_SUCCESS:
      case ACTION_TYPES.UPDATE_QUOTE_SUCCESS:
        return action.payload;
      case ACTION_TYPES.LOGOUT:
      case ACTION_TYPES.CLEAR_QUOTE:
        return null;
      default:
        return state;
    }
  },

  quoteVars(state = null, action) {
    switch (action.type) {
      case ACTION_TYPES.GET_QUOTE_VARS_SUCCESS:
        return action.payload.variables;
      case ACTION_TYPES.LOGOUT:
      case ACTION_TYPES.CLEAR_QUOTE:
        return null;
      default:
        return state;
    }
  },

  quoteExtraData(state = null, action) {
    switch (action.type) {
      case ACTION_TYPES.GET_QUOTE_VARS_SUCCESS:
        return action.payload.extra_data;
      case ACTION_TYPES.LOGOUT:
      case ACTION_TYPES.CLEAR_QUOTE:
        return null;
      default:
        return state;
    }
  },

  quoteCheckout(state = null, action) {
    switch (action.type) {
      case ACTION_TYPES.GET_QUOTE_CHECKOUT_SUCCESS:
        return action.payload;
      case ACTION_TYPES.LOGOUT:
      case ACTION_TYPES.CLEAR_QUOTE:
        return null;
      default:
        return state;
    }
  },

  quoteHealthCheckCachedDrivers(state = null, action) {
    switch (action.type) {
      case ACTION_TYPES.UPDATE_CACHED_QUOTE_HEALTH_CHECK_STATE:
        if (get(action, 'payload.drivers')) {
          return action.payload.drivers;
        } else {
          return state;
        }
      case ACTION_TYPES.CREATE_QUOTE:
      case ACTION_TYPES.CLEAR_QUOTE:
      case ACTION_TYPES.FINALIZE_QUOTE:
      case ACTION_TYPES.GET_POLICIES:
      case ACTION_TYPES.LOGOUT:
        return null;
      default:
        return state;
    }
  },

  quoteHealthCheckCachedVehicles(state = null, action) {
    switch (action.type) {
      case ACTION_TYPES.UPDATE_CACHED_QUOTE_HEALTH_CHECK_STATE:
        if (get(action, 'payload.vehicles')) {
          return action.payload.vehicles;
        } else {
          return state;
        }
      case ACTION_TYPES.CREATE_QUOTE:
      case ACTION_TYPES.CLEAR_QUOTE:
      case ACTION_TYPES.FINALIZE_QUOTE:
      case ACTION_TYPES.GET_POLICIES:
      case ACTION_TYPES.LOGOUT:
        return null;
      default:
        return state;
    }
  },

  quoteHealthCheckCachedOrders(state = null, action) {
    switch (action.type) {
      case ACTION_TYPES.UPDATE_CACHED_QUOTE_HEALTH_CHECK_STATE:
        if (get(action, 'payload.orders')) {
          return action.payload.orders;
        } else {
          return state;
        }
      case ACTION_TYPES.CREATE_QUOTE:
      case ACTION_TYPES.CLEAR_QUOTE:
      case ACTION_TYPES.FINALIZE_QUOTE:
      case ACTION_TYPES.GET_POLICIES:
      case ACTION_TYPES.LOGOUT:
        return null;
      default:
        return state;
    }
  },

  policies(state = null, action) {
    switch (action.type) {
      case ACTION_TYPES.GET_POLICIES_SUCCESS:
        return action.payload.policies;
      case ACTION_TYPES.GET_POLICY_SUCCESS:
      case ACTION_TYPES.UPDATE_POLICY_SUCCESS:
      case ACTION_TYPES.CANCEL_POLICY_SUCCESS:
        return sortBy(
          uniqBy([action.payload.policy, ...state], 'id'),
          (policy) => -moment(policy.ends_at).valueOf()
        );
      case ACTION_TYPES.LOGOUT:
      case ACTION_TYPES.CREATE_QUOTE_SUCCESS:
        return null;
      default:
        return state;
    }
  },

  policyVars(state = null, action) {
    switch (action.type) {
      case ACTION_TYPES.GET_POLICY_VARS_SUCCESS:
        return action.payload.variables;
      case ACTION_TYPES.LOGOUT:
      case ACTION_TYPES.CREATE_QUOTE_SUCCESS:
        return null;
      default:
        return state;
    }
  },

  policyExtraData(state = null, action) {
    switch (action.type) {
      case ACTION_TYPES.GET_POLICY_VARS_SUCCESS:
        return action.payload.extra_data;
      case ACTION_TYPES.LOGOUT:
      case ACTION_TYPES.CREATE_QUOTE_SUCCESS:
        return null;
      default:
        return state;
    }
  },

  policyCheckout(state = null, action) {
    switch (action.type) {
      case ACTION_TYPES.GET_POLICY_CHECKOUT_SUCCESS:
        return action.payload;
      case ACTION_TYPES.LOGOUT:
      case ACTION_TYPES.CREATE_QUOTE_SUCCESS:
        return null;
      default:
        return state;
    }
  },

  isCCPABannerOpen(state = true, action) {
    switch (action.type) {
      case ACTION_TYPES.CLOSE_CCPA_BANNER:
        return action.payload;
      default:
        return state;
    }
  },

  user(state = null, action) {
    switch (action.type) {
      case ACTION_TYPES.GET_USER_SUCCESS:
        return action.payload;
      case ACTION_TYPES.LOGOUT:
        return null;
      default:
        return state;
    }
  },

  claims(state = null, action) {
    switch (action.type) {
      case ACTION_TYPES.GET_CLAIMS_SUCCESS:
        return action.payload;
      case ACTION_TYPES.LOGOUT:
        return null;
      default:
        return state;
    }
  },

  featureFlags(state = null, action) {
    switch (action.type) {
      case ACTION_TYPES.GET_FEATURE_FLAGS_SUCCESS:
        return action.payload;
      case ACTION_TYPES.GET_FEATURE_FLAGS_FAILURE:
        return null;
      default:
        return state;
    }
  },
};

function combineReducers(reducers) {
  return function getStateForReducers(state = {}, action) {
    const reducerKeys = Object.keys(reducers);
    if (!reducerKeys.length) {
      const persistedStore = Object.assign(
        {},
        ...persistWhitelist.map((key) => ({ [key]: state[key] }))
      );
      localforage.setItem(persistedStoreKey, JSON.stringify(persistedStore));
      const persistedSessionStore = Object.assign(
        {},
        ...sessionStorageWhitelist.map((key) => ({ [key]: state[key] }))
      );
      setSessionStorage(
        persistedStoreKey,
        JSON.stringify(persistedSessionStore)
      );
      return state;
    } else {
      const key = head(reducerKeys);
      const newState = reducers[key](state[key], action);
      return combineReducers(omit(reducers, [key]))(
        { ...state, [key]: newState },
        action
      );
    }
  };
}

export default combineReducers(reducers);
