import localforage from 'localforage';
import { setSDKAuthHeader, deleteSDKAuthHeader } from 'sure-platform-sdk';
import axios from 'axios';

import sureAxios from '../services/sureAxios';
import destructureAxiosReq from '../services/destructureAxiosReq';

localforage.config({
  name: 'farmers_direct_auto',
  storeName: 'farmers_direct_auto',
});

export default {
  setAuthHeader(accessToken) {
    return new Promise((resolve) => {
      sureAxios.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
      setSDKAuthHeader(accessToken);
      resolve();
    });
  },

  deleteAuthHeader() {
    delete sureAxios.defaults.headers['Authorization'];
    deleteSDKAuthHeader();
  },

  createQuote(enrollmentApplication) {
    return destructureAxiosReq(
      sureAxios.post(`/api/quotes/v1/quotes`, enrollmentApplication)
    );
  },

  getQuote(quote_number) {
    return destructureAxiosReq(
      sureAxios.get(`/api/quotes/v1/quotes/${quote_number}`)
    );
  },

  updateQuote(quote_number, quote) {
    return destructureAxiosReq(
      sureAxios.put(`/api/quotes/v1/quotes/${quote_number}`, quote)
    );
  },

  getQuoteVars(quote_number) {
    return destructureAxiosReq(
      sureAxios.get(`/api/quotes/v1/quotes/${quote_number}/variables`)
    );
  },

  getQuoteCheckout(quoteNumber, application) {
    if (application) {
      return destructureAxiosReq(
        sureAxios.put(`/api/quotes/v1/quotes/${quoteNumber}/checkout`, {
          application,
        })
      );
    } else {
      return destructureAxiosReq(
        sureAxios.get(`/api/quotes/v1/quotes/${quoteNumber}/checkout`)
      );
    }
  },

  finalizeQuote(quote_number, opts) {
    return destructureAxiosReq(
      sureAxios.post(`/api/quotes/v1/quotes/${quote_number}/finalize`, opts)
    );
  },

  getPolicies() {
    return destructureAxiosReq(
      sureAxios.get(`/api/management/v1/policies`, {}, {})
    );
  },

  getPolicy(policyId) {
    return destructureAxiosReq(
      sureAxios.get(`/api/management/v1/policies/${policyId}`)
    );
  },

  updatePolicy(policy) {
    return destructureAxiosReq(
      sureAxios.put(`/api/management/v1/policies/${policy.id}`, policy)
    );
  },

  getPolicyVars(policyId) {
    return destructureAxiosReq(
      sureAxios.get(`/api/management/v1/policies/${policyId}/variables`)
    );
  },

  getPolicyCheckout(policy) {
    return destructureAxiosReq(
      sureAxios.post(
        `/api/management/v1/policies/${policy.id}/checkout`,
        policy
      )
    );
  },

  cancelPolicy(policy, reason = '') {
    return destructureAxiosReq(
      sureAxios.delete(`/api/management/v1/policies/${policy.id}`, {
        data: {
          reason,
        },
      })
    );
  },

  getUser() {
    return destructureAxiosReq(sureAxios.get(`/api/user/v1/profile`));
  },

  getClaims(policyId) {
    return destructureAxiosReq(
      sureAxios.get(`/api/management/v1/policies/${policyId}/claims`)
    );
  },

  getLinkUrl(quote) {
    sureAxios.defaults.headers[
      'Authorization'
    ] = `Bearer ${process.env.REACT_APP_CARRIER_SECRET_TOKEN}`;
    return destructureAxiosReq(sureAxios.post('/api/auth/v1/links', quote));
  },

  exchangeQuoteAuthToken(quoteNumber, token) {
    return destructureAxiosReq(
      sureAxios.post(process.env.REACT_APP_EXCHANGE_TOKEN_ENDPOINT, {
        quote_number: quoteNumber,
        token,
      })
    );
  },

  createPaymentMethod({
    token: source_token,
    email,
    name,
    gatewayReferenceId: gateway_reference_id,
    paymentServiceId,
    clientId,
  }) {
    const payload = {
      source_token,
      email,
      name,
      gateway_reference_id,
      payment_service_id: gateway_reference_id ? paymentServiceId : undefined,
      vertical_code: 'farmers_direct_auto',
      client: {
        id: clientId,
      },
    };

    return destructureAxiosReq(
      sureAxios.post('/api/management/v1/payment_methods', payload)
    );
  },

  exchangeVgsToken({
    type,
    cardNumberToken,
    expMonth,
    expYear,
    cvcToken,
    carrierCode,
    paymentApiAuthToken,
    email,
    billingAddress,
  }) {
    const payload = {
      payment_method: {
        type,
        number: cardNumberToken,
        exp_month: expMonth,
        exp_year: expYear,
        cvc: cvcToken,
        billing_address: billingAddress,
      },
      carrier: {
        code: carrierCode,
      },
      email,
    };

    return destructureAxiosReq(
      axios.post(
        `${process.env.REACT_APP_PAYMENT_SERVICE_BASE_URL}/api/payment-methods/`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${paymentApiAuthToken}`,
          },
        }
      )
    );
  },

  getFeatureFlags() {
    return destructureAxiosReq(sureAxios.get('/api/feature_flags/v1'));
  },
};
