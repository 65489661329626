import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';
import snakeCase from 'lodash/snakeCase';
import Button from '../Button';
import Fade from '@material-ui/core/Fade';
import wlConfig from '../../constants/wlConfig';

const {
  theme: {
    styles: {
      modalStyles: {
        button,
        dialog,
        dismissButton,
        title,
        titleVariant,
        titlePaddingTop,
        titleActionButtonPaddingTop,
      },
    },
  },
} = wlConfig;

const propTypes = {
  children: PropTypes.node,
  onConfirm: PropTypes.func,
  onDismiss: PropTypes.func,
  onClear: PropTypes.func,
  onCustomButtonClick: PropTypes.func,
  confirmLabel: PropTypes.string,
  dismissLabel: PropTypes.string,
  clearLabel: PropTypes.string,
  customLabel: PropTypes.string,
  modalTitle: PropTypes.string,
  hasCloseIcon: PropTypes.bool,
  customClasses: PropTypes.object,
  hideFooterActions: PropTypes.bool,
  fullScreen: PropTypes.bool,
  square: PropTypes.bool,
  hasSlideTransition: PropTypes.bool,
  hasModalTitleAction: PropTypes.bool,
  hasLoaderOnActions: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  isAlert: PropTypes.bool,
};

const defaultProps = {
  children: null,
  onConfirm: () => {},
  onDismiss: () => {},
  onClear: () => {},
  onCustomButtonClick: () => {},
  confirmLabel: 'Save',
  dismissLabel: 'Cancel',
  clearLabel: '',
  customLabel: '',
  modalTitle: '',
  hasCloseIcon: false,
  customClasses: {},
  hideFooterActions: false,
  fullScreen: false,
  square: false,
  hasSlideTransition: false,
  hasModalTitleAction: false,
  hasLoaderOnActions: false,
  isSubmitting: false,
  isAlert: false,
};

const useStyles = makeStyles((theme) => ({
  dialog_paper: {
    minWidth: 300,
    padding: 0,
  },
  dialog_title: ({ isAlert }) => {
    return {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      zIndex: 1,
      boxShadow: isAlert ? 'none' : '0px 0px 5px rgba(0, 0, 0, 0.12)',
      background: theme.palette.tenant.white,
      padding: '14px 24px',
    };
  },
  modal_title: ({ isAlert }) => {
    return {
      paddingTop: titlePaddingTop,
      width: isAlert ? '100%' : '90%',
      ...title,
    };
  },
  modal_title_icon: ({ fullScreen }) => {
    return fullScreen
      ? {
          marginRight: 16,
          color: theme.palette.tenant.primaryMid,
          padding: 0,
        }
      : {
          color: theme.palette.tenant.primaryMid,
          position: 'absolute',
          right: 16,
        };
  },
  modalTitleAction_button: {
    position: 'absolute',
    right: 16,
    paddingTop: titleActionButtonPaddingTop,
    cursor: 'pointer',
    color: theme.palette.tenant.primaryMid,
    fontSize: 16,
  },
  dialog: {
    ...dialog,
  },
  withAdditionalAction: {
    justifyContent: 'flex-start',
    '& > *:first-child': {
      marginRight: 'auto',
    },
  },
  customActionButton: {
    color: theme.palette.tenant.accent,
    ...button,
  },
  defaultActionButton: {
    color: theme.palette.tenant.primaryMid,
    ...button,
  },
  defaultDismissButton: {
    color: theme.palette.tenant.primaryMid,
    ...dismissButton,
    ...button,
  },
  dialogActionsBar: ({ isAlert }) => {
    return {
      position: 'relative',
      boxShadow: isAlert ? 'none' : '0px 0px 5px rgba(0, 0, 0, 0.12)',
      background: theme.palette.tenant.white,
    };
  },
}));

const SlideTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DefaultTransition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

function ModalDialog({
  children,
  onConfirm,
  onDismiss,
  onClear,
  onCustomButtonClick,
  confirmLabel,
  dismissLabel,
  clearLabel,
  customLabel,
  modalTitle,
  hasCloseIcon,
  customClasses,
  hideFooterActions,
  fullScreen,
  hasModalTitleAction,
  square,
  hasSlideTransition,
  isSubmitting,
  hasLoaderOnActions,
  'data-testid': dataTestId,
  open,
  isAlert,
}) {
  const classes = useStyles({
    fullScreen,
    isAlert,
  });

  const [clickedAction, setClickedAction] = useState('');
  useEffect(() => {
    if (!isSubmitting) {
      setClickedAction('');
    }
  }, [isSubmitting]);

  return (
    <Dialog
      role="dialog"
      open={open}
      onClose={onDismiss}
      classes={{
        paper: classes.dialog_paper,
      }}
      fullScreen={fullScreen}
      TransitionComponent={
        hasSlideTransition ? SlideTransition : DefaultTransition
      }
      PaperProps={{ square }}
      data-testid={dataTestId}
    >
      {modalTitle && (
        <DialogTitle
          disableTypography
          className={classnames(
            classes.dialog_title,
            customClasses.dialog_title
          )}
          data-testid="dialog_title"
        >
          {hasCloseIcon && (
            <IconButton
              data-testid="dialog_title_closeIcon"
              color="inherit"
              onClick={onDismiss}
              disabled={isSubmitting}
              aria-label="close"
              classes={{
                root: classes.modal_title_icon,
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
          <Typography
            variant={titleVariant}
            className={classnames(
              classes.modal_title,
              customClasses.modal_title
            )}
          >
            {modalTitle}
          </Typography>
          {hasModalTitleAction && onConfirm && (
            <Button
              className={classes.modalTitleAction_button}
              disabled={isSubmitting}
              size="small"
              onClick={(e) => {
                onConfirm(e);
                hasLoaderOnActions && setClickedAction('confirm');
              }}
            >
              {clickedAction === 'confirm' && isSubmitting ? (
                <CircularProgress
                  data-testid="dialog_confirm_spinner"
                  size="30px"
                  classes={{
                    colorPrimary: classes.defaultActionButton,
                  }}
                />
              ) : (
                confirmLabel
              )}
            </Button>
          )}
        </DialogTitle>
      )}
      <DialogContent
        children={children}
        className={classnames(classes.dialog, customClasses.dialog)}
        data-testid="dialog_content"
      />

      {!hideFooterActions &&
        (clearLabel || customLabel || dismissLabel || confirmLabel) && (
          <DialogActions
            data-testid="dialog_footer_actions"
            className={classnames(
              classes.dialogActionsBar,
              customClasses.dialogActionsBar
            )}
            classes={{
              root: classnames({
                [classes.withAdditionalAction]: clearLabel || customLabel,
              }),
            }}
          >
            {clearLabel && (
              <Button color="primary" onClick={onClear} disabled={isSubmitting}>
                {clearLabel}
              </Button>
            )}
            {customLabel && (
              <Button
                data-testid={`dialog_${snakeCase(customLabel)}Btn`}
                className={classes.customActionButton}
                classes={{
                  label: classnames(
                    classes.customActionButton,
                    customClasses.customActionButton
                  ),
                }}
                onClick={(e) => {
                  onCustomButtonClick(e);
                  hasLoaderOnActions && setClickedAction('custom');
                }}
                size="small"
                disabled={isSubmitting}
              >
                {clickedAction === 'custom' && isSubmitting ? (
                  <CircularProgress
                    data-testid="dialog_custom_spinner"
                    size="30px"
                    classes={{
                      colorPrimary: classes.customActionButton,
                    }}
                  />
                ) : (
                  customLabel
                )}
              </Button>
            )}
            {dismissLabel && (
              <Button
                classes={{
                  label: classnames(
                    classes.defaultDismissButton,
                    customClasses.dismissButton
                  ),
                }}
                onClick={onDismiss}
                size="small"
                data-testid="dialog_closeBtn"
                disabled={isSubmitting}
              >
                {dismissLabel}
              </Button>
            )}
            {confirmLabel && (
              <Button
                data-testid={`dialog_${snakeCase(confirmLabel)}Btn`}
                classes={{
                  label: classnames(
                    classes.defaultActionButton,
                    customClasses.confirmButton
                  ),
                }}
                size="small"
                onClick={(e) => {
                  onConfirm(e);
                  hasLoaderOnActions && setClickedAction('confirm');
                }}
                disabled={isSubmitting}
              >
                {clickedAction === 'confirm' && isSubmitting ? (
                  <CircularProgress
                    data-testid="dialog_confirm_spinner"
                    size="30px"
                    classes={{
                      colorPrimary: classes.defaultActionButton,
                    }}
                  />
                ) : (
                  confirmLabel
                )}
              </Button>
            )}
          </DialogActions>
        )}
    </Dialog>
  );
}

ModalDialog.propTypes = propTypes;
ModalDialog.defaultProps = defaultProps;

export default ModalDialog;
