import { useRef, useMemo, useReducer, useEffect } from 'react';
import moment from 'moment-timezone';
import isEqual from 'lodash/isEqual';

const loggerStyles = {
  color: {
    green: 'color: #44DD7F;',
    blue: 'color: #04A9F4;',
  },
  style: {
    normal: 'font-weight: normal;',
    bold: 'font-weight: bold;',
  },
};

const isNotProdOrTest =
  process.env.REACT_APP_ENV !== 'production' && process.env.NODE_ENV !== 'test';

function withLogger(dispatch) {
  return function (action) {
    if (isNotProdOrTest) {
      console.group(
        `%caction %c${action.type} %c@ ${moment().format('HH:mm:ss.SSS')}`,
        loggerStyles.style.normal,
        loggerStyles.style.bold,
        loggerStyles.style.normal
      );
      console.log(
        '%caction',
        `${loggerStyles.color.blue}${loggerStyles.style.bold}`,
        action
      );
    }
    return dispatch(action);
  };
}

function useReducerWithLogger(reducer, initialState) {
  const prevState = useRef(initialState);
  const [state, dispatch] = useReducer(reducer, initialState);
  const dispatchWithLogger = useMemo(() => {
    return withLogger(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (isNotProdOrTest) {
      if (!isEqual(prevState.current, state)) {
        console.log('%cprev state', loggerStyles.style.bold, prevState.current);
        console.log(
          '%cnext state',
          `${loggerStyles.color.green}${loggerStyles.style.bold}`,
          state
        );
      }
      console.groupEnd();
    }
    prevState.current = state;
  }, [state]);

  return [state, dispatchWithLogger];
}

export default useReducerWithLogger;
