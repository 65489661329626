import React from 'react';
import LayoutRoot from '../../components/LayoutRoot';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useGlobalState } from '../../store';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
  const {
    palette: { tenant },
    breakpoints,
  } = theme;

  return {
    '@global': {
      body: {
        background: tenant.white,
      },
    },
    aboutContainer: {
      padding: '8px 24px 32px 24px',
      [breakpoints.up('sm')]: {
        padding: '24px 64px 56px 64px',
      },
      [breakpoints.up('md')]: {
        padding: '10px 148px 58px 148px',
      },
    },
    headerText: {
      color: tenant.primary,
      marginTop: 24,
      [breakpoints.up('sm')]: {
        fontSize: 32,
        marginTop: 32,
      },
      [breakpoints.up('md')]: {
        marginTop: 48,
      },
    },
    bodyText: {
      fontSize: 14,
      [breakpoints.up('sm')]: {
        fontSize: 16,
      },
    },
  };
});

function HeaderText({ className, children }) {
  return (
    <Typography
      className={className}
      component="h2"
      variant="h3"
      align="left"
      gutterBottom
    >
      {children}
    </Typography>
  );
}

function BodyText({ className, children }) {
  return (
    <Typography
      className={className}
      variant="body2"
      align="left"
      paragraph
      gutterBottom
    >
      {children}
    </Typography>
  );
}

function AboutUsView() {
  const classes = useStyles();
  const { isLoggedIn } = useGlobalState();
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  return (
    <LayoutRoot
      withFooter
      withRightsReservedFooter={!isLoggedIn}
      withTopNavigation
      withAlternateBackground={isDesktop}
    >
      <Container className={classes.aboutContainer}>
        <HeaderText className={classes.headerText}>About Farmers</HeaderText>
        <Typography component="h3" variant="h5" align="left" gutterBottom>
          Serving America Since 1928
        </Typography>
        <BodyText className={classes.bodyText}>
          In 1928, two men shared a dream of providing a quality insurance
          product at a reasonable price. In the decades that followed we’ve
          grown and adapted to meet the changing needs of Americans. But one
          constant has remained: We have an unwavering commitment to uphold our
          founding ideals to provide industry-leading products and first-rate
          services to the customers we’re privileged to serve.
        </BodyText>
        <HeaderText className={classes.headerText}>
          Different Eras, Same Exceptional Service
        </HeaderText>
        <BodyText className={classes.bodyText}>
          Farmers not only prides itself on helping you plan wisely for the
          unexpected, but also on helping restore order when it occurs, so you
          can keep moving along the road of your life’s plans.
        </BodyText>
        <BodyText className={classes.bodyText}>
          In 1959 a Farmers customer wrote us a letter of surprised gratitude
          when his car battery was replaced just an hour after it was stolen. A
          letter from a couple in 2002 wrote that their Farmers agent and claims
          adjuster “were exceptional in their handling of our grief, what we
          needed to do, and helpful in many other ways.” That same year, when
          Hurricane Rita struck Beaumont, Texas, Farmers sent 300 agents to
          assess damages while policy holders were evacuated. Farmers also
          donated $100,000 for the city’s emergency operations center and two
          megawatt generators that restored power to Beaumont. Whatever year or
          decade a disaster occurs, you can count on us to be there for you.
        </BodyText>
        <BodyText className={classes.bodyText}>
          Today, the companies comprising the Farmers Insurance Group of
          Companies make up one of the country's largest insurers of vehicles,
          homes and small businesses and provide a wide range of other insurance
          and financial services products.
        </BodyText>
        <BodyText className={classes.bodyText}>
          Farmers is proud to serve more than 10 million households with more
          than 19 million individual policies across all 50 states through the
          efforts of over 48,000 exclusive and independent agents and nearly
          21,000 employees.
        </BodyText>
        <BodyText className={classes.bodyText}>
          The Farmers Exchanges are three reciprocal insurers (Farmers Insurance
          Exchange, Fire Insurance Exchange and Truck Insurance Exchange) owned
          by their policyholders, and together with their subsidiaries and
          affiliates comprise the Farmers Insurance Group of Companies. Farmers
          Group, Inc. and its subsidiaries, in their capacity as
          attorneys-in-fact, provide administrative and management services to
          the Farmers Exchanges.
        </BodyText>
        <HeaderText className={classes.headerText}>Our Story</HeaderText>
        <BodyText className={classes.bodyText}>
          Our story began with the simple goal of insuring the vehicles of rural
          farmers but as the world changed, so did we.
        </BodyText>
        <HeaderText className={classes.headerText}>
          Why choose Farmers?
        </HeaderText>
        <BodyText className={classes.bodyText}>
          We’ve spent almost 90 years putting customers first — personalized
          attention is a hallmark of the Farmers experience.
        </BodyText>
      </Container>
    </LayoutRoot>
  );
}

export default AboutUsView;
