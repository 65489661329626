const SlatePro = require('../../assets/farmers-direct/fonts/SlatePro.woff');
const SlateProBk = require('../../assets/farmers-direct/fonts/SlatePro-Bk.woff');
const SlateProMedium = require('../../assets/farmers-direct/fonts/SlatePro-Medium.woff');
const farmersLogo = require('../../assets/farmers-direct/img/farmers-logo.svg');
const farmersLogoDark = require('../../assets/farmers-direct/img/farmers-logo-dark.svg');
const quoteLoadingIcon = require('../../assets/farmers-direct/img/icon-car.svg');
const bristolWestIcon = require('../../assets/farmers-direct/img/icon-phone.svg');
const errorIcon = require('../../assets/farmers-direct/img/icon-exclamation.svg');
const towTruckIcon = require('../../assets/img/icon-towtruck.svg');
const {
  default: faqConfig,
} = require('../../assets/farmers-direct/faqConfig.js');
const {
  default: errorConfig,
} = require('../../assets/farmers-direct/errorConfig.js');

const TENANT = 'farmers_direct_auto';
const TENANT_DISPLAY_NAME = 'FarmersDirect';
const VERTICAL_CODE = 'farmers_direct_auto';
const SPACE = 'farmers_direct';

const colors = {
  accent: '#E01933',
  accentDark: '#B30032',
  defaultText: '#000000',
  mutedDark: '#585858',
  mutedLight: '#E7E7E7',
  mutedMid: '#B6B6B6',
  offWhite: '#FAFAFA',
  primary: '#003087',
  primaryHighlight: '#158FEF',
  primaryLight: '#B4C4EA',
  primaryMid: '#0073CF',
  success: '#55B800',
  warning: '#FFAE00',
  white: '#fff',
  whiteDark: '#f7f7f7',
};

const config = {
  accountInfo: {
    hasCreateAccountCta: false,
    serviceLogoPath: null,
    serviceName: null,
  },
  api: {
    space: SPACE,
    verticalCode: VERTICAL_CODE,
  },
  coverageCardLabels: {
    subtitle: 'Applied to all drivers and vehicles on your policy',
  },
  coverageQuoteViewLabels: {
    headerLabel: 'Coverages',
    policyLevelSubHeaderLabel:
      'Applied to all drivers and vehicles on your policy',
    subHeaderLabel:
      'We’ve tried to match your current policy coverage limits and deductibles. Please review and make any changes.',
  },
  defaultValues: {
    hasViolation: null,
    quoteCoveragesByOwnershipStatus: [],
  },
  discountCardsLabel: 'Discounts applied',
  driverQuoteViewLabel: 'Relationship to applicant',
  errorConfig,
  faqConfig,
  featureFlags: {
    checkoutLineItemBlacklist: [],
    hasRenewalBanner: true,
    hasCCPABanner: true,
    isCheckoutLoadingViewEnabled: true,
    isPurchaseFlowEnabled: true,
    isQuoteCreationEnabled: true,
    isSelfServiceEnabled: true,
  },
  footerConfig: {
    copyrightCopy: 'Farmers',
    copyrightDisclaimer: null,
    footerCopyText: null,
    hasBuiltOnSure: false,
    hasFooterNavItems: true,
    hasSocialListItems: {
      facebook: {
        url: 'https://www.facebook.com/FarmersInsurance',
      },
      instagram: {
        url: 'https://www.instagram.com/wearefarmers/',
      },
      linkedin: {
        url: 'https://www.linkedin.com/company/farmers-insurance/',
      },
      twitter: {
        url: 'https://twitter.com/wearefarmers',
      },
    },
    showRightsReserved: true,
  },
  icons: {
    bristolWestIcon,
    errorIcon,
    quoteLoadingIcon,
    towTruckIcon,
  },
  localforageDbName: TENANT,
  logos: {
    footerLogo: farmersLogo,
    logoAlternate: farmersLogoDark,
    logoPrimary: farmersLogo,
  },
  policyCheckoutView: {
    billingTitle: 'Billing details',
  },
  policyView: {
    overviewTitle: 'Policy Overview',
  },
  quoteExpirationCondition: {
    maxElapsedTimeMinutes: 30,
    onConfirmDestination: '/enroll/start',
    timestampKey: 'updated_at',
  },
  quoteLoadingCopy: 'One moment while we do some research...',
  renewalBanner: {
    actionLabel: 'View Documents',
    dismissLabel: 'Dismiss',
  },
  reviewQuoteViewLabels: {
    reviewQuoteSubheader: 'Please take a moment to confirm your selections.',
    yourCoverageAndVehiclesLabel: '',
    yourDiscountsLabel: '',
    yourDriversLabel: '',
  },
  tenantDisplayName: TENANT_DISPLAY_NAME,
  theme: {
    colors,
    fonts: {
      defaultFonts: {
        names: ['SlatePro-Bk'],
        weight: 'normal',
      },
      fontFaceStyles: [
        {
          fontDisplay: 'swap',
          fontFamily: 'SlatePro',
          fontStyle: 'normal',
          fontWeight: 'normal',
          src: `	
            local('SlatePro'),	
            url(${SlatePro}) format('woff')	
          `,
        },
        {
          fontDisplay: 'swap',
          fontFamily: 'SlatePro',
          fontStyle: 'normal',
          fontWeight: 500,
          src: `	
            local('SlatePro-Medium'),	
            url(${SlateProMedium}) format('woff')	
          `,
        },
        {
          fontDisplay: 'swap',
          fontFamily: 'SlatePro-Bk',
          fontStyle: 'normal',
          fontWeight: 'normal',
          src: `	
            local('SlatePro-Bk'),	
            url(${SlateProBk}) format('woff')	
          `,
        },
      ],
      headerFonts: {
        names: ['SlatePro'],
        weight: 500,
      },
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          body: {
            background:
              'linear-gradient(90deg, rgba(220, 235, 255, 0.2) 0%, rgba(96, 163, 219, 0.2) 100%), #FFFFFF;',
          },
        },
      },
    },
    shape: {
      borderRadius: 5,
    },
    styles: {
      addButtonStyles: {
        button: {
          '&:active': {
            boxShadow: 'none',
          },
          background: 'linear-gradient(180deg, #F7F7F7 49.03%, #E7E7E7 49.82%)',
          border: `1px solid ${colors.mutedMid}`,
          boxShadow: 'none',
          boxSizing: 'border-box',
        },
        buttonLabel: {
          color: colors.primaryMid,
          fontSize: 16,
        },
      },
      addModalSubtitleStyles: {
        larger: {
          color: colors.primary,
          marginBottom: 16,
        },
        mobile: {
          color: colors.defaultText,
          marginBottom: 8,
        },
      },
      buttonStyles: {
        tenantButton: {
          '&:disabled': {
            backgroundColor: colors.mutedLight,
            backgroundImage: 'none',
          },
          background: `linear-gradient(to bottom, ${colors.primaryHighlight} 0%, ${colors.primaryHighlight} 50%, ${colors.primaryMid} 51%)`,
          color: colors.white,
          fontSize: 16,
          height: 40,
          lineHeight: 2.2,
          minWidth: 160,
        },
      },
      ccpaBannerStyles: {
        rootStyles: {
          small: { backgroundColor: colors.white, color: colors.primary },
          large: { backgroundColor: colors.primary, color: colors.white },
        },
        iconCardButtonLink: {
          small: { color: colors.primaryHighlight },
          large: { color: colors.white },
        },
      },
      circularProgressStyles: {
        avatar: {
          backgroundColor: colors.primary,
        },
      },
      coverageCardStyles: {
        buttonLink: {
          color: colors.primaryMid,
        },
        headers: {},
        subtitle: {},
      },
      coverageQuoteViewStyles: {
        headers: {},
      },
      iconCardLineItemStyles: {
        iconInfo: {
          color: colors.primaryHighlight,
        },
      },
      discountCardsStyles: {
        headers: {},
        iconInfo: {
          color: colors.primaryHighlight,
        },
        subtitle: {},
      },
      errorViewStyles: {
        errorMessage: {},
      },
      faqStyles: {
        faqHeaderColor: colors.primary,
        topicLabelColor: colors.primaryHighlight,
        topicSectionLabelColor: colors.primary,
        labelDividerColor: colors.mutedLight,
        faqBackground: colors.white,
        topicAnswerColor: colors.defaultText,
      },
      footerStyles: {
        backgroundColor: colors.primary,
        color: colors.primaryLight,
        footerCopy: {},
      },
      iconCardStyles: {
        iconCardButtonLink: {
          color: colors.primaryMid,
        },
        iconColors: {
          icon: colors.white,
          modal: colors.mutedMid,
          nonModal: colors.primary,
        },
      },
      inputStyles: {
        inputLabelStyles: {
          margin: 'dense',
          variant: 'filled',
        },
        radioStyles: {
          extendedFormControl: {
            margin: 8,
          },
          radio_root: {},
          selectedRadio_root: {},
        },
        selectInputStyles: {
          component: 'FilledInput',
          select_disabled: {
            background: colors.mutedLight,
          },
          select_options_list: {
            backgroundColor: colors.offWhite,
            padding: 0,
          },
          variant: 'filled',
        },
        textFieldStyles: {
          label: {},
          underline: {},
          variant: 'filled',
        },
      },
      layoutPolicyStyles: {
        body: {
          background:
            'linear-gradient(90deg, rgba(220, 235, 255, 0.2) 0%, rgba(96, 163, 219, 0.2) 100%), #FFFFFF',
        },
        breadcrumbSeparator: {
          color: colors.white,
        },
        currentBreadcrumb: {
          color: colors.white,
        },
        parentBreadcrumb: {
          color: colors.primaryHighlight,
        },
        priceBlock: {
          backgroundColor: colors.primary,
        },
        priceBlockHeader: {
          color: colors.primaryLight,
        },
        priceBlockPolicycost: {
          color: colors.white,
        },
        priceBlockSubtitle: {
          color: colors.white,
        },
        stagedPolicyBlock: {
          backgroundColor: colors.primaryMid,
        },
        stagedPolicyCopy: {
          color: colors.white,
        },
        stagedPolicyHeader: {
          color: colors.white,
        },
        unstageChangesBtn: {
          color: colors.white,
        },
        updatePriceBtn: {
          '&:active': {
            boxShadow: 'none',
          },
          background: colors.white,
          boxShadow: 'none',
          color: colors.primaryMid,
        },
      },
      layoutQuoteStyles: {
        buttonQuoteCostColor: colors.primary,
        buttonWrapperColor: colors.white,
        headerColor: colors.primary,
        headerTextTransform: 'none',
        priceBlock: {
          backgroundColor: colors.primary,
          hasChevron: true,
          headerColor: colors.primaryLight,
          headerVariant: 'h3',
          iconColor: colors.accent,
          oldQuoteCost: {
            color: colors.mutedMid,
          },
          quoteCostColor: colors.white,
          quoteCostFontWeight: 'normal',
          quoteCostMobileFontWeight: 'normal',
          subtitleColor: colors.white,
          subtitleMarginTop: -8,
          undoChangesColor: colors.primaryHighlight,
          undoChangesFontWeight: 'normal',
          undoIconColor: colors.primaryHighlight,
        },
        quoteSpinnerColor: colors.white,
        subHeaderColor: colors.defaultText,
      },
      layoutRootStyles: {
        topBar: {
          desktopStyles: {
            topBar_link: {
              color: colors.white,
            },
            topBar_link_alternate: {
              color: colors.primary,
            },
          },
          styles: {
            topBar_alternateBackground: {
              background: colors.white,
            },
            topBar_drawerIcon_alternate: {
              color: colors.primary,
            },
          },
        },
      },
      layoutStyles: {
        layoutQuestion: {
          disclaimer: {
            color: colors.mutedDark,
            marginTop: 24,
          },
          header: {
            color: colors.primary,
            marginBottom: 16,
          },
          subHeader: {
            color: colors.defaultText,
          },
        },
      },
      modalStyles: {
        dialog: {
          background: colors.offWhite,
        },
        headerTextStyles: {
          large: {
            color: colors.primary,
          },
          small: {
            color: colors.primary,
          },
        },
        titleActionButtonPaddingTop: 8,
        titlePaddingTop: 8,
        titleVariant: 'h5',
      },
      policyCardStyles: {
        activeIconColor: colors.primary,
        canceledIconColor: colors.mutedMid,
        lapsedIconColor: colors.warning,
      },
      policyViewStyles: {
        roadsideAssistIcon: {
          height: 40,
          width: 60,
        },
        sectionHeader: {},
      },
      progressBarStyles: {
        barStyles: {
          backgroundColor: colors.primaryHighlight,
          transition: 'transform 0.5s cubic-bezier(0.4, 0.15, 0.19, 1)',
        },
        rootStyles: {
          backgroundColor: colors.white,
          boxShadow:
            '0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14)',
        },
      },
      quoteLoadingStyles: {
        header: {
          color: colors.primary,
        },
      },
      reviewQuoteStyles: {
        sectionHeaderStyle: {
          color: colors.defaultText,
        },
      },
      renewalBannerStyles: {
        avatarColor: colors.primary,
        iconCardButtonLink: {
          color: colors.primaryMid,
        },
        iconColor: colors.white,
      },
      sliderStyles: {
        valueLabel: {},
      },
      snackbarStyles: {
        actionButton: {
          color: colors.primaryMid,
        },
      },
      verifyInformationStyles: {
        header: {
          color: colors.primary,
        },
      },
    },
    typography: {
      button: {},
      h1: {},
    },
  },
  vehicleQuoteViewCopy: {
    subHeader:
      'We’ve added the vehicles that we found on your current policy. Please review and add or remove vehicles as needed.',
  },
  verifyInformationCopy:
    'One moment while we do a final check and confirm your quote ...',
  viewLabel: 'View profile',
};

module.exports = config;
