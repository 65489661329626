import React from 'react';
import LayoutRoot from '../../components/LayoutRoot/LayoutRoot';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useGlobalState } from '../../store';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
  const {
    palette: { tenant },
    breakpoints,
  } = theme;

  return {
    '@global': {
      body: {
        background: tenant.white,
      },
    },
    touContainer: {
      padding: '32px 24px',
      [breakpoints.up('sm')]: {
        padding: '56px 64px',
      },
      [breakpoints.up('md')]: {
        padding: '58px 148px',
      },
    },
    headerText: {
      color: tenant.primary,
      marginTop: 24,
      [breakpoints.up('sm')]: {
        fontSize: 32,
        marginTop: 32,
      },
      [breakpoints.up('md')]: {
        marginTop: 48,
      },
      '&:first-of-type': {
        marginTop: 0,
      },
    },
    bodyText: {
      fontSize: 14,
      [breakpoints.up('sm')]: {
        fontSize: 16,
      },
    },
    link: {
      textDecoration: 'none',
      '&:hover': {
        color: tenant.primaryHighlight,
      },
    },
  };
});

function HeaderText({ className, children }) {
  return (
    <Typography
      className={className}
      component="h2"
      variant="h3"
      align="left"
      gutterBottom
    >
      {children}
    </Typography>
  );
}

function BodyText({ className, children }) {
  return (
    <Typography
      className={className}
      variant="body2"
      align="left"
      paragraph
      gutterBottom
    >
      {children}
    </Typography>
  );
}

function ListText({ component, className, children }) {
  return (
    <Typography
      className={className}
      component={component || 'ul'}
      align="left"
      paragraph
      gutterBottom
    >
      {children}
    </Typography>
  );
}

function PaymentTermsOfUseView() {
  const classes = useStyles();
  const { isLoggedIn } = useGlobalState();

  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  return (
    <LayoutRoot
      withFooter
      withRightsReservedFooter={!isLoggedIn}
      withTopNavigation
      withAlternateBackground={isDesktop}
    >
      <Container className={classes.touContainer}>
        <HeaderText className={classes.headerText}>
          PAYMENT AUTHORIZATION TERMS AND CONDITIONS
        </HeaderText>
        <BodyText className={classes.bodyText}>
          These terms were last updated on November 3rd, 2023.
        </BodyText>
        <HeaderText className={classes.headerText}>
          One-Time Payment Authorization Terms and Conditions
        </HeaderText>
        <BodyText className={classes.bodyText}>
          <strong>Definitions:</strong> "We,” “us,” “our” and "ours" mean the
          insurer(s) authorized to process One-Time Bank Payment withdrawal(s)
          or Payment Card charge(s) for insurance payment(s) and any third party
          service providers, as applicable. “You,” “your” and “yours” mean the
          person or persons authorizing the One-Time Bank Payment withdrawal(s)
          or Payment Card charge(s) for insurance payments. “One- Time Bank
          Payment” means a single electronic withdrawal being deducted from your
          designated Bank Account on the date selected by you. “Payment Card”
          means any credit or debit card. “Business day” for Bank Payment
          withdrawal(s) means Monday through Friday, excluding our company
          holidays. “Business day” for Payment Card charge(s) means Monday
          through Sunday, including company holidays. Your designated Bank
          Account or Payment Card includes any replacement account number and/or
          routing number received electronically from the designated financial
          institution.
        </BodyText>
        <BodyText className={classes.bodyText}>
          <strong>Service Provider:</strong> You authorize us to use a third
          party, Sure, Inc., and its vendors, to process the authorized one-time
          Bank payment withdrawal(s) or Payment Card charge(s). We may also use
          Stripe (<a href="https://stripe.com">https://stripe.com</a>), a
          third-party payment platform, in connection with the Site and Services
          to process certain Payment Card charges. By using the Services, you
          agree that Stripe can collect, use, retain, and disclose information
          that we or you provide to Stripe in accordance with Stripe’s privacy
          policy (
          <a href="https://stripe.com/privacy">https://stripe.com/privacy</a>
          )).
        </BodyText>
        <BodyText className={classes.bodyText}>
          <strong>Financial Institution Authorization:</strong> You authorize
          the financial institution(s) holding your Bank Account(s) or Payment
          Card(s) to honor the withdrawal(s)/charge(s).
        </BodyText>
        <BodyText className={classes.bodyText}>
          <strong>Application of Payments:</strong>
          <br />
          <ListText component="ol">
            <li>
              Funds withdrawn or charged will be applied only to the designated
              billing account(s) or its replacement.
            </li>
            <li>
              The billing system runs on UTC (Coordinated Universal Time) so the
              payment process may start the night before the due date.
            </li>
            <li>
              You agree to have the:
              <br />
              a) Funds available in the designated Bank Account(s) on the
              date(s) you request the One-Time Bank Payment(s), whether or not
              the date(s) falls on a business day. [Note: It may take 3-5
              business days for your Bank Account(s) to reflect the
              payment(s).]; or
              <br />
              b) Funds available in the designated Payment Card account(s) on
              the date(s) you request the charge(s), whether or not the date(s)
              falls on a business day.
            </li>
          </ListText>
        </BodyText>
        <BodyText className={classes.bodyText}>
          <strong>Payments Not Honored:</strong> One-time payments that are not
          honored, including for reasons such as insufficient funds, may be
          resubmitted at our discretion. If we are unable to electronically
          withdraw the funds from your Bank Account(s) or charge the payment(s)
          to your Payment Card(s), any corresponding payment(s) posted in good
          faith will be reversed from the billing account(s) and cancellation
          notice(s) may be issued for the policy(ies) attached to the designated
          billing account(s).
        </BodyText>
        <BodyText className={classes.bodyText}>
          <strong>
            Excessive Returned Payments or Stopped Drafts/Charges:
          </strong>{' '}
          If we receive multiple returned payments, your account may become
          ineligible for One-Time Bank Payments, at our discretion.
        </BodyText>
        <BodyText className={classes.bodyText}>
          <strong>Returned Payment Charges:</strong> We may assess Returned
          Payment Charges if One-Time Bank Payments are returned for reasons
          such as insufficient funds, closed Bank Account or revoked
          authorization.
        </BodyText>
        <BodyText className={classes.bodyText}>
          <strong>Third-Party Use of Services:</strong> This One-Time payment
          option shall only be used by actual and bona-fide customers of ours.
          Third-Party use of these services is not permitted. You shall not use
          this One-Time payment option, or any information contained herein, for
          any unauthorized purpose or commercial use. Third-party commercial
          payment aggregators (third-party payment servicers or processors, or
          any other entities submitting payments on behalf of our customers) may
          not submit payments using this One-Time payment option. You hereby
          agree to be liable to us for all interchange fees and merchant fees,
          and all other costs and fees associated with processing all payments
          submitted by you in violation of this section. You further agree to be
          liable to us for all other damages, costs, fees, expenses, and
          reasonable attorney fees incurred by us arising out of, or related to,
          your breach of this section and our enforcement thereof.
        </BodyText>
        <BodyText className={classes.bodyText}>
          <strong>
            Exclusions of Warranties and Limitation of Liabilities:
          </strong>{' '}
          THE ONE-TIME BANK PAYMENT AND ONE-TIME PAYMENT CARD PROCESSING
          SERVICES AND RELATED DOCUMENTATION ARE PROVIDED ON AN “AS IS” BASIS
          WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING,
          BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND
          FITNESS FOR A PARTICULAR PURPOSE.
        </BodyText>
        <BodyText className={classes.bodyText}>
          In addition, we do not warrant, guarantee or make any representations
          regarding the security of Bank or Payment Card accounts, or that the
          web site is free from destructive materials, including but not limited
          to computer viruses, hackers, or other technical sabotage, nor does it
          warrant, guarantee or make any representations that access to this
          site will be fully accessible at all times, uninterrupted, or
          error-free.
        </BodyText>
        <BodyText className={classes.bodyText}>
          IN NO EVENT WILL WE OR OUR AFFILIATES BE LIABLE FOR ANY DAMAGES,
          INCLUDING WITHOUT LIMITATION DIRECT OR INDIRECT, SPECIAL, INCIDENTAL,
          COMPENSATORY, EXEMPLARY OR CONSEQUENTIAL DAMAGES, LOSSES OR EXPENSES,
          INCLUDING WITHOUT LIMITATION LOST OR MISDIRECTED APPLICATIONS, LOST
          PROFITS, LOST GOODWILL, OR LOST OR STOLEN PROGRAMS OR OTHER DATA,
          HOWEVER CAUSED AND UNDER ANY THEORY OF LIABILITY ARISING OUT OF OR IN
          CONNECTION WITH (1) USE OF THE WEB SITE, OR THE INABILITY TO USE THE
          WEB SITE BY ANY PARTY; OR (2) ANY FAILURE OR PERFORMANCE, ERROR,
          OMISSION, INTERRUPTION, DEFECT, DELAY IN OPERATION OR TRANSMISSION; OR
          (3) LINE OR SYSTEM FAILURE OR THE INTRODUCTION OF A COMPUTER VIRUS, OR
          OTHER TECHNICAL SABOTAGE, EVEN IF WE OR OUR AFFILIATES, OR THE
          EMPLOYEES OR REPRESENTATIVES THEREOF, ARE ADVISED OF THE POSSIBILITY
          OR LIKELIHOOD OF SUCH DAMAGES, LOSSES OR EXPENSES.
        </BodyText>
        <BodyText className={classes.bodyText}>
          <strong>System Requirements/Equipment:</strong> We use encryption to
          make your information unreadable as it passes over the Internet.
          Therefore, we strongly recommend that you use the latest version of
          your browser software for maximum security.
        </BodyText>
        <BodyText className={classes.bodyText}>
          <strong>Farmers® Privacy Policy:</strong> To view our Privacy Policy,
          go to{' '}
          <a href="https://www.farmers.com/privacy-statement/">
            https://www.farmers.com/privacy-statement/
          </a>
          .
        </BodyText>
        <BodyText className={classes.bodyText}>
          <strong>Storage of Information:</strong> Information stored on this
          site is subject to physical, electronic and procedural safeguards that
          comply with applicable regulatory standards to guard your nonpublic
          personal information. We authorize our employees and agents to get
          information about you only when they need it to do their work for us.
          We require companies working for us to protect information. They agree
          to use it only to provide services we ask them to perform for us.
        </BodyText>
        <BodyText className={classes.bodyText}>
          <strong>Refunds:</strong> If a policy change results in a premium
          refund due, we may deposit and/or refund the amount due to your Bank
          Account or Payment Card currently on file.
        </BodyText>
        <BodyText className={classes.bodyText}>
          <strong>Amendments to Terms and Conditions:</strong> We reserve the
          right to change these Terms and Conditions at any time.
        </BodyText>
        <BodyText className={classes.bodyText}>
          <strong>Non-waiver:</strong> Any failure by us to act upon any breach
          of these Terms and Conditions shall not be deemed to constitute a
          waiver of any subsequent breach of that or any other term or
          condition, or of any right to thereafter enforce these Terms and
          Conditions.
        </BodyText>
        <HeaderText className={classes.headerText}>
          Automatic Payment Authorization Terms and Conditions
        </HeaderText>
        <BodyText className={classes.bodyText}>
          <strong>Definitions:</strong> “We,” “us,” “our” and “ours” mean the
          insurer(s) authorized to process Automatic Bank Payment withdrawal(s)
          or Payment Card charge(s) for insurance payment(s) and any third party
          service providers, as applicable. “You,” “your” and “yours” mean the
          person or persons authorizing the Automatic Bank Payment withdrawal(s)
          or Payment Card charge(s) for insurance payments Automatic Payments”
          means either withdrawals automatically deducted from your designated
          bank account or charges automatically applied to your designated
          Payment Card on the scheduled due dates for the amounts due.
          “Automatic Bank Payment” means electronic funds transfer (EFT)
          withdrawals automatically being deducted from your designated Bank
          Account on the scheduled withdrawal dates for the amounts due.
          “Payment Card” means any credit or debit card, including reloadable
          pre-paid cards. “Business day” for Bank Payment withdrawal(s) means
          Monday through Friday, excluding our company holidays. “Business day”
          for Payment Card charge(s) means Monday through Sunday, including
          company holidays. Your designated Bank Account or Payment Card
          includes any replacement account number and/or routing number received
          electronically from the designated financial institution.
        </BodyText>
        <BodyText className={classes.bodyText}>
          <strong>Service Provider:</strong> You authorize us to use a third
          party, Sure, Inc., and its vendors, to process the authorized one-time
          Automatic Bank Payment withdrawal(s) or Payment Card charge(s). We
          also use Stripe (<a href="https://stripe.com">https://stripe.com</a>),
          a third-party payment platform, in connection with the Site and
          Services to process certain Payment Card charges. By using the
          Services, you agree that Stripe can collect, use, retain, and disclose
          information that we or you provide to Stripe in accordance with
          Stripe’s privacy policy (
          <a href="https://stripe.com/privacy">https://stripe.com/privacy</a>
          ).
        </BodyText>
        <BodyText className={classes.bodyText}>
          <strong>Financial Institution Authorization:</strong> You authorize
          the financial institution(s) holding your Bank Account(s) or Payment
          Card(s) to honor the withdrawal(s)/charge(s).
        </BodyText>
        <BodyText className={classes.bodyText}>
          <strong>Application of Payments:</strong>
          <br />
          <ListText component="ol">
            <li>
              Funds withdrawn or charged will be applied only to the designated
              billing account(s) or its replacement.
            </li>
            <li>
              The billing system runs on UTC (Coordinated Universal Time) so the
              payment process may start the night before the due date.
            </li>
            <li>
              You agree to have the:
              <br />
              a) Funds available in the designated Bank Account(s) on the
              date(s) you request the One-Time Bank Payment(s), whether or not
              the date(s) falls on a business day. [Note: It may take 3-5
              business days for your Bank Account(s) to reflect the
              payment(s).]; or
              <br />
              b) Funds available in the designated Payment Card account(s) on
              the date(s) you request the charge(s), whether or not the date(s)
              falls on a business day.
            </li>
          </ListText>
        </BodyText>
        <BodyText className={classes.bodyText}>
          <strong>Payments Not Honored:</strong> Automatic payments that are not
          honored, including for reasons such as insufficient funds, may be
          resubmitted at our discretion. If we are unable to electronically
          withdraw the funds from your Bank Account(s) or charge the payment(s)
          to your Payment Card(s), any corresponding payment(s) posted in good
          faith will be reversed from the billing account(s) and cancellation
          notice(s) may be issued for the policy(ies) attached to the designated
          billing account(s).
        </BodyText>
        <BodyText className={classes.bodyText}>
          <strong>Removal from Automatic Payments:</strong> If a payment is not
          honored, we may remove your account from the Automatic Payment method.
        </BodyText>
        <BodyText className={classes.bodyText}>
          <strong>
            Excessive Returned Payments or Stopped Drafts/Charges:
          </strong>{' '}
          If we receive multiple returned payments or stopped draft/charges,
          your account may become ineligible for Automatic Payments.
        </BodyText>
        <BodyText className={classes.bodyText}>
          <strong>Returned Payment Charges:</strong> We may assess Returned
          Payment Charges if Automatic Bank Payments are returned for reasons
          such as insufficient funds, or a closed Bank Account.
        </BodyText>
        <BodyText className={classes.bodyText}>
          <strong>Policy Cancellation:</strong> If we receive a request to
          cancel the policy, please note that we may not have enough time to
          cancel the upcoming payment if the due date is close. We will
          discontinue future Automatic Payments once we process the request to
          cancel the policy; however, it is possible that an Automatic Bank
          Payment withdrawal or Payment Card charge may be processed about the
          same time as the policy cancellation.
        </BodyText>
        <BodyText className={classes.bodyText}>
          <strong>Third-Party Use of Services:</strong> This Automatic payment
          option shall only be used by actual and bona-fide customers of ours.
          Third-Party use of these services is not permitted. You shall not use
          this Automatic payment option, or any information contained herein,
          for any unauthorized purpose or commercial use. Third-party commercial
          payment aggregators (third-party payment servicers or processors, or
          any other entities submitting payments on behalf of our customers) may
          not submit payments using this Automatic payment option. You hereby
          agree to be liable to us for all interchange fees and merchant fees,
          and all other costs and fees associated with processing all payments
          submitted by you in violation of this section. You further agree to be
          liable to us for all other damages, costs, fees, expenses, and
          reasonable attorney fees incurred by us arising out of, or related to,
          your breach of this section and our enforcement thereof.
        </BodyText>
        <BodyText className={classes.bodyText}>
          <strong>
            Exclusions of Warranties and Limitation of Liabilities:
          </strong>{' '}
          THE AUTOMATIC BANK PAYMENT AND AUTOMATIC PAYMENT CARD PROCESSING
          SERVICES AND RELATED DOCUMENTATION ARE PROVIDED ON AN “AS IS” BASIS
          WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING,
          BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND
          FITNESS FOR A PARTICULAR PURPOSE.
        </BodyText>
        <BodyText className={classes.bodyText}>
          In addition, we do not warrant, guarantee or make any representations
          regarding the security of Bank or Payment Card accounts, or that the
          web site is free from destructive materials, including but not limited
          to computer viruses, hackers, or other technical sabotage, nor does it
          warrant, guarantee or make any representations that access to this
          site will be fully accessible at all times, uninterrupted, or
          error-free.
        </BodyText>
        <BodyText className={classes.bodyText}>
          IN NO EVENT WILL WE OR OUR AFFILIATES BE LIABLE FOR ANY DAMAGES,
          INCLUDING WITHOUT LIMITATION DIRECT OR INDIRECT, SPECIAL, INCIDENTAL,
          COMPENSATORY, EXEMPLARY OR CONSEQUENTIAL DAMAGES, LOSSES OR EXPENSES,
          INCLUDING WITHOUT LIMITATION LOST OR MISDIRECTED APPLICATIONS, LOST
          PROFITS, LOST GOODWILL, OR LOST OR STOLEN PROGRAMS OR OTHER DATA,
          HOWEVER CAUSED AND UNDER ANY THEORY OF LIABILITY ARISING OUT OF OR IN
          CONNECTION WITH (1) USE OF THE WEB SITE, OR THE INABILITY TO USE THE
          WEB SITE BY ANY PARTY; OR (2) ANY FAILURE OR PERFORMANCE, ERROR,
          OMISSION, INTERRUPTION, DEFECT, DELAY IN OPERATION OR TRANSMISSION; OR
          (3) LINE OR SYSTEM FAILURE OR THE INTRODUCTION OF A COMPUTER VIRUS, OR
          OTHER TECHNICAL SABOTAGE, EVEN IF WE OR OUR AFFILIATES, OR THE
          EMPLOYEES OR REPRESENTATIVES THEREOF, ARE ADVISED OF THE POSSIBILITY
          OR LIKELIHOOD OF SUCH DAMAGES, LOSSES OR EXPENSES.
        </BodyText>
        <BodyText className={classes.bodyText}>
          <strong>System Requirements/Equipment:</strong> We use encryption to
          make your information unreadable as it passes over the Internet.
          Therefore, we strongly recommend that you use the latest version of
          your browser software for maximum security.
        </BodyText>
        <BodyText className={classes.bodyText}>
          <strong>Farmers® Privacy Policy:</strong> To view our Privacy Policy,
          go to{' '}
          <a href="https://www.farmers.com/privacy-statement/">
            https://www.farmers.com/privacy-statement/
          </a>
          .
        </BodyText>
        <BodyText className={classes.bodyText}>
          <strong>Storage of Information:</strong> Information stored on this
          site is subject to physical, electronic and procedural safeguards that
          comply with applicable regulatory standards to guard your nonpublic
          personal information. We authorize our employees and agents to get
          information about you only when they need it to do their work for us.
          We require companies working for us to protect information. They agree
          to use it only to provide services we ask them to perform for us.
        </BodyText>
        <BodyText className={classes.bodyText}>
          <strong>Refunds:</strong> If a policy change results in a premium
          refund due, we may deposit and/or refund the amount due to your Bank
          Account or Payment Card currently on file.
        </BodyText>
      </Container>
    </LayoutRoot>
  );
}

export default PaymentTermsOfUseView;
