import React, {
  useContext,
  createContext,
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import Button from '../components/Button';

import includes from 'lodash/includes';
import indexOf from 'lodash/indexOf';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import tail from 'lodash/tail';
import last from 'lodash/last';

import wlConfig from '../constants/wlConfig';

const {
  theme: {
    styles: {
      snackbarStyles: { actionButton },
    },
  },
} = wlConfig;

const SnackbarContext = createContext();

export const SNACKBAR_DELAY = 500;
export const SNACKBAR_DURATION = 5000;

export function useSnackbars() {
  const {
    handleSetVisibleSnackbars,
    setVisibleSnackbars,
    undoAction,
    setUndoAction,
    setBaseSnackbarPosition,
    snackbarConfig,
  } = useContext(SnackbarContext);

  const clearSnackbars = useCallback(() => {
    setVisibleSnackbars([]);
  }, [setVisibleSnackbars]);

  function addUndoAction(action) {
    if (!undoAction) {
      setUndoAction(action);
    }
  }

  const [prevBasePosition, setPrevBasePosition] = useState(null);
  const updateSnackbarBasePosition = useCallback(
    (pos) => {
      setBaseSnackbarPosition((prev) => {
        setPrevBasePosition(prev);
        return pos;
      });
    },
    [setBaseSnackbarPosition]
  );

  const resetSnackbarBasePosition = useCallback(() => {
    prevBasePosition && setBaseSnackbarPosition(prevBasePosition);
  }, [prevBasePosition, setBaseSnackbarPosition]);

  function addCustomSnackbar(config) {
    snackbarConfig.push(config);
  }

  return {
    handleSetVisibleSnackbars,
    clearSnackbars,
    addUndoAction,
    setUndoAction,
    setBaseSnackbarPosition,
    addCustomSnackbar,
    resetSnackbarBasePosition,
    updateSnackbarBasePosition,
  };
}

const useStyles = makeStyles((theme) => ({
  snackbar_root: ({ bottomPosition }) => {
    return {
      bottom: bottomPosition,
      padding: '0 8px',
      left: 0,
      right: 0,
      transform: 'initial',
      zIndex: 1000000,
      [theme.breakpoints.up('md')]: {
        justifyContent: 'left',
        maxWidth: 600,
        width: 'fit-content',
      },
      [theme.breakpoints.up('lg')]: {
        bottom: 16,
      },
    };
  },
  actionButton,
}));

export function SnackbarContextProvider({ children, snackbarConfig }) {
  const [visibleSnackbars, setVisibleSnackbars] = useState([]);
  const [undoAction, setUndoAction] = useState(null);
  const [snackbarBottomPositions, setSnackbarBottomPositions] = useState([]);
  const [baseSnackbarPosition, setBaseSnackbarPosition] = useState(16);

  const classes = useStyles({
    bottomPosition: baseSnackbarPosition,
  });

  useEffect(
    function determineSnackbarPositions() {
      const getPositions = (visibleSnackbars, snackbarPositions = []) => {
        if (!visibleSnackbars.length) {
          return snackbarPositions;
        } else if (!snackbarPositions.length) {
          return getPositions(tail(visibleSnackbars), [baseSnackbarPosition]);
        } else {
          const previousElPos = last(snackbarPositions);
          const previousElHeight = get(
            document.getElementById(
              `openSnackbar${snackbarPositions.length - 1}`
            ),
            'offsetHeight'
          );
          const padding = 20;
          const updatedSnackbarPositions = [
            ...snackbarPositions,
            previousElPos + previousElHeight + padding,
          ];
          return getPositions(tail(visibleSnackbars), updatedSnackbarPositions);
        }
      };
      if (visibleSnackbars.length > 1) {
        setSnackbarBottomPositions(getPositions(visibleSnackbars));
      }
    },
    [baseSnackbarPosition, visibleSnackbars]
  );

  const timeoutRef = useRef(null);

  const handleSetVisibleSnackbars = useCallback(
    (snackbars, opts = { isOverride: false }) => {
      if (opts.isOverride) {
        clearTimeout(timeoutRef.current);
        setVisibleSnackbars(snackbars);
      } else {
        forEach(snackbars, (snack, index) => {
          timeoutRef.current = setTimeout(() => {
            setVisibleSnackbars((currentList) => currentList.concat(snack));
            timeoutRef.current = setTimeout(() => {
              setVisibleSnackbars((currentList) => currentList.slice(1));
            }, SNACKBAR_DURATION + SNACKBAR_DELAY * index);
          }, SNACKBAR_DELAY * index);
        });
      }
    },
    []
  );

  function removeFirstSnackbar() {
    setVisibleSnackbars(visibleSnackbars.slice(1));
    setUndoAction(null);
    clearTimeout(timeoutRef.current);
  }

  const snackBarStore = useMemo(
    () => ({
      handleSetVisibleSnackbars,
      setVisibleSnackbars,
      undoAction,
      setUndoAction,
      setBaseSnackbarPosition,
      snackbarConfig,
    }),
    [handleSetVisibleSnackbars, snackbarConfig, undoAction]
  );

  return (
    <SnackbarContext.Provider value={snackBarStore}>
      {children}
      {snackbarConfig.map((config, index) => {
        const isOpen = !!includes(visibleSnackbars, config.id);
        const visibleSnackbarsIndex = indexOf(visibleSnackbars, config.id);
        return (
          <Snackbar
            key={`snackbar${config.id}${index}`}
            open={isOpen}
            id={
              isOpen ? `openSnackbar${visibleSnackbarsIndex}` : 'closedSnackbar'
            }
            onClose={removeFirstSnackbar}
            TransitionComponent={Fade}
            autoHideDuration={SNACKBAR_DURATION}
            disableWindowBlurListener={true}
            style={{
              bottom: snackbarBottomPositions[visibleSnackbarsIndex],
            }}
            ContentProps={{
              'data-testid': config.dataTestid,
            }}
            classes={{
              root: classes.snackbar_root,
            }}
            action={
              config.hasUndoAction &&
              !!undoAction && (
                <Button
                  color="secondary"
                  size="small"
                  data-testid="undo_button"
                  className={classes.actionButton}
                  onClick={() => {
                    undoAction();
                    removeFirstSnackbar();
                  }}
                >
                  Undo
                </Button>
              )
            }
            message={<Typography variant="body1">{config.message}</Typography>}
          />
        );
      })}
    </SnackbarContext.Provider>
  );
}
