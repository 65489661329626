export const COVERAGE_UPDATE_SNACKBARS = {
  COMPREHENSIVE_COLLISION_COVERAGE_UPDATE:
    'COMPREHENSIVE_COLLISION_COVERAGE_UPDATE',
  VEHICLE_LEVEL_COVERAGES_UPDATE: 'VEHICLE_LEVEL_COVERAGES_UPDATE',
  BODILY_INJURY_UPDATE: 'BODILY_INJURY_UPDATE',
  UIMBI_UPDATE: 'UIMBI_UPDATE',
  BODILY_INJURY_AND_UMBI_UPDATE: 'BODILY_INJURY_AND_UMBI_UPDATE',
  UMBI_UPDATE: 'UMBI_UPDATE',
  UMBI_AND_UIMBI_UPDATE: 'UMBI_AND_UIMBI_UPDATE',
  PROPERTY_DAMAGE_UPDATE: 'PROPERTY_DAMAGE_UPDATE',
  UMPD_UPDATE: 'UMPD_UPDATE',
  PROPERTY_DAMAGE_AND_UMPD_UPDATE: 'PROPERTY_DAMAGE_AND_UMPD_UPDATE',
  UMPD_AND_UIMPD_UPDATE: 'UMPD_AND_UIMPD_UPDATE',
  UIMPD_UPDATE: 'UIMPD_UPDATE',
  UPDATE_COVERAGES: 'UPDATE_COVERAGES',
};

export const coverageUpdateSnackbarConfig = [
  {
    id: COVERAGE_UPDATE_SNACKBARS.COMPREHENSIVE_COLLISION_COVERAGE_UPDATE,
    dataTestid: 'coverageSnackbar_COMPREHENSIVE_COLLISION_COVERAGE_UPDATE',
    message: 'Your Comprehensive & Collision Coverages have been updated.',
    hasUndoAction: true,
  },
  {
    id: COVERAGE_UPDATE_SNACKBARS.VEHICLE_LEVEL_COVERAGES_UPDATE,
    dataTestid: 'coverageSnackbar_VEHICLE_LEVEL_COVERAGES_UPDATE',
    message: 'Your Vehicle-level coverages have been updated.',
    hasUndoAction: true,
  },
  {
    id: COVERAGE_UPDATE_SNACKBARS.BODILY_INJURY_UPDATE,
    dataTestid: 'coverageSnackbar_BODILY_INJURY_UPDATE',
    message: 'Your Bodily Injury coverage has been updated.',
    hasUndoAction: true,
  },
  {
    id: COVERAGE_UPDATE_SNACKBARS.UIMBI_UPDATE,
    dataTestid: 'coverageSnackbar_UIMBI_UPDATE',
    message:
      'Your Underinsured Motorist Bodily Injury coverage has been updated.',
    hasUndoAction: true,
  },
  {
    id: COVERAGE_UPDATE_SNACKBARS.BODILY_INJURY_AND_UMBI_UPDATE,
    dataTestid: 'coverageSnackbar_BODILY_INJURY_AND_UMBI_UPDATE',
    message:
      'Your Bodily Injury and Uninsured Motorist Bodily Injury coverages have been updated.',
    hasUndoAction: true,
  },
  {
    id: COVERAGE_UPDATE_SNACKBARS.UMBI_UPDATE,
    dataTestid: 'coverageSnackbar_UMBI_UPDATE',
    message: 'Your Uninsured Motorist Bodily Injury coverage has been updated.',
    hasUndoAction: true,
  },
  {
    id: COVERAGE_UPDATE_SNACKBARS.UMBI_AND_UIMBI_UPDATE,
    dataTestid: 'coverageSnackbar_UMBI_AND_UIMBI_UPDATE',
    message:
      'Your Uninsured Motorist Bodily Injury and Underinsured Motorist Bodily Injury coverages have been updated.',
    hasUndoAction: true,
  },
  {
    id: COVERAGE_UPDATE_SNACKBARS.PROPERTY_DAMAGE_UPDATE,
    dataTestid: 'coverageSnackbar_PROPERTY_DAMAGE_UPDATE',
    message: 'Your Property Damage coverage has been updated.',
    hasUndoAction: true,
  },
  {
    id: COVERAGE_UPDATE_SNACKBARS.UMPD_UPDATE,
    dataTestid: 'coverageSnackbar_UMPD_UPDATE',
    message:
      'Your Uninsured Motorist Property Damage coverage has been updated.',
    hasUndoAction: true,
  },
  {
    id: COVERAGE_UPDATE_SNACKBARS.PROPERTY_DAMAGE_AND_UMPD_UPDATE,
    dataTestid: 'coverageSnackbar_PROPERTY_DAMAGE_AND_UMPD_UPDATE',
    message:
      'Your Property Damage and Uninsured Motorist Property Damage coverages have been updated.',
    hasUndoAction: true,
  },
  {
    id: COVERAGE_UPDATE_SNACKBARS.UMPD_AND_UIMPD_UPDATE,
    dataTestid: 'coverageSnackbar_UMPD_AND_UIMPD_UPDATE',
    message:
      'Your Uninsured Motorist Property Damage and Underinsured Motorist Property Damage coverages have been updated.',
    hasUndoAction: true,
  },
  {
    id: COVERAGE_UPDATE_SNACKBARS.UIMPD_UPDATE,
    dataTestid: 'coverageSnackbar_UIMPD_UPDATE',
    message:
      'Your Underinsured Motorist Property Damage coverage has been updated.',
    hasUndoAction: true,
  },
  {
    id: COVERAGE_UPDATE_SNACKBARS.UPDATE_COVERAGES,
    dataTestid: 'coverageSnackbar_UPDATE',
    message: 'Your quote has been updated.',
  },
];
