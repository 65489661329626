import { PATHNAMES } from './paths';
import wlConfig from './wlConfig';

const {
  navigationItems: { drawerLinks, footerLinks, topBarLinks },
} = wlConfig;

export const DRAWER_LINKS = [
  ...(drawerLinks.flags.hasGetAQuote
    ? [
        {
          name: 'Get a quote',
          href: `/enroll${PATHNAMES.START}`,
          testid: 'get_a_quote',
          requiresAnonymous: true,
        },
      ]
    : []),
  {
    name: 'My account',
    href: PATHNAMES.MY_ACCOUNT,
    testid: 'my_account',
    requiresLogin: true,
    ...drawerLinks.myAccount,
  },
  {
    name: 'Claims',
    href: PATHNAMES.CLAIMS,
    testid: 'claims',
    ...drawerLinks.claims,
  },
  {
    name: 'FAQ',
    href: PATHNAMES.FAQ,
    testid: 'faq',
    requiresAnonymous: true,
    ...drawerLinks.faq,
  },
  ...(drawerLinks.flags.hasAboutUs
    ? [
        {
          name: 'About us',
          href: PATHNAMES.ABOUT_US,
          testid: 'about_us',
          requiresAnonymous: true,
          ...drawerLinks.aboutUs,
        },
      ]
    : []),
  ...(drawerLinks.flags.hasContactUs
    ? [
        {
          name: 'Contact us',
          href: PATHNAMES.CONTACT_US,
          testid: 'contact_us',
          requiresAnonymous: true,
          isDifferentDomain: true,
        },
      ]
    : []),
];

export const DRAWER_ACCOUNT_ACTIONS = [
  {
    name: 'Log in',
    href: PATHNAMES.LOG_IN,
    testid: 'log_in',
    requiresAnonymous: true,
  },
  {
    name: 'Logout',
    href: PATHNAMES.LOG_OUT,
    testid: 'log_out',
    requiresLogin: true,
    hasDivider: true,
  },
];

export const TOP_BAR_LINKS = [
  {
    name: 'My account',
    href: PATHNAMES.MY_ACCOUNT,
    testid: 'my_account',
    requiresLogin: true,
    ...topBarLinks.myAccount,
  },
  ...(topBarLinks.flags.hasGetAQuote
    ? [
        {
          name: 'Get a quote',
          href: `/enroll${PATHNAMES.START}`,
          testid: 'get_a_quote',
          requiresAnonymous: true,
        },
      ]
    : []),
  {
    name: 'Claims',
    href: PATHNAMES.CLAIMS,
    testid: 'claims',
    ...topBarLinks.claims,
  },
  ...(topBarLinks.flags.hasFaq
    ? [
        {
          name: 'FAQ',
          href: PATHNAMES.FAQ,
          testid: 'faq',
          requiresAnonymous: true,
          ...topBarLinks.faq,
        },
      ]
    : []),
];

export const TOP_BAR_ACCOUNT_ACTIONS = [
  {
    name: 'Log in',
    href: PATHNAMES.LOG_IN,
    testid: 'log_in',
    requiresAnonymous: true,
    ...topBarLinks.login,
  },
  {
    name: 'Log out',
    href: PATHNAMES.LOG_OUT,
    testid: 'log_out',
    requiresLogin: true,
    hasDivider: true,
    ...topBarLinks.logout,
  },
];

export const FOOTER_NAV_ITEMS = [
  {
    label: 'Contact us',
    url: PATHNAMES.CONTACT_US,
    testid: 'contact_us',
    isDifferentDomain: true,
  },
  {
    label: 'About us',
    url: PATHNAMES.ABOUT_US,
    testid: 'about_us',
    ...footerLinks.aboutUs,
  },
  {
    label: 'Claims',
    url: PATHNAMES.CLAIMS,
    testid: 'claims',
    ...footerLinks.claims,
  },
  {
    label: 'Terms of use',
    url: PATHNAMES.TERMS_OF_USE,
    testid: 'terms_of_use',
    ...footerLinks.termsOfUse,
  },
  {
    label: 'Privacy policy',
    url: PATHNAMES.PRIVACY_POLICY,
    testid: 'privacy_policy',
    ...footerLinks.privacy,
  },
  {
    label: 'Do not sell my information',
    url: `${PATHNAMES.PRIVACY_POLICY}#do-not-sell-my-personal-information`,
    testid: 'do_not_sell_my_information',
    ...footerLinks.privacy,
  },
  {
    label: 'FAQ',
    url: PATHNAMES.FAQ,
    testid: 'faq',
  },
];
