import React, { useEffect, lazy, Suspense } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from '../../constants/theme';
import wlConfig from '../../constants/wlConfig';
import {
  persistWhitelist,
  persistedStoreKey,
  sessionStorageWhitelist,
} from '../../store/reducers';
import { GlobalProvider } from '../../store';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import PersistGate from '../../components/PersistGate';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { ErrorContextProvider } from '../ErrorHandler';
import QueryClientContainer from '../QueryClientContainer';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorView from '../../views/ErrorView';
import AboutUsView from '../../views/AboutUsView';
import FAQView from '../../views/FAQView';
import TermsOfUseView from '../../views/TermsOfUseView';
import PaymentsTermsOfUseView from '../../views/PaymentTermsOfUseView';
import { NavBlockerContextProvider } from '../../services/useNavBlocker';
import { StagedPolicyContextProvider } from '../../services/useStagedPolicy';
import { SessionTimeoutContextProvider } from '../../services/useSessionTimeout';
import LoadingView from '../../views/LoadingView';
import ClaimsContainer from '../ClaimsContainer';
import { Security, LoginCallback } from '@okta/okta-react';

import { SnackbarContextProvider } from '../../services/useSnackbars';
import {
  EnrollmentFlowSnackbarConfig,
  PurchaseFlowSnackbarConfig,
  MyAccountSnackbarConfig,
  SelfServiceSnackbarConfig,
} from '../../constants/snackbars';
import { FeatureFlagProvider } from '../../services/useFeatureFlags';

import { PRIVACY_POLICY_URL } from '../../constants/defaultUrls';

const EnrollmentContainer = lazy(() => import('../EnrollmentContainer'));
const LayoutRoot = lazy(() => import('../../components/LayoutRoot'));
const LandingHomeView = lazy(() => import('../../views/LandingHomeView'));
const LoginView = lazy(() => import('../../views/LoginView'));
const LogoutView = lazy(() => import('../../views/LogoutView'));
const MFAView = lazy(() => import('../../views/MFAView'));
const MyAccountView = lazy(() => import('../../views/MyAccountView'));
const PolicyContainer = lazy(() => import('../PolicyContainer'));
const ProgressBar = lazy(() => import('../../components/ProgressBar'));
const QuoteContainer = lazy(() => import('../QuoteContainer'));
const StartScreensContainer = lazy(() => import('../StartScreensContainer'));

const {
  featureFlags: {
    isAccountManagementEnabled,
    isPurchaseFlowEnabled,
    isSelfServiceEnabled,
    isQuoteCreationEnabled,
  },
  redirectUrls: {
    aboutUsRedirectUrl,
    indexRedirectUrl,
    loggedOutRedirectUrl,
    mfaSuccessRedirectUrl,
    privacyRedirectUrl,
    termsRedirectUrl,
  },
  localforageDbName,
  tenantDisplayName,
} = wlConfig;

function App() {
  const location = useLocation();
  const match = useRouteMatch();

  useEffect(
    function handleRouteChange() {
      window.analytics.page();
      window.scrollTo(0, 0);
      try {
        window.zE && window.zE('webWidget', 'updatePath');
      } catch (err) {
        console.info(err);
      }
    },
    [location]
  );

  const isDevOrQAEnvironment =
    process.env.REACT_APP_ENV === 'development' ||
    process.env.REACT_APP_ENV === 'qa';
  const isDevOrQAEnvironmentAndNotCypress =
    isDevOrQAEnvironment && !window.Cypress;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <PersistGate
          persistWhitelist={persistWhitelist}
          persistedStoreKey={persistedStoreKey}
          sessionStorageWhitelist={sessionStorageWhitelist}
          dbName={localforageDbName}
        >
          {(initialStore) => {
            return (
              <GlobalProvider initialStore={initialStore}>
                <QueryClientContainer>
                  <ErrorContextProvider>
                    <ErrorBoundary>
                      <NavBlockerContextProvider>
                        <Security
                          issuer={process.env.REACT_APP_OKTA_ISSUER}
                          clientId={process.env.REACT_APP_OKTA_CLIENT_ID}
                          redirectUri={`${window.location.origin}${process.env.REACT_APP_OKTA_REDIRECT_URI}`}
                          responseType={['id_token', 'token']}
                          onSessionExpired={() => {}}
                          pkce={false}
                          cookies={{
                            secure: process.env.REACT_APP_ENV !== 'development',
                          }}
                        >
                          <FeatureFlagProvider>
                            <Suspense fallback={<LoadingView />}>
                              <Switch>
                                {isPurchaseFlowEnabled && (
                                  <Route
                                    exact
                                    path="/"
                                    render={(routerProps) => {
                                      if (!!indexRedirectUrl) {
                                        window.location.href = indexRedirectUrl;
                                      } else {
                                        return (
                                          <LandingHomeView {...routerProps} />
                                        );
                                      }
                                    }}
                                  />
                                )}
                                {isSelfServiceEnabled && [
                                  (isAccountManagementEnabled ||
                                    isDevOrQAEnvironmentAndNotCypress) && (
                                    <Route
                                      exact
                                      path="/login"
                                      key="/login"
                                      render={(routerProps) => (
                                        <LoginView
                                          {...{
                                            ...routerProps,
                                            tenantDisplayName,
                                          }}
                                        />
                                      )}
                                    />
                                  ),
                                  <Route
                                    exact
                                    path="/logout"
                                    key="/logout"
                                    component={LogoutView}
                                  />,
                                ]}
                                <Route
                                  exact
                                  path="/about"
                                  render={(routerProps) => {
                                    if (!!aboutUsRedirectUrl) {
                                      window.location.href = aboutUsRedirectUrl;
                                    } else {
                                      return <AboutUsView {...routerProps} />;
                                    }
                                  }}
                                />
                                <Route exact path="/faq" component={FAQView} />
                                <Route
                                  exact
                                  path="/privacy"
                                  render={() => {
                                    if (!!privacyRedirectUrl) {
                                      window.location.href = privacyRedirectUrl;
                                    } else {
                                      window.location.href = PRIVACY_POLICY_URL;
                                    }
                                  }}
                                />
                                <Route
                                  path="/claims"
                                  component={ClaimsContainer}
                                />
                                {isPurchaseFlowEnabled && [
                                  isQuoteCreationEnabled && (
                                    <Route
                                      key="/enroll"
                                      path="/enroll"
                                      render={(routerProps) => (
                                        <LayoutRoot withFooter>
                                          <ProgressBar
                                            match={match}
                                            location={location}
                                          />
                                          <SnackbarContextProvider
                                            snackbarConfig={
                                              EnrollmentFlowSnackbarConfig
                                            }
                                          >
                                            <EnrollmentContainer
                                              isQuoteCreationEnabled={
                                                isQuoteCreationEnabled
                                              }
                                              {...routerProps}
                                            />
                                          </SnackbarContextProvider>
                                        </LayoutRoot>
                                      )}
                                    />
                                  ),
                                  <Route
                                    key="/quotes/:quoteNumber"
                                    path="/quotes/:quoteNumber"
                                    render={(routerProps) => (
                                      <SessionTimeoutContextProvider>
                                        <SnackbarContextProvider
                                          snackbarConfig={
                                            PurchaseFlowSnackbarConfig
                                          }
                                        >
                                          <QuoteContainer {...routerProps} />
                                        </SnackbarContextProvider>
                                      </SessionTimeoutContextProvider>
                                    )}
                                  />,
                                  <Route
                                    exact
                                    key="/verify"
                                    path="/verify"
                                    render={(routerProps) => (
                                      <MFAView
                                        {...routerProps}
                                        isSelfServiceEnabled={
                                          isSelfServiceEnabled
                                        }
                                        mfaSuccessRedirectUrl={
                                          mfaSuccessRedirectUrl
                                        }
                                      />
                                    )}
                                  />,
                                ]}
                                {isSelfServiceEnabled && [
                                  (isAccountManagementEnabled ||
                                    isDevOrQAEnvironmentAndNotCypress) && (
                                    <Route
                                      exact
                                      key="/my-account"
                                      path="/my-account"
                                      component={(routerProps) => (
                                        <SnackbarContextProvider
                                          snackbarConfig={
                                            MyAccountSnackbarConfig
                                          }
                                        >
                                          <MyAccountView {...routerProps} />
                                        </SnackbarContextProvider>
                                      )}
                                    />
                                  ),
                                  <Route
                                    key="/policies/:policyId"
                                    path="/policies/:policyId"
                                    render={(routeProps) => (
                                      <SnackbarContextProvider
                                        snackbarConfig={
                                          SelfServiceSnackbarConfig
                                        }
                                      >
                                        <StagedPolicyContextProvider>
                                          <PolicyContainer {...routeProps} />
                                        </StagedPolicyContextProvider>
                                      </SnackbarContextProvider>
                                    )}
                                  />,
                                ]}
                                <Route path="/error" component={ErrorView} />
                                <Route
                                  exact
                                  path="/terms-of-use"
                                  render={(routerProps) => {
                                    if (!!termsRedirectUrl) {
                                      window.location.href = termsRedirectUrl;
                                    } else {
                                      return (
                                        <TermsOfUseView {...routerProps} />
                                      );
                                    }
                                  }}
                                />
                                <Route
                                  exact
                                  path="/payment-terms-of-use"
                                  render={(routerProps) => {
                                    return (
                                      <PaymentsTermsOfUseView
                                        {...routerProps}
                                      />
                                    );
                                  }}
                                />
                                <Route
                                  path="/implicit/callback"
                                  component={LoginCallback}
                                />
                                {isDevOrQAEnvironment && (
                                  <Route
                                    path="/_/"
                                    component={StartScreensContainer}
                                  />
                                )}
                                {!isPurchaseFlowEnabled &&
                                  !isAccountManagementEnabled && (
                                    <Route
                                      component={() => {
                                        window.location.href = loggedOutRedirectUrl;
                                        return null;
                                      }}
                                    />
                                  )}
                                <Redirect
                                  {...(isPurchaseFlowEnabled
                                    ? {
                                        to: '/',
                                      }
                                    : {
                                        to: '/my-account',
                                      })}
                                />
                              </Switch>
                            </Suspense>
                          </FeatureFlagProvider>
                        </Security>
                      </NavBlockerContextProvider>
                    </ErrorBoundary>
                  </ErrorContextProvider>
                </QueryClientContainer>
              </GlobalProvider>
            );
          }}
        </PersistGate>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

export default App;
