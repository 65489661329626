import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import get from 'lodash/get';
import startsWith from 'lodash/startsWith';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import wlConfig from '../../constants/wlConfig';

const {
  theme: {
    styles: {
      iconCardStyles: {
        iconCardBody: baseIconCardBody,
        iconCardButtonLink,
        iconCardButtonLinkLabel,
        iconCardCaption,
        iconCardHeader: baseIconCardHeader,
        iconColors: {
          modal: modalIconBackgroundColor,
          nonModal: nonModalIconBackgroundColor,
          icon: iconColor,
        },
      },
    },
  },
} = wlConfig;

const propTypes = {
  buttonProps: PropTypes.object,
  isModal: PropTypes.bool,
  onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  buttonText: PropTypes.string,
  header: PropTypes.string,
  body: PropTypes.string,
  caption: PropTypes.string,
  IconComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  IconComponentProps: PropTypes.object,
  CustomContent: PropTypes.element,
  isSubmitting: PropTypes.bool,
  isAvatar: PropTypes.bool,
  children: PropTypes.element,
};

const defaultProps = {
  buttonProps: {},
  isModal: false,
  onClick: () => {},
  buttonText: '',
  header: '',
  body: '',
  caption: '',
  IconComponent: null,
  IconComponentProps: {},
  CustomContent: null,
  isSubmitting: false,
  isAvatar: true,
  children: null,
};

const useStyles = makeStyles((theme) => ({
  iconCard_root: {
    marginBottom: 16,
    boxShadow:
      '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14)',
    borderRadius: theme.shape.borderRadius,
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.up('md')]: {
      marginBottom: 24,
      padding: 24,
    },
  },
  iconCard_avatar: (props) => ({
    backgroundColor: props.isModal
      ? modalIconBackgroundColor
      : nonModalIconBackgroundColor,
  }),
  iconCard_icon: {
    color: iconColor,
    width: 24,
    height: 24,
  },
  iconCard_text: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 16,
    overflowWrap: 'break-word',
    maxWidth: '75%',
  },
  iconCard_buttonLink: {
    alignSelf: 'flex-end',
    marginTop: 16,
    ...iconCardButtonLink,
  },
  iconCard_buttonLinkLabel: {
    ...iconCardButtonLinkLabel,
  },
  iconCard_stringButtonLink: {
    textDecoration: 'none',
  },
  iconCard_stringButtonPrimary: {
    color: iconCardButtonLink.color,
  },
  loader: {
    color: theme.palette.tenant.primaryMid,
  },
  iconCardBody: {
    ...baseIconCardBody,
    '&$mobile': {
      fontSize: 20,
    },
  },
  iconCardCaption,
  iconCardHeader: {
    ...baseIconCardHeader,
  },
  mobile: {},
}));

function IconCardButton({
  onClick,
  children,
  buttonProps,
  isSubmitting,
  classes,
  setShowLoader,
}) {
  if (typeof onClick !== 'string') {
    return (
      <Button
        color="primary"
        size="small"
        onClick={(e) => {
          onClick(e);
          setShowLoader(true);
        }}
        disabled={isSubmitting}
        {...buttonProps}
        className={classnames(
          classes.iconCard_buttonLink,
          buttonProps.className
        )}
        classes={{ label: classes.iconCard_buttonLinkLabel }}
      >
        {children}
      </Button>
    );
  }

  if (startsWith(onClick, 'http')) {
    return (
      <Button
        color="primary"
        size="small"
        disabled={isSubmitting}
        {...buttonProps}
        className={classnames(
          classes.iconCard_buttonLink,
          buttonProps.className
        )}
        href={onClick}
        target="_blank"
      >
        {children}
      </Button>
    );
  }

  return (
    <Link
      to={onClick}
      className={classnames(
        classes.iconCard_buttonLink,
        buttonProps.className,
        classes.iconCard_stringButtonLink
      )}
      {...buttonProps}
    >
      <Button
        color="primary"
        size="small"
        disabled={isSubmitting}
        classes={{
          textPrimary: classes.iconCard_stringButtonPrimary,
        }}
      >
        {children}
      </Button>
    </Link>
  );
}

function IconCard({
  isModal,
  onClick,
  buttonText,
  header,
  body,
  caption,
  CustomContent,
  IconComponent,
  IconComponentProps,
  buttonProps,
  isSubmitting,
  isAvatar,
  children,
  dataTestId,
}) {
  const classes = useStyles({ isModal });
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    if (!isSubmitting) {
      setShowLoader(false);
    }
  }, [isSubmitting]);

  return (
    <Paper className={classes.iconCard_root}>
      {CustomContent ? (
        CustomContent
      ) : (
        <Grid
          container
          wrap="nowrap"
          spacing={2}
          data-testid={dataTestId || 'default_iconCard'}
        >
          {IconComponent && (
            <Grid item>
              {isAvatar ? (
                <Avatar
                  className={
                    get(IconComponentProps, 'classes.iconCard_avatar') ||
                    classes.iconCard_avatar
                  }
                >
                  <IconComponent className={classes.iconCard_icon} />
                </Avatar>
              ) : (
                <IconComponent
                  className={
                    get(IconComponentProps, 'classes.iconCard_icon') ||
                    classes.iconCard_icon
                  }
                />
              )}
            </Grid>
          )}
          <Grid item xs className={classes.iconCard_text}>
            {header && (
              <Typography
                variant="overline"
                gutterBottom
                className={classes.iconCardHeader}
              >
                {header}
              </Typography>
            )}
            {body && (
              <Typography
                variant="h5"
                className={classnames(classes.iconCardBody, {
                  [classes.mobile]: isMobile,
                })}
              >
                {body}
              </Typography>
            )}
            {caption && (
              <Typography
                variant="subtitle2"
                className={classes.iconCardCaption}
              >
                {caption}
              </Typography>
            )}
          </Grid>
        </Grid>
      )}
      {children}
      {buttonText && (
        <IconCardButton
          {...{
            buttonProps,
            classes,
            setShowLoader,
            onClick,
            isSubmitting,
          }}
        >
          {showLoader && isSubmitting ? (
            <CircularProgress
              data-testid="icon_button_spinner"
              size={'30px'}
              classes={{ colorPrimary: classes.loader }}
            />
          ) : (
            buttonText
          )}
        </IconCardButton>
      )}
    </Paper>
  );
}

IconCard.propTypes = propTypes;
IconCard.defaultProps = defaultProps;

export default IconCard;
