import each from 'lodash/each';
import get from 'lodash/get';
import isPlainObject from 'lodash/isPlainObject';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import * as Sentry from '@sentry/browser';
import wlConfig from '../constants/wlConfig';

const { tenantDisplayName } = wlConfig;

function logException(ex, context) {
  // XXX: If test env, stringify `ex` to circumvent react-testing-library bug
  // https://github.com/testing-library/react-testing-library/issues/442
  if (process.env.NODE_ENV !== 'test') {
    window.console && window.console.error && window.console.error(ex);
  } else {
    window.console &&
      window.console.error &&
      window.console.error(ex.toString());
  }

  return new Promise((resolve) => {
    Sentry.withScope((scope) => {
      scope.setLevel('error');
      each(context, (val, key) => {
        scope.setExtra(key, val);
      });
      if (get(ex, '_hrq')) {
        scope.setTag('hrq', ex._hrq);
      }

      scope.setTag('tenant', tenantDisplayName);

      if (ex instanceof Error) {
        resolve(Sentry.captureException(ex));
      } else if (isPlainObject(ex) && (ex.message || ex.code)) {
        resolve(Sentry.captureMessage(`[${ex.code}] ${ex.message}`));
      } else if (isPlainObject(ex) && ex.errors && isArray(ex.errors)) {
        scope.setExtra('errors', ex.errors);
        resolve(Sentry.captureException(ex));
      } else if (isString(ex)) {
        resolve(Sentry.captureMessage(ex));
      }
    });
  });
}

export default logException;
