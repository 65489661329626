import { createMuiTheme } from '@material-ui/core/styles';
import wlConfig from './wlConfig';
import get from 'lodash/get';
import merge from 'lodash/merge';

const { colors, fonts, typography, overrides, shape } = wlConfig.theme;

const defaultFonts = [
  ...fonts.defaultFonts.names,
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  '"Roboto"',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',');

const headerFonts = fonts.headerFonts.names.join(',');

const defaultWeight = fonts.defaultFonts.weight;
const headerWeight = fonts.headerFonts.weight;
const secondaryHeaderWeight = get(fonts, 'secondaryHeaderFonts.weight');

// Build theme

const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.primary,
      light: colors.primaryLight,
    },
    secondary: {
      light: colors.primaryMid,
      main: colors.primaryMid,
      dark: colors.primaryMid,
    },
    error: {
      main: colors.accent,
    },
    divider: colors.mutedMid,
    text: {
      primary: colors.defaultText,
    },
    background: {
      paper: colors.white,
      default: colors.whiteDark,
    },
    tenant: colors,
  },
  overrides: merge(
    {},
    {
      MuiButton: {
        root: {
          height: 40,
        },
        sizeSmall: {
          padding: '4px 12px 0px',
        },
      },
      MuiCssBaseline: {
        '@global': {
          '@font-face': fonts.fontFaceStyles,
          html: {
            height: '100%',
          },
          body: {
            height: '100%',
          },
          '#root': {
            height: '100%',
          },
          ...(process.env.REACT_APP_ZENDESK_CHAT_KEY && {
            '#launcher': {
              '@media (max-width:1279px)': {
                bottom: '60px !important',
              },
            },
          }),
        },
      },
      MuiExpansionPanel: {
        root: {
          borderRadius: 0,
          borderTop: `1px solid ${colors.mutedLight}`,
          borderBottom: 'none',
          boxShadow: 'none',
          padding: '8px 0',
          '&:before': {
            backgroundColor: colors.mutedLight,
          },
        },
      },
      MuiExpansionPanelDetails: {
        root: {
          padding: 0,
        },
      },
      MuiExpansionPanelSummary: {
        root: {
          padding: 0,
        },
        content: {
          margin: 0,
        },
      },
      MuiFilledInput: {
        root: {
          backgroundColor: colors.white,
          border: `1px solid ${colors.mutedLight}`,
          borderBottom: 'none',
          '&:hover': {
            backgroundColor: colors.whiteDark,
          },
          '&$focused': {
            backgroundColor: colors.white,
          },
          '@media (hover:none)': {
            '&&:hover': {
              backgroundColor: colors.white,
            },
          },
        },
        adornedStart: {
          paddingLeft: 5,
        },
        input: {
          fontSize: 18,
          paddingLeft: 16,
        },
        inputMarginDense: {
          paddingTop: 26,
          paddingBottom: 8,
        },
        underline: {
          color: colors.primary,
          '&:hover:before': {
            borderBottom: `1px solid ${colors.primary}`,
          },
        },
      },
      MuiFormControl: {
        marginDense: {
          marginBottom: 0,
          marginTop: 16,
        },
      },
      MuiIconButton: {
        root: {
          color: colors.mutedDark,
        },
      },
      MuiInputBase: {
        input: {
          color: colors.defaultText,
          '&::placeholder': {
            color: colors.mutedDark,
          },
          '&&::placeholder': {
            color: colors.mutedDark,
          },
        },
      },
      MuiInputLabel: {
        root: {
          color: colors.mutedDark,
          fontSize: 14,
        },
        filled: {
          '&&$marginDense': {
            transform: 'translate(16px, 22px) scale(1)',
          },
          '&&$shrink$marginDense': {
            transform: 'translate(16px, 10px) scale(0.857)',
          },
        },
      },
      MuiPaper: {
        root: {
          padding: '24px 16px',
        },
      },
      MuiListItem: {
        root: {
          '&&$selected': {
            backgroundColor: colors.mutedLight,
          },
        },
      },
    },
    overrides
  ),
  shape,
  typography: merge(
    {},
    {
      fontFamily: defaultFonts,
      fontSize: 16,
      h1: {
        fontFamily: defaultFonts,
        fontWeight: defaultWeight,
        color: colors.primary,
        textTransform: 'uppercase',
        fontSize: 40,
        lineHeight: 1.2,
        letterSpacing: '-1.25px',
        '@media (min-width:600px)': {
          fontSize: 48,
          lineHeight: 1.17,
          letterSpacing: '-1.5px',
        },
      },
      h2: {
        fontWeight: headerWeight,
        fontFamily: headerFonts,
        fontSize: 32,
        lineHeight: 1.25,
        letterSpacing: '-0.3px',
        '@media (min-width:600px)': {
          fontSize: 40,
          lineHeight: 1.2,
          letterSpacing: '-0.5px',
        },
      },
      h3: {
        fontWeight: secondaryHeaderWeight || headerWeight,
        fontFamily: headerFonts,
        fontSize: 24,
        lineHeight: 1.33,
        letterSpacing: 'normal',
        '@media (min-width:600px)': {
          fontSize: 32,
          lineHeight: 1.25,
        },
      },
      h4: {
        fontWeight: headerWeight,
        fontFamily: headerFonts,
        fontSize: 20,
        lineHeight: 1.2,
        letterSpacing: '0.21px',
        '@media (min-width:600px)': {
          fontSize: 24,
          lineHeight: 1.33,
          letterSpacing: '0.25px',
        },
      },
      h5: {
        fontWeight: headerWeight,
        fontFamily: headerFonts,
        fontSize: 16,
        '@media (min-width:600px)': {
          fontSize: 20,
          lineHeight: 1.2,
          letterSpacing: 'normal',
        },
      },
      h6: {
        fontWeight: secondaryHeaderWeight || headerWeight,
        fontFamily: headerFonts,
        fontSize: 20,
        lineHeight: 1.2,
        letterSpacing: '0.15px',
      },
      subtitle1: {
        fontFamily: defaultFonts,
        fontWeight: defaultWeight,
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: '0.15px',
      },
      subtitle2: {
        fontWeight: headerWeight,
        fontFamily: headerFonts,
        fontSize: 14,
        lineHeight: 1.71,
        letterSpacing: '0.1px',
      },
      body1: {
        fontFamily: defaultFonts,
        fontWeight: 'normal',
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: '0.5px',
      },
      body2: {
        fontFamily: defaultFonts,
        fontWeight: 'normal',
        fontSize: 14,
        lineHeight: 1.43,
        letterSpacing: '0.25px',
      },
      button: {
        fontFamily: headerFonts,
        fontWeight: headerWeight,
        fontSize: 18,
        lineHeight: 1.9,
        letterSpacing: '1.25px',
      },
      caption: {
        fontFamily: defaultFonts,
        fontWeight: defaultWeight,
        fontSize: 12,
        lineHeight: 1.33,
        letterSpacing: '0.4px',
      },
      overline: {
        fontFamily: defaultFonts,
        fontWeight: defaultWeight,
        fontSize: 10,
        lineHeight: 1.6,
        letterSpacing: '1.5px',
      },
    },
    typography
  ),
});

export default theme;
