import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LayoutRoot from '../../components/LayoutRoot/LayoutRoot';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import PhoneIcon from '@material-ui/icons/Phone';
import Avatar from '@material-ui/core/Avatar';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  body: {
    flex: '1 0 auto',
    width: '100%',
    paddingTop: 150,
    paddingBottom: 16,
    color: theme.palette.tenant.primary,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatar: {
    backgroundColor: theme.palette.tenant.primary,
    width: 48,
    height: 48,
    marginBottom: 24,
  },
  icon: {
    color: theme.palette.tenant.white,
  },
}));

function NewClaimView() {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return (
    <LayoutRoot witFooter withTopNavigation>
      <Container className={classes.body} data-testid="newClaimMessage">
        <Avatar className={classes.avatar}>
          <PhoneIcon className={classes.icon} />
        </Avatar>

        <Typography variant={isMobile ? 'h3' : 'h4'} align="center">
          We'll be happy to help you.
          {isMobile ? <br /> : ' '}
          Please call us at{isMobile ? <br /> : ' '}
          <a style={{ color: 'inherit' }} href="tel:1-800-435-7764">
            1-800-435-7764
          </a>
          .
        </Typography>
      </Container>
    </LayoutRoot>
  );
}

export default NewClaimView;
