import merge from 'lodash/merge';
import { PATHNAMES } from './paths';
import quoteLoadingIcon from '../assets/farmers-direct/img/icon-car.svg';
import bristolWestIcon from '../assets/farmers-direct/img/icon-phone.svg';
import errorIcon from '../assets/farmers-direct/img/icon-exclamation.svg';
import towTruckIcon from '../assets/img/icon-towtruck.svg';

const TENANT_DISPLAY_NAME = 'FarmersDirect';

const colors = {
  accent: '#E01933',
  accentDark: '#B30032',
  defaultText: '#000000',
  mutedDark: '#585858',
  mutedLight: '#E7E7E7',
  mutedMid: '#B6B6B6',
  offWhite: '#FAFAFA',
  primary: '#003087',
  primaryHighlight: '#158FEF',
  primaryLight: '#B4C4EA',
  primaryMid: '#0073CF',
  success: '#55B800',
  warning: '#FFAE00',
  white: '#fff',
  whiteDark: '#f7f7f7',
};

const defaultConfig = {
  accountInfo: {
    hasCreateAccountCta: false,
    serviceLogoPath: null,
    serviceName: null,
  },
  api: {
    space: 'farmers_direct',
    verticalCode: 'farmers_direct_auto',
  },
  checkboxes: {
    displayFormLabelFocused: true,
  },
  coverageCardLabels: {
    subtitle: 'Applies to all drivers and vehicles on your policy',
  },
  coverageQuoteViewLabels: {
    ctaText: 'Next: Review & Pay',
    headerLabel: 'Review your coverage',
    policyLevelSubHeaderLabel:
      'Applies to all drivers and vehicles on your policy',
    subHeaderLabel:
      'We’ve tried to match your current policy coverage limits and deductibles. Please review and make any changes.',
  },
  defaultValues: {
    hasViolation: null,
    quoteCoveragesByOwnershipStatus: [],
  },
  disabledLienholderInfoByOwnershipStatus: [],
  discountCardsLabel: 'Discounts applied',
  driverQuoteViewLabel: 'Relationship to applicant',
  errorConfig: {
    bristolWest: null,
  },
  faqConfig: [],
  featureFlags: {
    canEditPrimaryVehicleOwnershipStatus: true,
    checkoutLineItemBlacklist: [],
    hasRenewalBanner: false,
    hasCCPABanner: false,
    isAccountManagementEnabled: true,
    isCheckoutLoadingViewEnabled: true,
    isPrefillAccountInfo: false,
    isPurchaseFlowEnabled: true,
    isQuoteCreationEnabled: true,
    isSelfServiceEnabled: true,
    showNonPayModalOnLoad: false,
  },
  footerConfig: {
    copyrightCopy: 'Farmers',
    copyrightDisclaimer: null,
    footerCopyText: null,
    hasBuiltOnSure: false,
    hasFooterNavItems: true,
    hasSocialListItems: {
      facebook: {
        url: 'https://www.facebook.com/FarmersInsurance',
      },
      instagram: {
        url: 'https://www.instagram.com/wearefarmers/',
      },
      linkedin: {
        url: 'https://www.linkedin.com/company/farmers-insurance/',
      },
      twitter: {
        url: 'https://twitter.com/wearefarmers',
      },
    },
    showRightsReserved: true,
  },
  icons: {
    bristolWestIcon,
    errorIcon,
    quoteLoadingIcon,
    towTruckIcon,
  },
  infoIconsConfig: {
    policyEffectiveDate: {},
  },
  layoutRoot: {
    alwaysShowsAccountActions: true,
  },
  localforageDbName: 'default_auto',
  logos: {
    footerLogo: null,
    logoPrimary: null,
  },
  navigationItems: {
    drawerLinks: {
      aboutUs: {},
      claims: {},
      faq: {
        name: 'FAQ',
        requiresAnonymous: true,
      },
      flags: {
        hasAboutUs: true,
        hasContactUs: true,
        hasGetAQuote: true,
        hasStartNewPolicy: false,
      },
      myAccount: {},
    },
    footerLinks: {
      aboutUs: {},
      claims: {},
      privacy: {},
      termsOfUse: {},
    },
    topBarLinks: {
      claims: {},
      faq: {
        name: 'FAQ',
        requiresAnonymous: true,
      },
      flags: {
        hasFaq: false,
        hasGetAQuote: true,
        hasStartNewPolicy: false,
      },
      login: {
        buttonVariant: 'text',
      },
      logout: {
        buttonVariant: 'text',
      },
      myAccount: {},
    },
  },
  policyCheckoutView: {
    billingTitle: 'Billing details',
  },
  policyView: {
    hasReviewChangesCopy: false,
    overviewTitle: 'Policy Overview',
  },
  primaryVehicleCoverageRulesByOwnershipStatus: [],
  primaryVehicleDataSource: '',
  quoteExpirationCondition: {
    maxElapsedTimeMinutes: 30,
    onConfirmDestination: `/enroll${PATHNAMES.START}`,
    timestampKey: 'updated_at',
  },
  quoteLoadingCopy: 'One moment while we do some research...',
  redirectUrls: {
    aboutUsRedirectUrl: '',
    indexRedirectUrl: '',
    loggedOutRedirectUrl: '',
    logoRedirectUrl: '/',
    mfaSuccessRedirectUrl: '',
    myAccountRedirectUrl: '',
    navBlockerRedirectUrl: '',
    privacyRedirectUrl: '',
    termsRedirectUrl: '',
  },
  renewalBanner: {
    actionLabel: 'View Documents',
    dismissLabel: 'Dismiss',
  },
  reviewQuoteViewLabels: {
    reviewQuoteSubheader: 'Please take a moment to confirm your selections.',
    yourCoverageAndVehiclesLabel: '',
    yourDiscountsLabel: '',
    yourDriversLabel: '',
  },
  tenantDisplayName: TENANT_DISPLAY_NAME,
  theme: {
    colors,
    fonts: {
      defaultFonts: {
        names: [],
        weight: 'normal',
      },
      fontFaceStyles: [],
      headerFonts: {
        names: [],
        weight: 500,
      },
    },
    overrides: {
      MuiCssBaseLine: {
        body: {
          background:
            'linear-gradient(90deg, rgba(220, 235, 255, 0.2) 0%, rgba(96, 163, 219, 0.2) 100%), #FFFFFF;',
        },
      },
      MuiFilledInput: {
        root: {},
      },
      MuiInputBase: {
        input: {},
      },
    },
    shape: {
      borderRadius: 5,
    },
    styles: {
      addButtonStyles: {
        button: {
          background: 'linear-gradient(180deg, #F7F7F7 49.03%, #E7E7E7 49.82%)',
          border: `1px solid ${colors.mutedMid}`,
          boxSizing: 'border-box',
        },
        buttonLabel: {
          color: colors.primaryMid,
          fontSize: 16,
        },
      },
      addModalSubtitleStyles: {
        buttonStyles: {
          label: {},
          root: {},
        },
        larger: {
          color: colors.primary,
          marginBottom: 16,
        },
        largerVariant: 'h4',
        mobile: {
          color: colors.defaultText,
          marginBottom: 8,
        },
        mobileVariant: 'h5',
      },
      businessVehiclesViewStyles: {
        noSkipButtonLabelStyles: {},
      },
      buttonStyles: {
        mobileTenantButton: {},
        tenantButton: {
          '&:disabled': {
            backgroundColor: colors.mutedLight,
            backgroundImage: 'none',
          },
          background: `linear-gradient(to bottom, ${colors.primaryHighlight} 0%, ${colors.primaryHighlight} 50%, ${colors.primaryMid} 51%)`,
          fontSize: 16,
          height: 40,
          lineHeight: 2.2,
          minWidth: 160,
        },
      },
      ccpaBannerStyles: {
        rootStyles: {
          small: {},
          large: {},
        },
        iconCardButtonLink: {
          small: {},
          large: {},
        },
      },
      circularProgressStyles: {
        avatar: {
          backgroundColor: colors.primary,
        },
        progress: {
          inProgress: {},
          done: {},
        },
      },
      coverageCardStyles: {
        buttonLink: {
          color: colors.primaryMid,
        },
        buttonLinkLabel: {},
        headers: {},
        subtitle: {},
      },
      coverageQuoteViewStyles: {
        headers: {},
      },
      disclosureViewStyles: {
        signedButtonStyle: {},
        viewButtonDisabledStyle: {},
        viewButtonLabelStyle: {},
      },
      discountCardsStyles: {
        headers: {},
        iconInfo: {
          color: colors.primaryHighlight,
        },
        subtitle: {},
      },
      documentCenterStyles: {
        documentIconColor: colors.defaultText,
      },
      errorViewStyles: {
        avatar: {},
        errorMessage: {},
      },
      faqStyles: {
        container: {},
        faqHeaderColor: colors.defaultText,
        topicLabelColor: colors.defaultText,
        topicSectionLabelColor: colors.defaultText,
        labelDividerColor: colors.mutedLight,
        topicAnswerColor: colors.defaultText,
      },
      footerStyles: {
        backgroundColor: colors.primary,
        builtOnSure: {
          color: colors.white,
        },
        color: colors.primaryLight,
        footerCopy: {},
      },
      iconCardLineItemStyles: {
        iconContainer: {},
        iconInfo: {
          color: colors.primaryHighlight,
        },
      },
      iconCardStyles: {
        iconCardBody: {},
        iconCardButtonLink: {
          color: colors.primaryMid,
        },
        iconCardButtonLinkLabel: {},
        iconCardHeader: {},
        iconColors: {
          icon: colors.white,
          modal: colors.mutedMid,
          nonModal: colors.primary,
        },
      },
      inputStyles: {
        addressAutocompleteStyles: {
          icon: {},
          iconError: {},
          suggestionsContainer_root: {},
          textIndent: null,
        },
        checkboxStyles: {
          formControlLabel_checked: {},
          formControlLabel_root: {},
        },
        inputDateStyles: {
          component: 'FilledInput',
          underline: {},
        },
        inputLabelStyles: {
          margin: 'dense',
          variant: 'filled',
        },
        radioStyles: {
          extendedFormControl: {},
          labelVariant: 'h5',
          largerLabel: {},
          mobileLabel: {},
          radio_disabled: {},
          radio_label: {},
          radio_root: {},
          selectedRadio_label: {},
          selectedRadio_root: {},
        },
        selectInputStyles: {
          component: 'FilledInput',
          root: {
            background: colors.white,
          },
          select_disabled: {
            background: colors.mutedLight,
          },
          select_options_list: {
            backgroundColor: colors.offWhite,
            padding: 0,
          },
          variant: 'filled',
        },
        textFieldStyles: {
          label: {},
          underline: {},
          variant: 'filled',
        },
      },
      layoutPolicyStyles: {
        body: {
          background:
            'linear-gradient(90deg, rgba(220, 235, 255, 0.2) 0%, rgba(96, 163, 219, 0.2) 100%), #FFFFFF',
        },
        breadcrumbSeparator: {
          color: colors.white,
        },
        currentBreadcrumb: {
          color: colors.white,
        },
        parentBreadcrumb: {
          color: colors.primaryHighlight,
        },
        priceBlock: {
          backgroundColor: colors.primary,
        },
        priceBlockHeader: {
          color: colors.primaryLight,
        },
        priceBlockPolicycost: {
          color: colors.white,
        },
        priceBlockSubtitle: {
          color: colors.white,
        },
        stagedPolicyBlock: {
          backgroundColor: colors.primaryMid,
        },
        stagedPolicyCopy: {
          color: colors.white,
        },
        stagedPolicyHeader: {
          color: colors.white,
        },
        unstageChangesBtn: {
          color: colors.white,
        },
        updatePriceBtn: {
          background: colors.white,
          color: colors.primaryMid,
        },
      },
      layoutQuoteStyles: {
        buttonCta: {},
        buttonQuoteCostColor: colors.primary,
        buttonWrapperColor: colors.white,
        headerColor: colors.primary,
        headerTextTransform: 'none',
        priceBlock: {
          backgroundColor: colors.primary,
          hasChevron: true,
          headerColor: colors.primaryLight,
          headerVariant: 'h3',
          iconColor: colors.accent,
          oldQuoteCost: {
            color: colors.mutedMid,
          },
          quoteCostColor: colors.white,
          quoteCostFontWeight: 'normal',
          quoteCostMobileFontWeight: 'normal',
          quoteWrapperStyles: {},
          subtitleColor: colors.white,
          subtitleMarginTop: -8,
          undoChangesColor: colors.primaryHighlight,
          undoChangesFontWeight: 'normal',
          undoIconColor: colors.primaryHighlight,
        },
        quoteSpinnerColor: colors.white,
        subHeaderColor: colors.defaultText,
      },
      layoutRootStyles: {
        hasCenteredTopBarLogo: true,
        topBar: {
          desktopStyles: {
            logo: {},
            root: {},
            topBar_link_alternate: {},
            topBarGroup_links: {},
          },
          styles: {
            drawerCloseIcon: {},
            drawerHeader: {},
            drawerIcon: {},
            drawerPaper: {},
            logo: {},
            root: {},
            topBar_alternateBackground: {},
            topBar_drawerIcon_alternate: {},
            topBarGroup_links: {},
          },
        },
      },
      layoutStyles: {
        layoutQuestion: {
          buttonWrapperColor: colors.white,
          disclaimer: {
            marginTop: 24,
          },
          header: {
            marginBottom: 16,
          },
          subHeader: {},
          subHeaderIcon: {},
        },
      },
      loadingViewProgressStyle: {
        avatar: {},
        inProgress: {},
        root: {},
      },
      mfaEnrollStyles: {
        header: {},
        subHeader: {},
      },
      modalStyles: {
        button: {},
        confirmModalStyles: {
          body: {},
          confirmBtn: {},
          dialogTitle: {},
          dismissBtn: {},
          modalTitle: {},
        },
        dialog: {
          background: colors.offWhite,
        },
        dismissButton: {},
        headerTextStyles: {
          large: {
            color: colors.primary,
          },
          largeVariant: 'h4',
          small: {
            color: colors.primary,
          },
          smallVariant: 'h3',
        },
        titleActionButtonPaddingTop: 8,
        titlePaddingTop: 8,
        titleVariant: 'h5',
      },
      policyCardStyles: {
        activeIconColor: colors.primary,
        canceledIconColor: colors.mutedMid,
        lapsedIconColor: colors.warning,
      },
      policyDocumentsCenter: {},
      policyViewStyles: {
        roadsideAssistIcon: {},
        sectionHeader: {},
      },
      progressBarStyles: {
        barStyles: {
          backgroundColor: colors.primaryHighlight,
          transition: 'transform 0.5s cubic-bezier(0.4, 0.15, 0.19, 1)',
        },
        rootStyles: {
          backgroundColor: colors.white,
          boxShadow:
            '0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14)',
        },
      },
      quoteLoadingStyles: {
        avatar: {},
        header: {
          color: colors.primary,
        },
        checkList: {
          progress: {},
          completed: {},
        },
      },
      renewalBannerStyles: {
        avatarColor: colors.primary,
        background: null,
        iconCardButtonLink: {
          color: colors.primaryMid,
        },
        iconColor: colors.white,
      },
      reviewQuoteStyles: {
        editLinkLabelStyle: {},
        sectionHeaderStyle: {},
        sectionHeaderVariant: null,
      },
      revokedSuspendedViewStyles: {
        noSkipButtonLabelStyles: {},
      },
      rideshareViewStyles: {
        noSkipButtonLabelStyles: {},
      },
      sliderStyles: {
        track: {},
        valueLabel: {},
      },
      snackbarStyles: {
        actionButton: {
          color: colors.primaryMid,
        },
      },
      sr22ViewStyles: {
        noSkipButtonLabelStyles: {},
      },
      startScreenStyles: {
        header: {},
        subHeader: {},
      },
      termsOfUseStyles: {
        termsOfUse_checkbox_root: {},
        termsOfUse_formControlLabel_root: {},
      },
      vehicleFieldsStyles: {
        textFieldLabel: {},
      },
      verifyInformationStyles: {
        header: {
          color: colors.primary,
        },
      },
      viewPdfModalStyles: {
        body: {},
      },
    },
    typography: {
      button: {},
      h1: {},
    },
  },
  vehicleQuoteViewCopy: {
    subHeader:
      'We’ve added the vehicles that we found on your current policy. Please review and add or remove vehicles as needed.',
  },
  verifyInformationCopy: 'One moment while we verify your information...',
  viewLabel: 'View profile',
};

const testConfig =
  window.Cypress &&
  window.wlConfigTestHelper &&
  window.wlConfigTestHelper(require(process.env.REACT_APP_WL_CONFIG));

const envConfig = testConfig || require(process.env.REACT_APP_WL_CONFIG) || {};

const configWithDefaults = merge({}, defaultConfig, envConfig);

export default configWithDefaults;
