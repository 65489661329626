import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  body: {
    flex: '1 0 auto',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 208,
    paddingBottom: 16,
  },
}));

function LoadingView() {
  const classes = useStyles();
  return (
    <Container className={classes.body} maxWidth={false}>
      <CircularProgress />
    </Container>
  );
}

export default LoadingView;
